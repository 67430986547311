export const layerStyles: {
  name: string;
  workspace: string;
  icon_url: string;
  base_map: number;
}[] = [
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 6,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 9,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 4,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 11,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 8,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 7,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 5,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 1,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 17,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 18,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 16,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 10,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 19,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 3,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 13,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 12,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 15,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 2,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 14,
  },
  {
    name: '2022_landuse_vacant',
    workspace: 'mapport',
    icon_url: '',
    base_map: 21,
  },
];
