import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-maps',
  templateUrl: './my-maps.component.html',
  styleUrls: ['./my-maps.component.css'],
})
export class MyMapsComponent implements OnInit {
  constructor() {
    //
  }

  ngOnInit(): void {
    //
  }
}
