<app-global-mod-header></app-global-mod-header>
<div class="container tabs mt-5" style="margin-top: 50px">
  <mat-tab-group (selectedTabChange)="tabChanged($event)" mat-align-tabs="end" [selectedIndex]="8"
    animationDuration="0ms">
    <ng-container *ngFor="let tab of Maintab">
      <mat-tab>
        <ng-template mat-tab-label [dataProvider]="tab"
          *ngIf="tab.data.label !== 'Security' && tab.data.label !== 'Global Modification'">
          <a class="mat-label-text" href="{{tab.data.link}}" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template mat-tab-label [dataProvider]="tab" *ngIf="tab.data.label === 'Global Modification'">
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template mat-tab-label [dataProvider]="tab" *ngIf="tab.data.label === 'Security'">
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template mat-tab-label [dataProvider]="tab" *ngIf="tab.data.label === 'plans'">
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template mat-tab-label [dataProvider]="tab" *ngIf="tab.data.label === 'subscribePages'">
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <!-- <ng-container *ngComponentOutlet="tab.component"></ng-container> -->
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>

<div class="container">
  <div class="wrapper">
    <div class="main-heading mt-3">
      <div class="row page-title">
        <div class="col-md-5"></div>
        <h3>
          <a>Recurly Plans</a>
        </h3>
        <div class="col-md-7">
          <ul class="actions navbar-right">
            <div id="loading" style="display: none"></div>
          </ul>
        </div>
      </div>
    </div>
    <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedTab" (selectedIndexChange)="setTabState($event)"
      animationDuration="0ms">
      <ng-container *ngFor="let tab of tabs" (click)="tabSelected(tab)">
        <mat-tab>
          <ng-template mat-tab-label [dataProvider]="tab">
            <div class="mat-label-text" (click)="tabSelected(tab)">
              {{ tab.data.label }}
            </div>
          </ng-template>
          <ng-container *ngComponentOutlet="tab.component"></ng-container>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
</div>
<ngx-spinner [fullScreen]="true" type="" size="medium" bdColor="rgba(0, 0, 0, 0)">
  <p class="loading"> <img src="/assets/img/wheel.svg" alt="Girl in a jacket" width="100px" height="100px"> </p>
</ngx-spinner>
<app-footer></app-footer>