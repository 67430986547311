import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorInternetConnectionService {
  connectionStatusMessage: any;
  connectionStatus: any;
  constructor() {
    // \\
  }
}
