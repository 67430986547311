import { SnackbarService } from './../../../../services/snackbarService/snackbar.service';
import { CloneLayersService } from './../../../../services/cloneLayer/clone-layers.service';
import { cloneData } from './../../../shared/models/clone-layer/cloneLayer';
import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { createLayer } from 'src/app/modules/shared/models/new-layer/newLayer.model';
import { first } from 'rxjs/operators';
import { LayerService } from 'src/app/services/layer/layer.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-clone-layer',
  templateUrl: './clone-layer.component.html',
  styleUrls: ['./clone-layer.component.css'],
})
export class CloneLayerComponent implements OnInit {
  layer: cloneData = new cloneData();
  layerData: any;
  layerId: number;
  constructor(
    private dialogRef: MatDialogRef<CloneLayerComponent>,
    @Inject(MAT_DIALOG_DATA) layer: createLayer,
    public cloneService: CloneLayersService,
    public _snackbar: SnackbarService,
    public layerService: LayerService,
    private spinner: NgxSpinnerService
  ) {
    this.layerData = layer;
    this.layerId = layer.id;
  }

  ngOnInit(): void {
    //
  }
  save(form: NgForm) {
    this.spinner.show();
    Object.assign(form, { layer_id: this.layerId });
    this.cloneService
      .createCloneLayer(form)
      .pipe(first())
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.layerService.refreshLayer('res');
          localStorage.removeItem('layerData');
          localStorage.setItem('layerData', JSON.stringify(res).toString());
          this._snackbar.openSnackBar('Layer Clone successfully');
          this.layerService.layerIds = [];
          this.layerService.layerData = [];
          this.close();
        },
        error: () => {
          this.spinner.hide();
          this._snackbar.openSnackBar('Something went wrong');
        },
      });
  }
  close() {
    this.dialogRef.close();
  }
}
