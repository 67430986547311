<div *ngIf="isDataLoaded">
  <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
    <div *ngFor="let product of productList; let i=index" formArrayName="products">
      <div *ngIf="product === 'parcel_viewer'">
        <h6 class="heading">Parcel Viewer</h6>
      </div>
      <div *ngIf="product === 'wildfire_viewer'">
        <h6 class="heading">Wildfire Viewer</h6>
      </div>
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col" style="width: 10%;"></th>
            <th scope="col" style="text-align: center; width: 15%;">Price</th>
            <th scope="col" style="text-align: center; width: 15%">Count</th>
            <th scope="col" style="text-align: center">Plan Codes</th>
          </tr>
        </thead>
        <tbody [formGroupName]='i'>
          <tr style="width: 10%;">
            <th scope="row">Day Pass</th>
            <td class="price-box">
              <input type="number" formControlName="dayPrice" class="form-control" placeholder="0" aria-label="0"
                aria-describedby="addon-wrapping" />
            </td>
            <td class="count-box">
              <input type="number" formControlName="dayCount" class="form-control" placeholder="50" aria-label="0"
                aria-describedby="addon-wrapping" />
            </td>
            <td class="count-box">
              <input type="text" formControlName="dayPlanCode" class="form-control" placeholder="County"
                aria-label="County" aria-describedby="addon-wrapping" />
            </td>
          </tr>
          <tr style="width: 15%;">
            <th scope="row">Monthly</th>
            <td class="price-box">
              <input type="number" formControlName="monthlyPrice" class="form-control" placeholder="0" aria-label="0"
                aria-describedby="addon-wrapping" />
            </td>
            <td class="count-box">
              <input type="number" formControlName="monthlyCount" class="form-control" placeholder="50" aria-label="0"
                aria-describedby="addon-wrapping" />
            </td>
            <td class="count-box">
              <input type="text" formControlName="monthlyPlanCode" class="form-control" placeholder="County"
                aria-label="County" aria-describedby="addon-wrapping" />
            </td>
          </tr>
          <tr style="width: 15%;">
            <th scope="row">Annual</th>
            <td class="price-box">
              <input type="number" formControlName="annualPrice" class="form-control" placeholder="0" aria-label="0"
                aria-describedby="addon-wrapping" />
            </td>
            <td class="count-box">
              <input type="number" formControlName="annualCount" class="form-control" placeholder="50" aria-label="0"
                aria-describedby="addon-wrapping" />
            </td>
            <td class="count-box">
              <input type="text" formControlName="annualPlanCode" class="form-control" placeholder="County"
                aria-label="County" aria-describedby="addon-wrapping" />
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <button type="submit" class="btn btn-primary" style="margin-left: 10px">
      <span class="glyphicon glyphicon-floppy-disk"></span> Save
    </button>
  </form>
</div>
<ngx-spinner [fullScreen]="true" type="" size="medium" bdColor="rgba(0, 0, 0, 0)">
  <p class="loading"> <img src="/assets/img/wheel.svg" alt="Girl in a jacket" width="100px" height="100px"> </p>
</ngx-spinner>
<!-- <div *ngIf="isDataLoaded">
  <div *ngFor="let product of productList; let i=index" formArrayName="products">
    <form [formGroup]="productForm">
      <h4 class="heading">Wildfire Viewer</h4>
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" style="text-align: center">Price</th>
            <th scope="col" style="text-align: center">Count</th>
            <th scope="col" style="text-align: center">Plan Codes</th>
          </tr>
        </thead>
        <tbody [formGroupName]="i">
          <tr>
            <th scope="row">Day Pass</th>
            <td class="price-box">
              <input type="number" class="form-control" placeholder="0" aria-label="0"
                aria-describedby="addon-wrapping" />
            </td>
            <td class="count-box">
              <input disabled type="number" class="form-control" placeholder="50" aria-label="0"
                aria-describedby="addon-wrapping" />
            </td>
            <td class="count-box">
              <input type="text" class="form-control" placeholder="County" aria-label="County"
                aria-describedby="addon-wrapping" />
            </td>
          </tr>
          <tr>
            <th scope="row">Monthly</th>
            <td class="price-box">
              <input type="number" class="form-control" placeholder="0" aria-label="0"
                aria-describedby="addon-wrapping" />
            </td>
            <td class="count-box">
              <input disabled type="number" class="form-control" placeholder="50" aria-label="0"
                aria-describedby="addon-wrapping" />
            </td>
            <td class="count-box">
              <input type="text" class="form-control" placeholder="County" aria-label="County"
                aria-describedby="addon-wrapping" />
            </td>
          </tr>
          <tr>
            <th scope="row">Annual</th>
            <td class="price-box">
              <input type="number" class="form-control" placeholder="0" aria-label="0"
                aria-describedby="addon-wrapping" />
            </td>
            <td class="count-box">
              <input disabled type="number" class="form-control" placeholder="50" aria-label="0"
                aria-describedby="addon-wrapping" />
            </td>
            <td class="count-box">
              <input type="text" class="form-control" placeholder="County" aria-label="County"
                aria-describedby="addon-wrapping" />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>

</div> -->