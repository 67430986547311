<div class="action-buttons">
  <div class="form-group" style="">
    <a href="/maps/new/" class="btn btn-primary" id="new-map" style="">
      New Map
    </a>

    <a
      id="new-del-map"
      type="button"
      class="btn btn-danger"
      href="javascript:;"
      style=""
      >Delete</a
    >
    <button id="new-copy-map" type="button" class="btn btn-success" style="">
      Clone
    </button>
  </div>
</div>
