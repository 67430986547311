import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { DatePipe } from '@angular/common';

import { MatDialog } from '@angular/material/dialog';
import { MapServiceService } from '../modules/map/services/Map-service/map-service.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  currentDate: any;
  constructor(
    private snackBar: MatSnackBar,
    public global: GlobalService,
    public mapServices: MapServiceService,
    public dialog: MatDialog,
    private router: Router,
    private auth: AuthService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('auth_token') || '';
    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Token ' + token),
      });
    }

    //remoe text/html when done from bk
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json, text/html'),
      url: environment.baseUrl + request.url,
    });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.global.toggleLoader(false);
        }
        //converting expiryMap date to short date format
        this.auth.expiryMap = new DatePipe('en-US').transform(
          this.auth.expiryMap,
          'short'
        );

        this.currentDate = new Date();
        //converting current  date to short date format
        this.currentDate = new DatePipe('en-US').transform(
          this.currentDate,
          'short'
        );

        //handling safari expiry issue

        if (
          this.auth.expiryMap != undefined &&
          this.auth.expiryMap != null &&
          this.auth.expiryMap.trim() != ''
        ) {
          if (new Date(this.auth.expiryMap) <= new Date(this.currentDate)) {
            this.auth.expiryMap = null;
            this.router.navigate(['/login']);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.global.toggleLoader(false);
        if (
          error.error.detail ===
            'You do not have permission to perform this action.' &&
          error.status === 403
        ) {
          this.router.navigate(['/parcelviewer/subscribe/']);
        } else if (error.status === 401) {
          localStorage.clear();
          this.router.navigate(['/login']);
        } else if (error.status === 403) {
          this.global.showMessageModal(error.error.message, error.statusText);
        }

        return throwError(error);
      })
    );
  }
}
