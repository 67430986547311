import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { InvalidUserComponent } from 'src/app/modules/dashboard/modals/invalid-user/invalid-user.component';
import { MapServiceService } from 'src/app/modules/map/services/Map-service/map-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-map-access',
  templateUrl: './map-access.component.html',
  styleUrls: ['./map-access.component.css'],
})
export class MapAccessComponent implements OnInit {
  token = '';
  slug = '';
  userInfo: any;
  embed: any;
  encrypted: any;
  message = 'SuperSecret!!';
  loginModal: any
  constructor(
    private route: ActivatedRoute,
    private global: GlobalService,
    public mapServices: MapServiceService,
    private auth: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    localStorage.clear();
    this.token = this.route.snapshot.paramMap.get('token') || '';
    this.slug = this.route.snapshot.paramMap.get('slug?embed=true') || '';
    this.route.queryParamMap.subscribe((params) => {
      setTimeout(() => {
        this.mapServices.embed = params.get('embed');
      }, 1000);
    });
    setTimeout(() => {
      this.loginModal = this.mapServices.getAllModals.login_error
    }, 2000);
    this.mapServices.getClientInfo(this.token).subscribe(async (res: any) => {
      this.userInfo = res;
      this.encrypted = await CryptoJS.AES.encrypt(
        this.userInfo.user.access_level.toString(),
        this.message
      );
      localStorage.setItem('userAccessLevel', this.encrypted);

      if (res.message) {
        this.dialog.open(InvalidUserComponent, {
          data: {
            title: 'Please try again',
            content: this.loginModal,
          },
          disableClose: true,
        });
        // this.global.showMessageModal(
        //   '.',
        //   'Username and/or password not recognized'
        // );
      } else {
        if (this.userInfo.user.login_directly === 'True') {
          this.auth.setToken(res);
          localStorage.setItem('redirect', 'true');
          this.router.navigate([this.userInfo.redirect_to]);
          // this.router.navigate(['/alameda-county-parcel-viewer']);
        } else {
          localStorage.setItem('redirect', 'false');
          this.auth.setToken(res);
          this.router.navigate([`/${this.slug}`]);
        }
      }
    });
  }
}
