<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">Selection Limit</div>
    <div class="modal-body modal-padding">
      <p [innerHtml]="message | sanitizeHtml">
      </p>
    </div>
    <div class="modal-footer">
      <div style="text-align: right">
        <button
          class="modal-button-style"
          data-dismiss="modal"
          (click)="close()"
        >
          Okay
        </button>
      </div>
    </div>
  </div>
</div>
