export class labelData {
  id?: number
  map_id_labels?: any ;
  apn?: number;
  layer_id_labels?: number;
  owner_name?: string;
  situs_addr_One?: string;
  situs_addr_Two?: string;
  mailing_addr_One ?: string
  mailing_addr_Two ?: string
  visibility?: boolean
}
