import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-inheritance-modal',
  templateUrl: './inheritance-modal.component.html',
  styleUrls: ['./inheritance-modal.component.css'],
})
export class InheritanceModalComponent implements OnInit {
  title: any;
  content: any;
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = '';
    this.content = '';
  }

  ngOnInit(): void {
    this.title = '';
    this.content = '';
    this.title = this.data.title;
    this.content = this.data.content;
  }
  close(): void {
    this.dialog.closeAll();
  }
}
