<div class="action-buttons" style="z-index: 999;">
  <div class="form-group">
    <a class="btn btn-primary" id="new-map" routerLink="/global/layers"
    routerLinkActive="active" (click)="btnClick()" style="cursor:pointer"> New Layer </a>

    <a id="new-del-map" type="button" class="btn btn-danger" (click)="btnDelete()"
      >Delete</a
    >
    <button id="new-copy-map" type="button" class="btn btn-success" (click)="btnClone()">
      Clone
    </button>
  </div>
</div>
