<div class="tab-content">
  <div class="table-data">
    <div class="col-sm-6">
      <div class="dataTables_length" id="tbl-maps_length">
        <label>Show
          <select name="tbl-maps_length" aria-controls="tbl-maps" class="form-control input-sm" (change)="size($event)">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          entries</label>
      </div>
    </div>
    <div class="col-sm-6">
      <div id="tbl-maps_filter" class="dataTables_filter">
        <label>Search:<input type="search" class="form-control input-sm" placeholder="" aria-controls="tbl-maps"
            #myInput (keyup)="search($event)" />
        </label>
      </div>
    </div>
  </div>
  <table class="table table-hover mt-4" style="table-layout: fixed">
    <tbody style="border-top: 2px solid #eee">
      <tr *ngFor="let layer of allLayers">
        <td style="width: 100px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="customCheck1" (change)="getLayerdata(layer)" />
          </div>
        </td>
        <td style="text-align: left;">{{ layer.name }} <p class="comment">{{layer.comment}}</p>
        </td>
        <td style="text-align: end;">
          <a type="submit" class="btn btn-default" href="{{ djangoAdminUrl }}" (click)="djangoAdmin(layer.id)"
            style="margin-left: 10px;"> Django Admin
          </a>
          <button type="submit" class="btn btn-primary" (click)="editLayer(layer)" style="margin-left: 10px;"> Edit
            Layer
          </button>
          <button type="submit" class="btn btn-default1" (click)="applyInheritance(layer)" style="margin-left: 10px;">
            Apply
          </button>
          <a class="btn btn-default"
            style="margin-left: 10px; width: 50px; padding: 5px">{{layer.child_layer_count}}</a>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- paginator -->
  <div class="d-flex">
    <div class="col-sm-5">
      <div class="dataTables_info" id="tbl-maps_info" role="status" aria-live="polite">
        Showing {{currentPages}} to {{pageSize}} of {{totalItems}} entries
      </div>
    </div>
    <div class="col-sm-7">
      <div class="dataTables_paginate paging_simple_numbers" id="tbl-maps_paginate">

        <ul class="pagination">
          <li class="paginate_button previous " id="tbl-maps_previous" (click)="prevPage()">
            <a [ngClass]="disablePerviousBuuton ? 'adisabled': ''">Previous</a>
          </li>
          <ng-container *ngFor="let data of pages;index as  i">
            <li class="paginate_button" [ngClass]="currentPages === data ? 'active' : ''">
              <a aria-controls="tbl-maps" data-dt-idx="1" (click)="onbtnClick(data,i+1)">{{ data }}</a>
            </li>
          </ng-container>
          <li class="paginate_button next" id="tbl-maps_next" (click)="nextPage()">
            <a endIndex>Next</a>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>