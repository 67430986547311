<mat-toolbar color="#eee">
  <mat-toolbar-row>
    <div class="container">
      <div class="header">
        <span class="title">MapPort</span>
        <div class="navbar-text">
          <p>{{user.first_name}} {{user.last_name}}(Demigod)</p>
          <a (click)="logout()">Logout</a>
        </div>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

