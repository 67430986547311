import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CourtesyService } from 'src/app/services/courtesy.service';

@Component({
  selector: 'app-courtesy-add-ons',
  templateUrl: './courtesy-add-ons.component.html',
  styleUrls: ['./courtesy-add-ons.component.css'],
})
export class CourtesyAddOnsComponent {
  addOnForm!: FormGroup;

  constructor(private fb: FormBuilder, private service: CourtesyService) {
    this.service.emitData.subscribe(() => {
      this.service.getAddsOns(this.addOnForm.value);
    });
    this.addOnForm = this.fb.group({
      collab: [false],
      outreach: [false],
      collab_quantity: [''],
      collab_expiry_check: [false],
      collab_expiry: [''],
      outreach_expiry_check: [false],
      outreach_expiry: [''],
    });
    this.collabToggle();
    this.outreachToggle();
  }
  collabToggle() {
    if (this.addOnForm.value.collab) {
      this.addOnForm.controls['collab_quantity'].enable();
      this.addOnForm.controls['collab_expiry_check'].enable();
      this.addOnForm.controls['collab_expiry'].enable();
    } else {
      this.addOnForm.controls['collab_quantity'].disable();
      this.addOnForm.controls.collab_quantity.setValue('');
      this.addOnForm.controls['collab_expiry_check'].disable();
      this.addOnForm.controls.collab_expiry_check.setValue(false);
      this.addOnForm.controls['collab_expiry'].disable();
      this.addOnForm.controls.collab_expiry.setValue('');
    }
    if (this.addOnForm.value.collab_expiry_check) {
      this.addOnForm.controls['collab_expiry'].enable();
    } else {
      this.addOnForm.controls['collab_expiry'].disable();
      this.addOnForm.controls.collab_expiry.setValue('');
    }
  }

  collabExpiryToggle() {
    if (this.addOnForm.value.collab_expiry_check) {
      this.addOnForm.controls['collab_expiry'].enable();
    } else {
      this.addOnForm.controls['collab_expiry'].disable();
      this.addOnForm.controls.collab_expiry.setValue('');
    }
  }

  outreachToggle() {
    if (this.addOnForm.value.outreach) {
      this.addOnForm.controls['outreach_expiry_check'].enable();
      this.addOnForm.controls['outreach_expiry'].enable();
    } else {
      this.addOnForm.controls['outreach_expiry_check'].disable();
      this.addOnForm.controls.outreach_expiry_check.setValue(false);
      this.addOnForm.controls['outreach_expiry'].disable();
      this.addOnForm.controls.outreach_expiry.setValue('');
    }
    if (this.addOnForm.value.outreach_expiry_check) {
      this.addOnForm.controls['outreach_expiry'].enable();
    } else {
      this.addOnForm.controls['outreach_expiry'].disable();
      this.addOnForm.controls.outreach_expiry.setValue('');
    }
  }

  outreachExpiryToggle() {
    if (this.addOnForm.value.outreach_expiry_check) {
      this.addOnForm.controls['outreach_expiry'].enable();
    } else {
      this.addOnForm.controls['outreach_expiry'].disable();
      this.addOnForm.controls.outreach_expiry.setValue('');
    }
  }
}
