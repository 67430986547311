<div class="modal-body modal-padding">
  <h5>Confirm Delete</h5>
  <p [innerHtml]="text | sanitizeHtml"></p>
  <div class="button-wrapper">
    <button class="modal-button-style delete-btn-yes" (click)="close()">
      Yes
    </button>
    <button class="modal-button-style" (click)="close()">Cancel</button>
  </div>
</div>
