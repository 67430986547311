import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-global-mod-header',
  templateUrl: './global-mod-header.component.html',
  styleUrls: ['./global-mod-header.component.css'],
})
export class GlobalModHeaderComponent implements OnInit {
  user: any;
  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    this.user = this.authService.user.user;
  }
  logout() {
    this.authService.logout();
    window.location.href = `${environment.operatorDashboardUrl}/login/`;
  }
}
