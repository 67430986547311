import { SnackbarService } from './../../../../../services/snackbarService/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { labelData } from 'src/app/modules/shared/models/labels/lable';
import { TabItem } from 'src/app/modules/shared/models/tab/tab.model';
import { AuthService } from 'src/app/services/auth.service';
import { LabelService } from 'src/app/services/label/label.service';
import { TabService } from 'src/app/services/tab/tab.service';
import { LayerService } from 'src/app/services/layer/layer.service';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.css'],
})
export class LabelsComponent implements OnInit {
  user: any;
  tabs: TabItem[] | undefined;
  //data fetching from backend
  labelData: labelData = new labelData();
  product: any;
  editTable = false;
  on = true;
  off = false;
  layerName!: string;
  apMapFeed: any;
  layerId!: number;
  constructor(
    private authService: AuthService,
    public router: Router,
    public tabService: TabService,
    public labelService: LabelService,
    public _snackbar: SnackbarService,
    public layerService: LayerService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.layerService.layerdata = localStorage.getItem('layerData');
    if (this.layerService.layerdata) {
      this.product = JSON.parse(this.layerService.layerdata);
      this.labelData.layer_id_labels = this.product.id;
      this.apMapFeed = `/layers/${this.product.id}/apmapfeed`;
      this.layerName = this.product.name;
      this.labelData.map_id_labels = null;
      this.layerId = this.product.id;
      this.labelService
        .getLabelById(this.labelData.layer_id_labels)
        .pipe(first())
        .subscribe({
          next: (res: any) => {
            this.labelData = res;
            if (this.labelData.visibility === true) {
              this.on = true;
            } else {
              this.off = false;
            }
            //dasdasdsad
            if (this.labelData.owner_name !== '') {
              this.editTable = true;
            }
          },
          error: () => {
            this._snackbar.openSnackBar('Something went wrong');
          },
        });
    }
  }

  submitForm(): void {
    this.layerService.layerdata = localStorage.getItem('layerData');
    if (this.layerService.layerdata) {
      this.product = JSON.parse(this.layerService.layerdata);
      this.labelData.layer_id_labels = this.product.id;
    }
    if (this.editTable) {
      this.spinner.show();
      this.labelService
        .updateLabel(this.labelData.id, this.labelData)
        .pipe(first())
        .subscribe({
          next: () => {
            this.spinner.hide();
            this._snackbar.openSnackBar('Label update successfully');
            // this.router.navigate(['/']);
          },
          error: () => {
            this.spinner.hide();
            this._snackbar.openSnackBar('Something went wrong');
          },
        });
    } else {
      this.spinner.show();
      this.labelService
        .createLabel(this.labelData)
        .pipe(first())
        .subscribe({
          next: () => {
            this.spinner.hide();
            this._snackbar.openSnackBar('Label Create successfully');
            // this.router.navigate(['/']);
          },
          error: () => {
            this.spinner.hide();
            this._snackbar.openSnackBar('Something went wrong');
          },
        });
    }
  }
  logout() {
    this.authService.logout();
    window.location.href = `${environment.operatorDashboardUrl}/login/`;
  }
  backToLayer() {
    this.router.navigate([`/layers/${this.layerId}`]);
  }
}
