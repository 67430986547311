<app-global-mod-header></app-global-mod-header>
<div class="container tabs mt-5" style="margin-top: 50px;">
  <mat-tab-group (selectedTabChange)="tabChanged($event)" mat-align-tabs="end" [selectedIndex]="7"
    animationDuration="0ms">
    <ng-container *ngFor="let tab of tabs">
      <mat-tab>
        <ng-template mat-tab-label *ngIf="tab.data.label !== 'Security' && tab.data.label !== 'Global Modification'">
          <a class="mat-label-text" href="{{tab.data.link}}" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template mat-tab-label *ngIf="tab.data.label === 'Global Modification'">
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template mat-tab-label *ngIf="tab.data.label === 'Security'">
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <!-- <ng-container *ngComponentOutlet="tab.component"></ng-container> -->
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>
<div class="container">
  <div class="wrapper">
    <div class="content">
      <div class="col-md-12">
        <h2 class="text-center">Security Controls</h2>
        <form>
          <fieldset class="radiogroup">
            <legend>Captcha:</legend>
            <ul class="radio">
              <li>
                <input type="radio" name="group1" id="answer1" (change)="cap(onCap)"
                  [(ngModel)]="securityData.visibilityCap" [value]="onCap" />
                <label for="answer1">on</label>
              </li>
              <li>
                <input type="radio" name="group1" id="answer2" (change)="cap(offCap)"
                  [(ngModel)]="securityData.visibilityCap" [value]="offCap" />
                <label for="answer2">off</label>
              </li>
              <li>
                <input type="radio" name="group1" id="answer2" (change)="cap(auto)"
                  [(ngModel)]="securityData.visibilityCap" [value]="auto" />
                <label for="answer2">Auto</label>
              </li>
            </ul>
          </fieldset>

          <fieldset class="radiogroup">
            <legend>Otp Varification:</legend>
            <ul class="radio">
              <li>
                <input type="radio" name="group2" id="answer3" (change)="otp(onOtp)"
                  [(ngModel)]="securityData.visibilityOtp" [value]="onOtp" />
                <label for="answer1">on</label>
              </li>
              <li>
                <input type="radio" name="group2" id="answer4" (change)="otp(offOtp)"
                  [(ngModel)]="securityData.visibilityOtp" [value]="offOtp" />
                <label for="answer2">off</label>
              </li>
              <li>
                <input type="radio" name="group2" id="answer4" (change)="otp(auto1)"
                  [(ngModel)]="securityData.visibilityOtp" [value]="auto1" />
                <label for="answer2">Auto</label>
              </li>
            </ul>
          </fieldset>
          <button type="submit" class="btn btn-primary" style="margin-left: 10px;" (click)="submitForm()">
            <span class="glyphicon glyphicon-floppy-disk"></span> Save
          </button>
        </form>
      </div>
    </div>
  </div>
</div>