import { ChangeDetectorRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MessageModalComponent } from '../modules/shared/components/modals/message-modal/message-modal.component';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public loading = false;
  cdRef?: ChangeDetectorRef;
  messageModalRef: any;
  updateModalRef: any;
  constructor(public dialog: MatDialog) {}

  toggleLoader(state: boolean) {
    this.loading = state;
    if (this.cdRef) this.cdRef.detectChanges();
  }

  showMessageModal(message: string, title: string) {
    if (message === '') return;
    if (!title || title === '') {
      title = 'Please Try Again';
    }
    this.messageModalRef = this.dialog.open(MessageModalComponent, {
      disableClose: true,
      panelClass: 'app-full-bleed-dialog',
      backdropClass: 'bdrop',
      position: {
        top: '10%',
      },
      data: {
        message: message,
        title: title,
      },
    });
  }

  loadGoogleAnalytics(trackingID: string): void {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${trackingID}`
    );

    const gaScript2 = document.createElement('script');
    // eslint-disable-next-line no-useless-escape
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\');`;

    let d1 = document.documentElement.firstChild as HTMLElement;
    d1.appendChild(gaScript);
    d1 = document.documentElement.firstChild as HTMLElement;
    d1.appendChild(gaScript2);
  }
}
