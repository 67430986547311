<form [formGroup]="addOnForm">
    <div>
        <div class="row">
            <div class="col-md-4">
                <p><input type="checkbox" (change)="collabToggle()" formControlName="collab"> Collaborator Seats</p>
            </div>
            <div class="col-md-2">
                <input type="number" min="1" style="width:50px" formControlName="collab_quantity">
            </div>
            <div class="col-md-3">
                <p>Expiry
                    <input type="checkbox" (change)="collabExpiryToggle()" formControlName="collab_expiry_check">
                    <input type="number" min="1" style="width: 50px;" formControlName="collab_expiry">
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <p><input type="checkbox" (change)="outreachToggle()" formControlName="outreach"> Outreach</p>
            </div>
            <div class="col-md-2">
            </div>
            <div class="col-md-3">
                <p>Expiry
                    <input type="checkbox" (change)="outreachExpiryToggle()" formControlName="outreach_expiry_check">
                    <input type="number" min="1" style="width: 50px;" formControlName="outreach_expiry">
                </p>
            </div>
        </div>
    </div>
</form>