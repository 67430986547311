import { LayerService } from './../../../../services/layer/layer.service';
import { Component, OnInit } from '@angular/core';
import { tabledata } from 'src/app/modules/shared/models/table/table.model';
import { SnackbarService } from 'src/app/services/snackbarService/snackbar.service';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { TabService } from 'src/app/services/tab/tab.service';
import { environment } from 'src/environments/environment';
import { layerStyles } from './styles';
import { MatDialog } from '@angular/material/dialog';
import { InheritanceModalComponent } from 'src/app/modules/shared/components/modals/inheritance-modal/inheritance-modal.component';

@Component({
  selector: 'app-glo-mod-layers',
  templateUrl: './glo-mod-layers.component.html',
  styleUrls: ['./glo-mod-layers.component.css'],
})
export class GloModLayersComponent implements OnInit {
  allLayers: tabledata[] = [];
  totalItems: any;
  currentPages: any = 1;
  pageSize: any = 10;
  totalPages: any;
  startPage!: number;
  endPage!: number;
  startPages!: number;
  endPages!: number;
  startIndex: any;
  endIndex: any;
  djangoAdminUrl: any;
  pages: any;
  token: any;
  disablePerviousBuuton = false;
  disableNextBuuton = false;
  checkboxValue = false;
  options: any = {
    page: 1,
    search: '',
    size: 5,
  };
  count = 0;
  constructor(
    public LayerService: LayerService,
    public tabService: TabService,
    public _snackService: SnackbarService,
    public dialog: MatDialog,
    public router: Router
  ) {
    this.tabService.hideTab = false;
    this.token = localStorage.getItem('auth_token');
  }

  ngOnInit(): void {
    this.LayerService.layerListUpdate.subscribe((res: any) => {
      if (res) {
        if (this.totalItems == 11) {
          this.currentPages = 1;
        } else if (this.totalItems == 21) {
          this.currentPages = 2;
        } else if (this.totalItems == 31) {
          this.currentPages = 3;
        } else if (this.totalItems == 41) {
          this.currentPages = 4;
        }
        this.getAllLayers();
      }
    });

    this.getAllLayers();
    this.onTableDataChange(1);
    this.paginate(this.totalItems, this.currentPages, this.pageSize);
    this.LayerService.layerIds = []
    this.LayerService.layerData = []
    }
  search($event: any): void {
    const text = $event.target.value;
    if (text === '') {
      this.getAllLayers();
    } else {
      this.options.search = text;
    }

    this.options.page = 1;
    this.getAllLayers();
  }
  size(size: any): void {
    this.pageSize = size.target.value;
    this.getAllLayers();
    this.paginate(this.totalItems, this.currentPages, this.pageSize);
  }
  onTableDataChange(event: any) {
    this.currentPages = event;
    this.getAllLayers();
    this.paginate(this.totalItems, this.currentPages, this.pageSize);
  }
  getAllLayers(): void {
    this.LayerService.getAllLayer(
      this.pageSize,
      this.currentPages,
      this.options.search
    )
      .pipe(first(), debounceTime(500), distinctUntilChanged())
      .subscribe((res: any) => {
        this.allLayers = res.results;
        this.totalItems = res.count;
        switch (this.currentPages) {
          case 1:
            this.disablePerviousBuuton = true;
            this.disableNextBuuton = false;
            break;
          case this.currentPages == this.count:
            this.disableNextBuuton = true;
            this.disablePerviousBuuton = false;
            break;
          default:
            this.disableNextBuuton = false;
            this.disablePerviousBuuton = false;
            break;
        }
        this.paginate(this.totalItems, this.currentPages, this.pageSize);
      });
  }
  getLayerdata(layer: any): void {
    if (this.LayerService.layerIds.length > 0) {
      const index = this.LayerService.layerIds.findIndex((i) => i === layer.id);
      const index2 = this.LayerService.layerData.findIndex(
        (i) => i === layer.id
      );
      if (index != -1) {
        this.LayerService.layerIds.splice(index, 1);
      } else {
        this.LayerService.layerIds.push(layer.id);
      }
      if (index2 != -1) {
        this.LayerService.layerData.splice(index2, 1);
      } else {
        this.LayerService.layerData.push(layer);
      }
    } else {
      this.LayerService.layerIds.push(layer.id);
      this.LayerService.layerData.push(layer);
    }
  }
  editLayer(layerData: any) {
    this.LayerService.getLayerById(layerData.id)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          localStorage.setItem('setEdit', '1');
          localStorage.removeItem('layerData');
          //for handling layers created without basemap styles
          if (!res.layer_styles.length) {
            res.layer_styles = layerStyles;
          }
          localStorage.setItem('layerData', JSON.stringify(res).toString());
          // passing id with url
          this.router.navigateByUrl(`/layers/${layerData.id}`);
        },
        error: () => {
          this._snackService.openSnackBar('Please select layer');
        },
      });
  }

  applyInheritance(layerData: any) {
    this.LayerService.applyInheritance(layerData.id)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          this._snackService.openSnackBar(res.message);
          if (res.affected_layers.length > 0) {
            this.dialog.open(InheritanceModalComponent, {
              panelClass: 'app-full-bleed-dialog',
              backdropClass: 'bdrop',
              position: {
                top: '10%',
              },
              data: {
                title: 'Following layers have issue in Show Fields',
                content: res.affected_layers,
              },
            });
          }
        },
        error: (error) => {
          this._snackService.openSnackBar(error);
        },
      });
  }
  // update click on page number
  onbtnClick(pageData: any, index: any) {
    if (this.currentPages >= pageData) {
      this.currentPages = index;
      this.paginate(this.totalItems, this.currentPages, this.pageSize);
      this.getAllLayers();
    } else {
      const numPage = this.pages.length;
      if (this.currentPages < numPage) {
        this.currentPages = index;
        this.paginate(this.totalItems, this.currentPages, this.pageSize);
        this.getAllLayers();
      }
    }
  }
  prevPage() {
    if (this.currentPages > 1) {
      this.currentPages--;
      this.paginate(this.totalItems, this.currentPages, this.pageSize);
      this.getAllLayers();
    }
  }
  nextPage() {
    const numPage = this.pages.length;
    if (this.currentPages < numPage) {
      this.currentPages++;
      this.paginate(this.totalItems, this.currentPages, this.pageSize);
      this.getAllLayers();
    }
  }

  paginate(totalItems = 10, currentPage: any, pageSize: any, maxPages = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      this.startPages = 1;
      this.endPages = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        this.startPages = 1;
        this.endPages = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        this.startPages = totalPages - maxPages + 1;
        this.endPages = totalPages;
      } else {
        // current page somewhere in the middle
        this.startPages = currentPage - maxPagesBeforeCurrentPage;
        this.endPages = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(
      Array(this.endPages + 1 - this.startPages).keys()
    ).map((i) => this.startPage + i);

    // return object with all pager properties required by the view
    (this.totalItems = totalItems),
      (this.currentPages = currentPage),
      (this.pageSize = pageSize),
      (this.totalPages = totalPages),
      (this.startPage = this.startPages),
      (this.endPage = this.endPages),
      (this.startIndex = startIndex),
      (this.endIndex = endIndex),
      (this.pages = pages);
  }

  djangoAdmin(layerId: any) {
    this.djangoAdminUrl = `${environment.opLink}/admin/${layerId}/${this.token}`;
  }
}
