import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubscribeService } from 'src/app/services/subscribe/subscribe.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css'],
})
export class PagesComponent {
  pagesList: any[] = [];
  selectedList: any[] = [];
  selectedItems: any = [];
  selectAllCheck = false;
  dataLoaded = false;

  constructor(
    private service: SubscribeService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
    this.getAllPages();
  }

  defaultUncheckAll() {
    this.selectedList = [];
    for (let i = 0; i < this.pagesList.length; i++) {
      const select = { isSelected: false };
      this.selectedItems[i] = select;
    }
  }
  selectAll() {
    if (!this.selectAllCheck) {
      this.defaultUncheckAll();
    } else {
      this.selectedList = [];
      for (let i = 0; i < this.pagesList.length; i++) {
        const select = { isSelected: true };
        this.selectedItems[i] = select;
        this.selectedList.push(this.pagesList[i].id);
      }
    }
  }
  checkBox(event: Event, id: number) {
    if ((<HTMLInputElement>event.target).checked) {
      this.selectedList.push(id);
    } else if (!(<HTMLInputElement>event.target).checked) {
      const index = this.selectedList.indexOf(id);
      this.selectedList.splice(index, 1);
    }
    if (this.pagesList.length === this.selectedList.length) {
      this.selectAllCheck = true;
    } else {
      this.selectAllCheck = false;
    }
  }
  deletePages() {
    if (this.selectedList.length === 0) {
      return;
    }
    this.spinner.show();
    const body = { page_ids: this.selectedList };
    this.service.deletePages(body).subscribe(() => {
      this.getAllPages();
    });
    this.selectAllCheck = false;
  }
  getAllPages() {
    this.service.getAllPages().subscribe((res) => {
      this.pagesList = res.pages;
      this.defaultUncheckAll();
      this.dataLoaded = true;
      this.spinner.hide();
    });
  }
}
