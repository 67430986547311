<div class="modal-content error-modal-content">
    <div class="modal-body">
        <h4 id="heading-modal" class="head-modal">{{title}}</h4>
        <hr class="modal-hr-line" />
        <!-- <p id="err-recurly-modal-body" class="inbody-modal">
            {{content}}.
        </p> -->
        <div id="err-recurly-modal-body" class="inbody-modal">
            <div class="row" style="font-size: 16px !important; font-weight: bold;">
                <div class="col-md-3">Id</div>
                <div class="col-md-7">Name</div>
            </div>
            <div class="row" *ngFor="let row of content">
                <div class="col-md-3">{{row.id}}</div>
                <div class="col-md-7">{{row.name}}</div>
            </div>
        </div>
        <div class="btn-container" style="text-align: right">
            <button type="button" class="btn accept-btn gernal-btn" data-dismiss="modal"
                style="float: right; border-radius: 0px; margin-top: 10px" (click)="close()">
                OK
            </button>
        </div>
        <br />
        <br />
        <br />
    </div>
</div>