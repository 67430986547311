<app-header [product]="product"></app-header>
<div class="content">
  <div class="container" id="login-content-mod" style="margin-top: 4%;">
    <div class="row">
      <div class="col-md-12 form-container">
        <form id="login-content-form" #loginForm="ngForm" class="col-md-4">
          <div class="form-group">
            <input
              id="email"
              type="email"
              required
              [(ngModel)]="email"
              class="form-control login-mail"
              placeholder="Email Address"
              name="email"
            />
          </div>
          <div class="form-group">
            <input
              id="password"
              required
              [(ngModel)]="password"
              type="password"
              class="form-control login-password"
              placeholder="Password"
              name="password"
            />
          </div>

          <div>
            <div class="buttons">
              <button
                type="submit"
                (click)="login()"
                [disabled]="!loginForm.form.valid"
                class="btn btn-primary login-btn"
              >
                Log In
              </button>
            </div>
          </div>
          <div>
            <div class="buttons">
              <a href="javascript:;" (click)="forgotPassword()"
                >Forgot password?</a
              >
            </div>
          </div>
        </form>
      </div>
    </div>
    <div>
      <p class="login-footer">
        This login serves users of maps hosted on the
        <a  class="url-links" href="https://enplan.com">ENPLAN </a>
        <a class="url-links" href="https://mapport.com">MapPort</a> platform.
        <br />
        Not a subscriber? Go to our
        <a href="https://mapport.com">website</a> for more information.
      </p>
    </div>
  </div>
</div>
<app-footer></app-footer>
