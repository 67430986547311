import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createSubscribe } from 'src/app/modules/shared/models/subscribe/createSubscribe.model';

@Injectable({
  providedIn: 'root',
})
export class SubscribeService {
  constructor(private http: HttpClient) {}

  createPage(body: any): Observable<any> {
    return this.http.post('/subscribe/page/', body);
  }
  updatePage(id: number, body: any): Observable<createSubscribe> {
    return this.http.patch<createSubscribe>(
      '/subscribe/page/' + id + '/',
      body
    );
  }
  getPage(id: any): Observable<any> {
    return this.http.get('/subscribe/page/' + id + '/');
  }
  getAllPages(): Observable<any> {
    return this.http.get('/subscribe/page/');
  }
  deletePages(list: any): Observable<any> {
    return this.http.post('/subscribe/delete/page/', list);
  }
}
