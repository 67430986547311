<div class="modal-content" style="border: solid 1px #aaaaaa;padding: 10px;height: 250px;width: 400px">
    <div id="err-modal-body" class="modal-body">
        <h4 id="heading-modal" class="head-modal">Make a new account?</h4>
        <hr class="modal-hr-line">
        <p class="inbody-modal">Click OK to subscribe under a new account and finish checkout.</p>
        <p class="inbody-modal">The current account will remain active and related map access will continue. </p>
        <button type="button" style="float: right;height: 38px; margin-left: 7px;" class="btn decline-btn" data-dismiss="modal" (click)="close()">Cancel</button>
         <button type="button" style="float: right;height: 38px; margin-left: 7px;" class="btn accept-btn" (click)="logOut(); close()">Ok</button>
        <br>
    </div>
</div>