import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SubscribeService } from 'src/app/services/subscribe/subscribe.service';
import { MapServiceService } from '../../services/Map-service/map-service.service';

@Component({
  selector: 'app-assessop-parcel-error',
  templateUrl: './assessop-parcel-error.component.html',
  styleUrls: ['./assessop-parcel-error.component.css']
})
export class AssessopParcelErrorComponent implements OnInit {
  message: any
  constructor(private dialog: MatDialog, private mapServie: MapServiceService) {
    //
  }

  ngOnInit(): void {
    this.message =this.mapServie.getAllModals.assessor_parcel_error
  }
  closeDialog() {
    this.dialog.closeAll()
  }
}
