import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
[x: string]: any;
  @Input() product = '';
  @Input() productData = {logo: ''};
  title: any;
  userLevel: any;
  accountUrl: any;
  token: any;
  decrypted: any;
  message = 'SuperSecret!!';
  constructor(public auth: AuthService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.title = this.route.snapshot.paramMap.get('product') || '';
    this.decrypted = localStorage.getItem('userAccessLevel');
    if (this.decrypted) {
      const decrypted = CryptoJS.AES.decrypt(this.decrypted, this.message);
      this.userLevel = decrypted.toString(CryptoJS.enc.Utf8);
    }

    this.token = localStorage.getItem('auth_token');
    // method to handle any additional initialization tasks.
  }

  goToAccount() {
    if (this.userLevel === '500') {
      this.accountUrl = `${environment.operatorDashboardUrl}/login_from_trans/my_account/${this.token}`;
    } else {
      this.accountUrl = '/dashboard/';
    }
  }
}
