import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { labelData } from 'src/app/modules/shared/models/labels/lable';

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  constructor(private http: HttpClient,) { }

  //Ceate Table
  public createLabel(body: any): Observable<any> {
    return this.http.post('/glomod/labeltool_create/', body);
  }
  //Get Table By Id
  public getLabelById(id: any): Observable<any> {
    return this.http.get(`/glomod/labeltoolget/?layer_id=${id}`);
  }
  //update Table
  public updateLabel(id: any, body: any): Observable<labelData> {
    return this.http.put<labelData>(`/glomod/labeltool/${id}/`, body).pipe(
      shareReplay()
    );
  }

}
