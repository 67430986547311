<div class="modal-dialog">
  <div class="modal-body" style="overflow-y: hidden">
    <div class="row">
      <div class="col-md-12">
        <h2>Thank you for using Parcel Viewer.</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p>
          The <strong>Live Share</strong> link used to reach this map has
          expired.
        </p>
        <p>
          We hope you found the map easy to use and the data readily accessible.
        </p>
        <p>Subscribing is easy. There are three plans to choose from:</p>
        <div class="plan"><span>Day Pass</span><span style="margin-left: 20px">$19</span></div>
        <div class="plan"><span>Monthly</span><span style="margin-left: 20px">$49</span></div>
        <div class="plan"><span>Annual</span><span style="margin-left: 20px">$490</span></div>

        <div class="subscribe"><a
          class="btn btn-primary"
          href="/parcelviewer/subscribe"
          style="margin: 1.2em 0"
          >Subscribe</a
        ></div>
        <p style="line-height: 10px">Need more information?</p>
        <p style="line-height: 10px">
          Go to the
          <a href="https://parcelviewer.mapport.com" target="_blank"
            >Parcel Viewer</a
          >
          website, or contact ENPLAN
        </p>
        <p style="line-height: 10px">Monday-Friday 9am-5pm Pacific</p>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="link"><a href="mailto:wmsgroup@enplan.com" target="_blank"
          >wmsgroup@enplan.com</a
        >
        &nbsp;&nbsp;&nbsp;
        <a href="tel:1-530-402-4783" target="_blank">(530) 402-4783</a>
        <a href="/login" style="float: right; margin-right: 10px">Log In</a></div>
      </div>
    </div>
  </div>
</div>
