<div class="container tabs mt-5" style="margin-top: 50px;">
  <mat-tab-group (selectedTabChange)="tabChanged($event)" mat-align-tabs="end" [selectedIndex]="6"
    animationDuration="0ms">
    <ng-container *ngFor="let tab of tabs">
      <mat-tab>
        <ng-template mat-tab-label *ngIf="tab.data.label !== 'Security' && tab.data.label !== 'Global Modification'">
          <a class="mat-label-text" href="{{tab.data.link}}" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template mat-tab-label *ngIf="tab.data.label === 'Global Modification'">
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template mat-tab-label *ngIf="tab.data.label === 'Security'">
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <!-- <ng-container *ngComponentOutlet="tab.component"></ng-container> -->
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>