
    <div class="modal-content error-modal-content">
        <div class="modal-body">
            <h4 id="heading-modal" class="head-modal">{{data.title}}</h4>
            <hr class="modal-hr-line">
            <p id="err-recurly-modal-body" class="inbody-modal">{{data.message}}</p>
            <div class="btn-container" style="text-align: right">
                <button type="button" class="btn accept-btn gernal-btn" data-dismiss="modal" (click)="close()" style="float: right;border-radius: 0px ; margin-top: 10px">OK</button>
            </div>
            <br>
            <br>
            <br>
        </div>
    </div>

<!-- <h4 class="head-modal" mat-dialog-title>
    {{data.title}}</h4>
    <hr class="modal-hr-line">
<mat-dialog-content>
    <p>{{data.message}}</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="gernal-btn" mat-button (click)="close()">Ok</button>
</mat-dialog-actions> -->