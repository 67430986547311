export function getMapStyle() {
  return [
    {
      icon: 'Alameda County',
      dataBasemap: ['Stamen Watercolor',
        'ENPLAN Imagery',
        'Stamen Terrain',
        'MapBox Simple',
        'Mapbox Hybrid',
        'Mapbox Terrain',
        'Stamen Toner',
        'Google Imagery',
        'Google Terrain',
        'Google Roadmap',
        'USGS 7.5 Quad',
        'USGS Imagery 2018',
        'NAIP Imagery',
        'OpenStreetMap',
        'NAIP Topo Hybrid',
        'US Topo',
        'Blank',
        'Google Hybrid',
        'NAIP High Zoom',
        'NAIP 2020',
      ],
      workSpace: [
        'anderson_cdp',
        'cite',
        'common',
        'Contours',
        'dev-mapport',
        'Dixie_Fire_Tracker',
        'Drought_Monitor',
        'fire_damaged_properties',
        'geonotice',
        'HurricaneTracks',
        'infillfinder',
        'it.geosolutions',
        'mapport',
        'NHDplusHR',
        'nurc',
        'parcel-viewer',
        'parcel_data_mapport',
        'sde',
        'sf',
        'Smoke_Forecast',
        'survey_manager',
        'TCPD_REST',
        'topp',
        'TrailLabs',
        'USGS_Services',
        'VCAHospitals',
        'Watershed_Boundary',
        'Watersheds_WFS',
        'wildfire_viewer',
      ],
      layerStyle: [
        '59senior',
        '880 Underpasses',
        'access',
        'address_points_meu',
        'after2010',
        'aggregated_owners',
        'aggregated_owners_25',
        'AIF_Relativity2',
        'AIF_Relativity3',
        'AIF_Relativity4',
        'AIF_Relativity5',
        'Alameda Relative Values5',
        'alameda_parks',
        'AlamedaPARCELS',
        'alertwildfire_camera_point',
        'apn_label',
        'Archive: tparcels - no scale rule',
        'atlasparcelsSF',
        'Bar 11 Ranch',
        'BART',
        'beat-test',
        'before2010',
        'black test 012218',
        'blkgrp',
        'bridges_caltrans',
        'Bright Polygon',
        'Building',
        'building_fp',
        'building_fp_test',
        'ca_counties_forPV',
        'cal_fire_all_units',
        'calfire_live_incidents',
        'California_Counties',
        'caltrans bridges non-standard',
        'altrans bridges standard',
        "caltrans_bridges_nb",

        "caltransdistricts",
        "CARE",
        "carrperim",
        "census",
        "CIQ",

        "circle",

        "City boundary appear at 2000ft",

        "Com_Site_FM",

        "Contour_10ft",

        "Contour_10ft_v2",

        "Contour_10ft_v3",

        "Contour_5ft",

        "counties_onesymb",

        "coverage_below_25",

        "densc1",

        "densc2",

        "densc3",

        "denstest",

        "Department_ed",

        "disabled",

        "District Office Locations",

        "districts",

        "DPA BIA",

        "DPA BLM",
        "DPA BOR",

        "DPA CDF",

        "DPA DOD",

        "DPA LOCAL",

        "DPA NASA",

        "DPA NPS",

        "DPA USFS USDA",

        "DPA USFW",

        "elem",

        "Elementary School Location Icon",

        "ENPLAN LiDAR Coverage",
        "ENPLAN Ortho Coverage",

        "enplan_job",

        "envelope",

        "ESAP",

        "Farmland_Mapping",

        "fault_zones",

        "Federal Lands",

        "Fedland_BLM",

        "Fedland_FS",

        "Fedland_NPS",

        "fhsz_High",

        "fhsz_Moderate",

        "fhsz_VeryHigh",

        "Fire Hazard Severity Zones 2",

        "Fire_Districts",

        "Fire_perimeter",

        "fire_perimeter_rsl",

        "FireHazardSeverityZone_LRA",

        "FireHazardSeverityZone_SRA",
        "firehazardseverityzones",

        "FRA Polygon",

        "fullunitsserved",

        "general_plan",

        "genpl_gwy",

        "gp_v2",

        "Green Dot",

        "height2",

        "heightc1",

        "heightc1_v2",

        "heightc2",

        "heightc2_v2",

        "heightc3",

        "heightc3_v2",

        "high",

        "High School Location Icon",

        "horning",

        "horning_2",

        "housetype",

        "if_alameda_class1_10to1",

        "if_alameda_class1_2to1",

        "if_alameda_class1_6to1",

        "if_alameda_class2_10to1",

        "if_alameda_class2_2to1",

        "if_alameda_class2_6to1",

        "if_alameda_class3_10to1",

        "if_alameda_class3_2to1",

        "if_alameda_class3_6to1",

        "if_alameda_class4_10to1",

        "if_alameda_class4_2to1",

        "if_alameda_class4_6to1",

        "if_alameda_vacant1",

        "if_alameda_vacant2",

        "if_alameda_vacant3",

        "if_alameda_vacant4",

        "if_los_angeles_class1_10to1",

        "if_los_angeles_class1_2to1",

        "if_los_angeles_class1_6to1",

        "if_los_angeles_class2_10to1",

        "if_los_angeles_class2_2to1",

        "if_los_angeles_class2_6to1",

        "if_los_angeles_class3_10to1",

        "if_los_angeles_class3_2to1",

        "if_los_angeles_class3_6to1",

        "if_los_angeles_class4_10to1",

        "if_los_angeles_class4_2to1",

        "if_los_angeles_class4_6to1",

        "ImportantFarmland",

        "JLID HQ",

        "jlid_boundary",

        "jlidtest cl 012918",

        "k-8",

        "K-8 School Location Icon",

        "LA_metro_rail",

        "landbase",

        "landslide_zones",

        "Leininger_EasementBoundary",

        "Leininger_Histowner",

        "Leininger_Irrigated1923",

        "Leininger_IrrigatedInterpreted",

        "Leininger_parcels",

        "Leininger_points_TNC",

        "Leininger_rs",

        "Leininger_zones_ENPLAN",

        "Leininger_zones_TNC",

        "Leininger_zones_TNC_dashed",

        "Lightening",

        "lightning_point",

        "lightning_point_fade1",

        "lightning_point_test",

        "lightning_point_test_circles",

        "Lightning_PosNeg_1hr",

        "Lightning_PosNeg_1hr_color_disc",

        "Lightning_PosNeg_1hr_scale_invariant",

        "Lightning_PosNeg_1hr_scale_invariant_CC",

        "Line Red",

        "liquefaction_zones",

        "lmu_apn_labels",

        "LMU_Buildings_LMUProvided",

        "LMU_MS_Structure_Points",

        "lmu_roads",

        "lowmod",

        "LRA Polygon",

        "Magenta Line",

        "Magenta Polygon",

        "maintenancepoints",

        "medinc_statewide",

        "meu_structure_point",

        "MEU_Structure_Points_Ranked_Scaled",

        "middle",

        "Middle School Location Icon",

        "MUDFULL",

        "NHD_canal_ditch",

        "NHD_connector",

        "NHD_pipeline",

        "NHD_streamriver",

        "NHD_Waterbody",

        "nws_warnings",

        "Orange Line",

        "Orange Polygon",

        "Orange square",

        "Orange Thin Polygon",

        "OtherRelatedLands",

        "ownerocc",

        "parcel_centroid",

        "parcel_centroid_geo",

        "parcels appear at zoom level 200meters",

        "Parcels- 3 Steps",

        "Parcels- 3 steps appear sooner",

        "Parcels- 3 Steps BLACK",

        "Parcels- 3 Steps BLACK original",

        "Parcels- 3 Steps original",

        "parcels_vacant",
        "parcels_vacant_land",

        "parcels_vacant_orange",

        "ParcelsbyType",

        "ParcelsbyType_ContraCosta",

        "ParcelsNoNeedToZoom",

        "Park",

        "pipes-scsd",

        "PLSS",

        "PLSS-Section",

        "PLSS-Township",

        "PLSS2",

        "pointdensitytest",

        "POINTS_STATEW_DOLLARS",

        "POINTS_STATEW_hours",

        "POINTSDOLLARS",

        "POINTSHOURS",

        "propyearyes",

        "PV Parcels - Black - No Scale Rule",

        "PV Parcels - White - No Scale Rule",

        "rasterBasicRamp",

        "Red Polygon",

        "Red-Orange Dot",

        "relativity",

        "remainderblue",

        "Residence_Points",

        "residence_points_2",

        "RESULTDOLLARS",

        "RESULTHOURS",

        "RHAcare",

        "road",

        "road_centerline",

        "road_class_tcpd",

        "RSL-100_norow",

        "RSL-100_row",

        "sample_MEDINC_subdiv",

        "sample_POVLEVEL",

        "Sandbox Boundary",

        "Schools",

        "scsd-apputanance",

        "scsd-countour",

        "scsd-hydrant",

        "scsd-meters",

        "scsd-pump",

        "scsd-tank",

        "scsd-valve",

        "sd_point_label",

        "Shasta10DEM",

        "Shasta10Hillshade",

        "shu_boundary",

        "Sidewalks",

        "sld-circlered",

        "sld-CityTehamaZoning",

        "sld-richvale-beats",

        "sld-richvale-boundary",
        "sld-richvale-canal_heading",

        "sld-richvale-canals",

        "sld-richvale-contour-6in",

        "sld-richvale-drain",

        "sld-richvale-fields",

        "sld-richvale-grav_del",

        "sld-richvale-hillshade",

        "sld-richvale-parcels_rid",

        "sld-richvale-pofu",

        "sld-richvale-predicted-yield",

        "sld-richvale-pump_del",

        "sld-richvale-soils",

        "sld-richvale-turnouts",

        "slope",

        "Slope1",

        "Slope30-39",

        "Slope30-39v2",

        "Slope40-49",

        "Slope40-49v2",

        "Slope50plus",

        "Slope50plusv2",

        "Sphere_of_Influence",

        "SRA Polygon",

        "star",

        "state",

        "STATE_MEDINCTRACT",

        "STATE_POVLEVTRACT",

        "StateBoundaries",

        "StateBoundaries_White",

        "Structure_Points_LMU_Scaled",
        "Structure_Points_Ranked_Scaled",

        "styled_label_points",

        "styled_label_pointsCL",

        "subdiv",

        "tcpd_cities",

        "tcpd_gp",

        "tcpd_orange_bound",

        "tcpd_permit_point",

        "tcpd_road_class",

        "tcpd_road_class2",

        "tcpd_wa",

        "tcpd_zon",

        "tehama_districts",

        "tehama_districts2",

        "tehama_districts3",

        "tehama_districts4",

        "test_genplan_shasta_county",

        "TGU_Preplan_polygon",

        "TGU_square_w_labels",

        "TGU_structures",

        "Township",

        "tract",

        "tracts_ethnicity",

        "usfs_roads",

        "usfs_roads_v2",

        "usgs_3dep",

        "utilization_below_20",

        "utilization_below_30",

        "vacant",

        "vacant_8001",

        "vacant_8002",

        "vacant_8003",

        "vacant_8007",

        "vacant_8008",

        "vacant_parcels",

        "viirs_fire_age_1_testcopy1",

        "weather_stations",

        "White and Red Border Polygon",

        "White Border Polygon",

        "wilderness_study_areas",

        "WildernessArea",

        "WildernessStudyArea",

        "WV_building_footprint",

        "Yellow Circle",

        "Yellow Polygon",

        "yoni_address_point",

        "yoni_matched_parcel",

        "zip_census",

        "zip_esri",

        "ZipCodes",

        "zoning",
        "zoning_full_cor",

        "Zoning_open_space",

        "Zoning_Redding",
      ],

    },
  ]
}
