<app-global-mod-header></app-global-mod-header>
<app-layers-tab></app-layers-tab>
<div class="container">
  <div class="row page-title" style="margin-right: 0px">
    <div class="col-md-7 mt-3 p-0">
      <h3>
        <a routerLink="/global">Global Modification</a> »
        <a routerLink="/global">Layers</a> »
        <a (click)="backToLayer()" class="cursor-pointer">{{ layerName }}</a> »
        <a>AP Map Feed</a>
      </h3>
    </div>
    <div class="col-md-5">
      <ul class="actions navbar-right">
        <li role="presentation">
          <a class="mat-tab-label-active">AP Map Feed</a>
        </li>
        <li role="presentation">
          <a routerLink="/pdfTable">PDF table</a>
        </li>
        <li role="presentation">
          <a routerLink="/labels">Labels</a>
        </li>
        <li role="presentation">
          <a routerLink="/showFields">Show Fields</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-12 mb-5" style="padding-left: 0px; padding-right: 0px">
    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="heading">
        <h4 class="panel-title">
          <a> AP Map Feed </a>
        </h4>
      </div>

      <form action="." method="POST">
        <div class="panel-collapse collapse in">
          <div class="panel-body">
            <!-- <div class="form-group">
              <label class="control-label" for="id_has_home">County</label>
              <div>
                <select class="form-control" id="county" name="county" [(ngModel)]="ApMapFeed.county">
                  <option value="none">None</option>

                  <option value="alameda">Alameda</option>

                  <option value="alpine">Alpine</option>

                  <option value="amador_county">Amador County</option>

                  <option value="butte">Butte County</option>

                  <option value="calaveras">Calaveras</option>

                  <option value="colusa">Colusa</option>

                  <option value="contra_costa">Contra Costa</option>

                  <option value="del_norte">Del Norte</option>

                  <option value="el_dorado_county">El Dorado County</option>

                  <option value="fresno">Fresno</option>

                  <option value="glenn">Glenn</option>

                  <option value="humboldt">Humboldt</option>

                  <option value="imperial">Imperial</option>

                  <option value="inyo">Inyo</option>

                  <option value="kern">Kern</option>

                  <option value="kings">Kings</option>

                  <option value="lake">Lake</option>

                  <option value="lassen">Lassen</option>

                  <option value="los_angeles">Los Angeles</option>

                  <option value="madera">Madera</option>

                  <option value="marin">Marin</option>

                  <option value="mariposa">Mariposa</option>

                  <option value="mendocino">Mendocino</option>

                  <option value="merced_county">Merced County</option>

                  <option value="modoc">Modoc</option>

                  <option value="mono_county">Mono County</option>

                  <option value="monterey">Monterey</option>

                  <option value="napa">Napa</option>

                  <option value="nevada">Nevada</option>

                  <option value="orange">Orange</option>

                  <option value="placer">Placer</option>

                  <option value="plumas">Plumas</option>

                  <option value="riverside">Riverside</option>

                  <option value="sacramento">Sacramento</option>

                  <option value="san_benito">San Benito</option>

                  <option value="san_bernardino">San Bernardino</option>

                  <option value="san_diego">San Diego</option>

                  <option value="san_francisco">San Francisco</option>

                  <option value="san_joaquin">San Joaquin</option>

                  <option value="san_luis_obispo">San Luis Obispo</option>

                  <option value="san_mateo">San Mateo</option>

                  <option value="santa_barbara">Santa Barbara</option>

                  <option value="santa_clara">Santa Clara</option>

                  <option value="santa_cruz">Santa Cruz</option>

                  <option value="shasta">Shasta</option>

                  <option value="sierra">Sierra</option>

                  <option value="siskiyou">Siskiyou</option>

                  <option value="solano">Solano</option>

                  <option value="sonoma_county">Sonoma County</option>

                  <option value="stanislaus_county">Stanislaus County</option>

                  <option value="sutter">Sutter</option>

                  <option value="tehama">Tehama</option>

                  <option value="trinity">Trinity</option>

                  <option value="tulare">Tulare</option>

                  <option value="tuolumne">Tuolumne</option>

                  <option value="ventura">Ventura</option>

                  <option value="yolo_county">Yolo County</option>

                  <option value="yuba">Yuba</option>
                </select>
                <span class="help-block">The county to which the AP Map feed should connect.</span>
              </div>
            </div> -->
            <div class="form-group">
              <label class="control-label" for="id_has_home">APN Field</label>
              <div>
                <select (change)="selectApnField($event)" [(ngModel)]="ApMapFeed.table" class="form-control" id="table"
                  name="table">

                  <option value="{{ data }}" *ngFor="let data of feedFields">
                    {{ data }}
                  </option>
                </select>
                <span class="help-block">The APN field that will be used to connect to the map
                  feed.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="action-buttons">
          <br />

          <div class="form-group">
            <a class="btn btn-default" routerLink="/layers" style="margin: 0px 5px 0px 0px;">
              <span class="glyphicon glyphicon-remove"></span> Cancel
            </a>

            <button type="submit" class="btn btn-primary" (click)="submitForm()">
              <span class="glyphicon glyphicon-floppy-disk"></span> Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-spinner [fullScreen]="true" type="" size="medium" bdColor="rgba(0, 0, 0, 0)">
  <p class="loading"> <img src="/assets/img/wheel.svg" alt="Girl in a jacket" width="100px" height="100px"> </p>
</ngx-spinner>