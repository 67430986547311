<div class="main_wrapper" [style.width.px]="sidenavWidth">
  <!-- Any Type of Selection -->
  <div
    id="info-panel"
    class="mapport-panel mapport-panel-right"
    (click)="showLabelSwitch(0, $event)"
  >
    <div class="choices-panel wrapper">
      <div class="content">
        <h3 style="font-family: arial !important; padding-left: 1px !important" [style.font-size.px]="fontSize">
          Results<span id="features-count" [style.font-size.px]="fontSize">{{ result?.length }}</span>
          <div class="result-label-dropdown" *ngIf="tools.layer_label">
            <div
              *ngIf="switchVisibleNow && result.length >= 1 && isLabelPresent"
              class="result-label"
              id="result_label"
              (click)="showLabelSwitch(1, $event)"
            >
              Labels
              <i
                id="result_label"
                [ngClass]="showSwitch ? 'up-label-caret' : 'down-label-caret'"
              ></i>
            </div>
            <div
              *ngIf="showSwitch"
              id="label-dropdown"
              class="result-label-dropdown-content result-label-dropdown-content-show"
            >
              When multiple parcels are selected, number them in the map and
              data panel.<br />
              <mat-slide-toggle
                id="hideShowLable"
                (change)="hideShowLable()"
                [(ngModel)]="sidenavService.checkLabel"
                [(ngModel)]="mapService.checkLabel"
              ></mat-slide-toggle>
            </div>
          </div>
        </h3>

        <ul class="choices">
          <li class="dummy" [style.font-size.px]="fontSize" *ngIf="result?.length === 0">No results found.</li>

          <ng-container *ngIf="result">
            <li
              *ngFor="let r of result; index as i"
              id="feature-454586"
              (click)="callFeatureName(r, i)"
              (mouseenter)="fire($event)"
              (mouseleave)="fire($event)"
            >
              <a class="show-feature">
                <div
                  *ngIf="showLabelOnList && switchVisibleNow && r.label"
                  class="pull-left property-label"
                >
                  {{ r.label }}
                </div>

                <div
                [style.font-size.px]="fontSize"
                  (mouseenter)="hover(r)"
                  (mouseleave)="unHover()"
                  class="label has-icon"
                  [ngStyle]="{
                    'background-image': isEmptyObject(r.style)
                      ? 'url(' + r.icon + ')'
                      : 'url(' +
                        r.style[sidenavService.currentLoadedMap].icon +
                        ')'

                  }"
                  [ngClass]="
                    sidenavService.activeFeatureList[i] ? 'visited' : ''
                  "
                >
                  {{ r.typeName }}
                </div>
                <div class="value" [style.font-size.px]="fontSize">
                  <ng-container
                    class="val_container"
                    [ngClass]="
                      sidenavService.activeFeatureList[i] ? 'visited' : ''
                    "
                  >
                    {{ r.fieldname ? r.fieldname : "[no data]" }}
                  </ng-container>
                </div>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

  <div class="btn">
    <a class="closer button closerToolTip3">
      <img
      [style.right.px]="zoomRight"
        (click)="clickToZoom()"
        class="save-img common-btns tool-toolTip3"
        src="/assets/img/tools/zoom-to.png"
        alt="closer" /><span
        class="tooltiptext3"
        [innerHtml]="
          mapService.templatetoolTips['zoom_to_selection_right_panel']
        "
      ></span
    ></a>
    <a class="closer button closerToolTip2">
      <img
      [style.right.px]="viewRight1"
        (click)="viewAndExportData()"
        class="common-btns tool-toolTip2"
        [ngClass]="result.length > 0 ? 'view_data' : 'viewDataLeft'"
        src="/assets/img/pv/data_view_icon20X20.png"
        alt="back" /><span
        class="tooltiptext2"
        [innerHtml]="mapService.templatetoolTips['data_view']"
      ></span
    ></a>
    <a class="closer button closerToolTip1">
      <img
      [style.left.px]="backLeft"
        (click)="goBack()"
        class="back-img common-btns tool-toolTip1"
        src="/assets/img/tools/back-pv3.png"
        alt="back" /><span
        class="tooltiptext1"
        [style.left.px]="tooltip1"
        [innerHtml]="mapService.templatetoolTips['back_right_panel']"
      ></span
    ></a>
    <a class="closer button closerToolTip">
      <img
      [style.left.px]="closeLeft"
        (click)="closeRightpanel()"
        class="closer-img common-btns tool-toolTip"
        src="/assets/img/tools/close-pv3.png"
        alt="closer" /><span
        class="tooltiptext"
        [style.left.px]="tooltip2"
        [innerHtml]="mapService.templatetoolTips['close_right_panel']"
      ></span
    ></a>
  </div>
</div>
