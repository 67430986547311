import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapServiceService } from '../../../services/Map-service/map-service.service';
import { SideNavService } from '../../../services/sidenav/side-nav.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-view-and-export-data',
  templateUrl: './view-and-export-data.component.html',
  styleUrls: ['./view-and-export-data.component.css'],
})
export class ViewAndExportDataComponent implements OnInit {
  select: any;
  boundaries: any;
  mapView: any;
  parcelPresent: any;
  dataview: any;
  mailLabels: any;
  csvView: any;
  pdfView: any;
  decrypted: any;
  message = 'SuperSecret!!';
  //template system tooltips setting
  myOptions = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class',
  };
  myOptions1 = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class1',
  };
  loginUSer: any;
  //template system tooltips setting end
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private SideNavService: SideNavService,
    public mapService: MapServiceService
  ) {
   //
  }

  ngOnInit(): void {
    this.decrypted = localStorage.getItem('userAccessLevel');
    if (this.decrypted) {
      const decrypted = CryptoJS.AES.decrypt(this.decrypted, this.message);
      this.loginUSer = decrypted.toString(CryptoJS.enc.Utf8);
    }

    this.dataview =
      this.mapService.mapData.map_data.map_data.tools['data_view'];
      if(this.dataview){
        this.select = 1;
      }else if( this.mailLabels){
        this.select = 2;
      }
      else if( this.pdfView){
        this.select = 3;
      }
      else if(  this.csvView){
        this.select = 4;
      }else{
        this.select = 5;
      }
    this.mailLabels = this.mapService.mapData.map_data.map_data.tools['labels'];
    this.pdfView = this.mapService.mapData.map_data.map_data.tools['pdf_table'];
    this.csvView = this.mapService.mapData.map_data.map_data.tools['csv'];
    this.boundaries = this.mapService.mapData.map_data.map_data.layers;
    if (this.data.features.length) {
      for (let i = 0; i < this.boundaries.length; i++) {
        for (let j = 0; j < this.data.features.length; j++) {
          if (
            this.boundaries[i].id == this.data.features[j].layer_id &&
            (this.boundaries[i].name == 'Parcels' ||
              this.boundaries[i].name.toLowerCase().includes('parcels'))
          ) {
            this.parcelPresent = true;
            break;
          }
        }
      }
    } else {
      for (let i = 0; i < this.boundaries.length; i++) {
        if (
          this.boundaries[i].id == this.data.features.layer_id &&
          (this.boundaries[i].name == 'Parcels' ||
            this.boundaries[i].name.toLowerCase().includes('parcels'))
        ) {
          this.parcelPresent = true;
          break;
        }
      }
    }
  }

  changeMenu(number: any): void {
    this.select = number;
    this.SideNavService.selectedPanelInViewData = this.select;
  }

  closeDialog(): void {
    //hide before closing
    const id = document.getElementById('export-popup') as HTMLElement;
    id.style.display = 'none';
    this.dialog.closeAll();
  }
}
