import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { tabledata } from 'src/app/modules/shared/models/table/table.model';

@Injectable({
  providedIn: 'root'
})
export class LayerService {
  layerdata: any;
  layerId: any;
  layerIds: number[] = [];
  layerData: any[] = [];
  layerListUpdate: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient,) { }

  //Get All Layer
  public getAllLayer(pageSize: any,page: any, searchdata: any): Observable<any> {
    return this.http.get(`/glomod/layers/?page_size=${pageSize}&page=${page}&search=${searchdata}`).pipe(
      shareReplay()
    );
  }
  //Get Layer By Id
  public getLayerById(id: any) : Observable<any> {
    return this.http.get(`/glomod/layer_operations/${id}/`) .pipe(
      shareReplay()
    );
  }
  //Ceate Layer
  public createLayer(body: any): Observable<any> {
    return this.http.post('/glomod/layers/', body).pipe(
      shareReplay()
    );
  }
  //Edit Layer
  public editLayer(id: any, layerData: any): Observable<tabledata> {
    return this.http
      .patch<tabledata>(`/glomod/layer_operations/${id}/`, layerData)
      .pipe(
        shareReplay()
      );
  }
  //Delete Layer
  public deleteLayer(ids: any): Observable<any> {
    return this.http.delete(`/glomod/delete-layer/?ids=${ids}`,)
  }

  public refreshLayer(value: any) {
    this.layerListUpdate.next(value);
  }

  //Get Layer By Id
  public getLayerSchema() : Observable<any> {
    return this.http.get(`/glomod/get-schema-info/`) .pipe(
      shareReplay()
    );
  }


  public applyInheritance(id: any) : Observable<any> {
    return this.http.get(`/glomod/apply-inheritance/?layer_id=${id}`) .pipe(
      shareReplay()
    );
  }

  public getWorkSpace(): Observable<any> {
    return this.http.get(`/glomod/layer/workspaces/`).pipe(
      shareReplay()
    );
  }

  public getStyleOfWorkspace(workspace : string): Observable<any> {
    return this.http.get(`/glomod/layer/workspaces/${workspace}/styles/`).pipe(
      shareReplay()
    );
  }

  public getStyleOfLayer(layer : string): Observable<any> {
    return this.http.get(`/glomod/layer/${layer}/base-style/`).pipe(
      shareReplay()
    );
  }
  public getApnFields(layerId: any): Observable<any> {
    return this.http.get(`/glomod/layer/${layerId}/ap_map_feed/`).pipe(
      shareReplay()
    );
  }

  public addMapFeed(layerId: any, feedData: any): Observable<any> {
    return this.http.post(`/glomod/layer/${layerId}/ap_map_feed/`, feedData).pipe(
      shareReplay()
    );
  }

}
