import { Component, OnDestroy, OnInit } from '@angular/core';
import { TabItem } from 'src/app/modules/shared/models/tab/tab.model';
import { TabService } from 'src/app/services/tab/tab.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CourtesyService } from 'src/app/services/courtesy.service';
import { MatDialog } from '@angular/material/dialog';
import { SubscribErrorModalComponent } from 'src/app/modules/shared/components/modals/subscrib-error-modal/subscrib-error-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SnackbarService } from 'src/app/services/snackbarService/snackbar.service';

@Component({
  selector: 'app-courtesy-account',
  templateUrl: './courtesy-account.component.html',
  styleUrls: ['./courtesy-account.component.css'],
})
export class CourtesyAccountComponent implements OnInit, OnDestroy {
  accountForm!: FormGroup;
  mapsData: any[] = [];
  addOns: any;
  addOnsData: any;
  showLayersButton = false;
  hideTab: any = false;
  tabs: TabItem[] | undefined;
  Maintab: TabItem[] | undefined;
  selectedTab = 0;
  submitDisable = false;
  constructor(
    private fb: FormBuilder,
    private service: CourtesyService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public tabService: TabService,
    public router: Router,
    public _snackbar: SnackbarService
  ) {
    this.accountForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: [''],
    });
  }
  ngOnInit(): void {
    // Tab State Handling
    const state = this.tabService.getState();
    // Set to 0 if tabState not present
    this.selectedTab = state.tabState || 0;

    if (state.tabState === 1) {
      this.showLayersButton = true;
    } else {
      this.showLayersButton = false;
    }
    // Show State
    // this.tabService.activeTab$.subscribe((tab) => {
    //   // console.log('active tab set: ', tab);
    // });
    this.Maintab = this.tabService.getTabs();
    this.tabService.activeTab$.next(this.Maintab[10]);
    this.tabs = this.tabService.getCourtesyTabs();

    this.service.mapsData.subscribe((res) => {
      this.mapsData = res.mapsData;
    });
    this.service.addOns.subscribe((res) => {
      this.addOnsData = res;
      this.addOns = {
        collaborator_seats: {
          quantity: res.collab_quantity,
          expiry_duration: res.collab_expiry,
        },
        outreach: {
          expiry_duration: res.outreach_expiry,
        },
      };
    });
  }

  submit(): void {
    this.submitDisable = true;
    if (this.accountForm.invalid) {
      this.dialog.open(SubscribErrorModalComponent, {
        data: { title: 'Error', content: 'Fill the Required Input fields' },
      });
      this.submitDisable = false;
      return;
    }
    this.service.courtesyData(true);
    for (let i = 0; i < this.mapsData.length; i++) {
      delete this.mapsData[i].expiryCheck;
      if (
        !this.mapsData[i].map_id ||
        !this.mapsData[i].privilege ||
        !this.mapsData[i].expiry_duration ||
        this.mapsData[i].expiry_duration < 1
      ) {
        this.dialog.open(SubscribErrorModalComponent, {
          data: {
            title: 'Error',
            content: 'Choose Map & Valid Expiration!',
          },
        });
        this.submitDisable = false;
        return;
      }
    }
    if (this.addOnsData.collab || this.addOnsData.outreach) {
      if (!this.addOnsData.collab_expiry_check && this.addOnsData.collab) {
        this.dialog.open(SubscribErrorModalComponent, {
          data: {
            title: 'Error',
            content: 'Add Colaborator Expiry',
          },
        });
        this.submitDisable = false;
        return;
      } else if (
        this.addOnsData.collab_quantity < 1 ||
        this.addOnsData.collab_expiry < 1
      ) {
        this.dialog.open(SubscribErrorModalComponent, {
          data: {
            title: 'Error',
            content: 'Wrong Input in Add-ons',
          },
        });
        this.submitDisable = false;
        return;
      }
      if (!this.addOnsData.outreach_expiry_check && this.addOnsData.outreach) {
        this.dialog.open(SubscribErrorModalComponent, {
          data: {
            title: 'Error',
            content: 'Add Outreach Expiry',
          },
        });
        this.submitDisable = false;
        return;
      } else if (this.addOnsData.outreach_expiry < 1) {
        this.dialog.open(SubscribErrorModalComponent, {
          data: {
            title: 'Error',
            content: 'Wrong Input in Add-ons',
          },
        });
        this.submitDisable = false;
        return;
      }
    }

    this.createAccount();
  }
  createAccount() {
    this.spinner.show();
    const body = {
      first_name: this.accountForm.value.firstName,
      last_name: this.accountForm.value.lastName,
      email: this.accountForm.value.email,
      phone: this.accountForm.value.phone,
      maps: this.mapsData,
      add_ons: this.addOns,
    };
    this.service.registerUser(body).subscribe(
      () => {
        this.spinner.hide();
        this._snackbar.openSnackBar('Account Created Successfully');
        this.submitDisable = false;
      },
      (err) => {
        this.spinner.hide();
        this._snackbar.openSnackBar(err.error.message);
        this.submitDisable = false;
      }
    );
  }
  tabSelected(tab: TabItem) {
    this.tabService.activeTab$.next(tab);
    this.tabService.hideTab = true;
  }

  setTabState(tabId: number): void {
    this.selectedTab = tabId;
    this.tabService.setState({ tabState: tabId });
    if (tabId === 1) {
      this.showLayersButton = true;
    } else {
      this.showLayersButton = false;
    }
  }
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    if (tabChangeEvent.index == 6) {
      this.router.navigateByUrl('global');
    } else if (tabChangeEvent.index == 7) {
      this.router.navigateByUrl('securityControl');
    } else if (tabChangeEvent.index == 8) {
      this.router.navigateByUrl('plans');
    } else if (tabChangeEvent.index == 9) {
      this.router.navigateByUrl('subscribePages');
    } else if (tabChangeEvent.index == 10) {
      this.router.navigateByUrl('courtesyAccount');
    }
  };
  ngOnDestroy() {
    this.tabService.setState({ tabState: this.selectedTab });
  }
}
