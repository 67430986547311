<div class="panel mb-3" [ngClass]="editLayer ? 'mt-4' : 'mt-5'">
  <div class="panel-header">
    <h3 class="panel-title">Style by Base Map</h3>
  </div>
  <div class="panel-body">
    <div class="form-group">
      <label class="control-label" for="id_sql_touch_field">select style from layer</label>
      <select class="form-control db-meta db-column main-select-view" name="sql_touch_field"
        formcontrolname="styleLayer" (change)="onMainLayerSelect($event)">
        <!--  [(ngModel)]="product.sql_touch_field"  -->
        <ng-container>
          <ng-container *ngFor="let layes of existingLayer">
            <option [value]="layes.id">{{ layes.map_name +':'+layes.name }}</option>
          </ng-container>
        </ng-container>
      </select>

      <!-- <div class="help-block">
          If provided, a timestamp column to be updated whenever a feature
          is modified.
        </div> -->
    </div>
    <div class="container">
      <table class="w-100">
        <thead>
          <tr>
            <th></th>
            <th>Select Workspace</th>
            <th>Layer Style</th>
            <th>Icon URL</th>
          </tr>
        </thead>
        <tbody class="w-100" *ngIf="!editLayer">
          <tr class="w-100" *ngFor="let maps of baseMap">
            <td class="control-label-table">
              <label for="id_sql_touch_field">
                {{ maps.name }}
              </label>
            </td>
            <td>
              <select class="form-control db-meta db-column" name="sql_touch_field" formcontrolname="styleLayer"
                (change)="onWorkSpaceSelect($event,maps.id,true)">
                <ng-container>
                  <ng-container *ngFor="let ws of workspace">
                    <option [value]="ws.name" [selected]="ws.name === 'mapport'">
                      {{ ws.name }}
                    </option>
                  </ng-container>
                </ng-container>
              </select>
            </td>
            <td>
              <select class="form-control db-meta db-column" name="sql_touch_field" id="{{maps.id}}"
                formcontrolname="styleLayer" (change)="onLayerSelect($event,maps.id)">
                <ng-container>
                  <ng-container *ngFor="let style of maps.styleList">
                    <option [value]="style.name">{{ style.name }}</option>
                  </ng-container>
                </ng-container>
              </select>
            </td>
            <td>
              <div class="form-group">
                <input type="text" name="map_server" required id="id_map_server" class="form-control"
                  placeholder="Icon URL" (click)="inputClick($event,maps.id)" (input)="onIconChange()" />
              </div>
            </td>
          </tr>
        </tbody>


        <!-- Edit -->
        <tbody class="w-100" *ngIf="editLayer">
          <tr class="w-100" *ngFor="let maps of baseMap;">
            <!-- <div *ngFor="let loaded of getLayerData"></div> -->

            <td class="control-label-table">
              <div>
                <label for="id_sql_touch_field">
                  {{ maps.name }}
                </label>
              </div>

            </td>

            <!-- <td class="control-label-table" *ngFor="">
              <label for="id_sql_touch_field" >
                {{ loaded.base_map }}
              </label>
            </td> -->
            <td>
              <select class="form-control db-meta db-column" name="sql_touch_field" formcontrolname="styleLayer"
                (change)="onWorkSpaceSelect($event,maps.id,true)">
                <ng-container>
                  <ng-container *ngFor="let ws of workspace">
                    <option [value]="ws.name" [selected]="ws.name === maps.workspace">
                      {{ ws.name }}
                    </option>
                  </ng-container>
                </ng-container>
              </select>
            </td>
            <td>
              <select class="form-control db-meta db-column" name="sql_touch_field" id="{{maps.id}}"
                formcontrolname="styleLayer" (change)="onLayerSelect($event,maps.id)">
                <ng-container>
                  <ng-container *ngFor="let style of maps.styleList">
                    <option [value]="style.name" [selected]="style.name === maps.layername">{{ style.name }}</option>
                  </ng-container>
                </ng-container>
              </select>
            </td>
            <td>
              <div class="form-group">
                <input type="text" name="map_server" required id="id_map_server" class="form-control"
                  placeholder="Icon URL" (click)="inputClick($event,maps.id)" value="{{maps.icon_url}}"
                  (input)="onIconChange()" />
              </div>
            </td>
          </tr>
        </tbody>
        <!-- Edit Ends -->


      </table>
    </div>
  </div>
</div>
<div class="action-buttons mb-3">
  <div class="form-group">
    <a (click)="backToMain()" class="btn btn-default">
      <span class="glyphicon glyphicon-remove"></span> Cancel
    </a>

    <button type="submit" class="btn btn-primary" style="margin-left: 10px" (click)="saveStyelList()">
      <span class="glyphicon glyphicon-floppy-disk"></span> Save
    </button>
  </div>
</div>