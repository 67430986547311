import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-collaborator-new-account',
  templateUrl: './collaborator-new-account.component.html',
  styleUrls: ['./collaborator-new-account.component.css'],
})
export class CollaboratorNewAccountComponent implements OnInit {
  constructor(private dialog: MatDialog, public auth: AuthService) {
    //
  }

  ngOnInit(): void {
    //
  }

  close(): void {
    this.dialog.closeAll();
  }
  logOut(): void {
    localStorage.clear();
    this.auth.user = false;
  }
}
