import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { TabItem } from 'src/app/modules/shared/models/tab/tab.model';
import { TabService } from 'src/app/services/tab/tab.service';

@Component({
  selector: 'app-subscribe-pages',
  templateUrl: './subscribe-pages.component.html',
  styleUrls: ['./subscribe-pages.component.css'],
})
export class SubscribePagesComponent implements OnInit, OnDestroy {
  showLayersButton = false;
  hideTab: any = false;
  tabs: TabItem[] | undefined;
  Maintab: TabItem[] | undefined;
  selectedTab = 0;
  constructor(public tabService: TabService, public router: Router) {}

  ngOnInit(): void {
    // Tab State Handling
    const state = this.tabService.getState();
    // Set to 0 if tabState not present
    this.selectedTab = state.tabState || 0;

    if (state.tabState === 1) {
      this.showLayersButton = true;
    } else {
      this.showLayersButton = false;
    }
    // Show State
    this.tabService.activeTab$.subscribe(() => {
      // console.log('active tab set: ', tab);
    });
    this.Maintab = this.tabService.getTabs();
    this.tabService.activeTab$.next(this.Maintab[8]);
    this.tabs = this.tabService.getSubscribePagesTabs();
  }
  tabSelected(tab: TabItem) {
    this.tabService.activeTab$.next(tab);
    this.tabService.hideTab = true;
  }

  setTabState(tabId: number): void {
    this.selectedTab = tabId;
    this.tabService.setState({ tabState: tabId });
    if (tabId === 1) {
      this.showLayersButton = true;
    } else {
      this.showLayersButton = false;
    }
  }
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    if (tabChangeEvent.index == 6) {
      this.router.navigateByUrl('global');
    } else if (tabChangeEvent.index == 7) {
      this.router.navigateByUrl('securityControl');
    } else if (tabChangeEvent.index == 8) {
      this.router.navigateByUrl('plans');
    } else if (tabChangeEvent.index == 9) {
      this.router.navigateByUrl('subscribePages');
    }
  };
  ngOnDestroy() {
    this.tabService.setState({ tabState: this.selectedTab });
  }
}
