import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { pdfTabel } from 'src/app/modules/shared/models/pdfTable/pdfTable';
@Injectable({
  providedIn: 'root'
})
export class PdfTableService {

  constructor(private http: HttpClient,) { }
  //Get Table By Id
  public getPdfTableById(id: any): Observable<any> {
    return this.http.get(`/glomod/pdftabeltoolget/?layer_id=${id}`);
  }
  //Ceate Table
  public createPdfTable(body: any): Observable<any> {
    return this.http.post('/glomod/pdftable_create/', body);
  }
  //Update Table
  public editPdfTable(id: any, tableData: any): Observable<pdfTabel> {
    return this.http
      .put<pdfTabel>(`/glomod/pdftabletool/${id}/`, tableData)
      .pipe(
        shareReplay()
      );
  }
}
