<app-global-mod-header></app-global-mod-header>
<app-layers-tab></app-layers-tab>
<div class="container">
  <div class="row page-title" style="margin-right: 0px">
    <div class="col-md-7 mt-3 p-0">
      <h3>
        <a routerLink="/global">Global Modification</a> »
        <a routerLink="/global">Layers</a> » <a (click)="backToLayer()" class="cursor-pointer">{{ layerName }}</a> »
        <a>Fields</a>
      </h3>
    </div>
    <div class="col-md-5">
      <ul class="actions navbar-right">
        <li role="presentation">
          <a [routerLink]="apMapFeed">AP Map Feed</a>
        </li>
        <li role="presentation">
          <a routerLink="/pdfTable">PDF table</a>
        </li>
        <li role="presentation">
          <a routerLink="/labels">Labels</a>
        </li>
        <li role="presentation">
          <a routerLink="/showFields" class="mat-tab-label-active">Show Fields</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="container mt-5">
  <div class="align-items-center" style="overflow-x: auto">
    <table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8" cdkDropList
      [cdkDropListData]="dataSource" (cdkDropListDropped)="dropTable($event)">
      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <img class="grip" src="/assets/img/grip.png" alt="::" />
        </td>
      </ng-container>

      <!-- + Column -->
      <ng-container matColumnDef="+">
        <th mat-header-cell *matHeaderCellDef>+</th>
        <td mat-cell *matCellDef="let element">
          <input type="checkbox" (ngModelChange)="onChange($event, element)" [(ngModel)]="element.visible" />
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element; index as i">
          <input id="id_field_set-0-name" type="text" [(ngModel)]="element.name" maxlength="255" />
        </td>
      </ng-container>

      <!-- DB Column -->
      <ng-container matColumnDef="dbColumn">
        <th mat-header-cell *matHeaderCellDef>DB Column</th>
        <td mat-cell *matCellDef="let element">
          <input type="text" [(ngModel)]="element.sql_name" />
        </td>
      </ng-container>

      <!-- Type -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element">
          <select [(ngModel)]="element.type" name="type">
            <option *ngFor="let types of type; index as t" [value]="types.value"
              [selected]="types.type === element.type">
              {{ types.type }}
            </option>
          </select>
        </td>
      </ng-container>
      <!-- Unit -->
      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef>Unit</th>
        <td mat-cell *matCellDef="let element">
          <input type="text" [(ngModel)]="element.unit" />
        </td>
      </ng-container>
      <!-- visibility -->
      <ng-container matColumnDef="visibility">
        <th mat-header-cell *matHeaderCellDef>Visibility</th>
        <td mat-cell *matCellDef="let element">
          <select [(ngModel)]="element.visibility" name="visibility">
            <option *ngFor="let types of accessLevel; index as t" [value]="types.value"
              [selected]="types.type === element.visibility">
              {{ types.type }}
            </option>
          </select>
        </td>
      </ng-container>

      <!-- Queryability -->
      <ng-container matColumnDef="queryability">
        <th mat-header-cell *matHeaderCellDef>Queryability</th>
        <td mat-cell *matCellDef="let element">
          <select name="field_set-0-type" id="id_field_set-0-type" [(ngModel)]="element.quicksearchable">
            <option *ngFor="let types of accessLevel; index as t" [value]="types.value"
              [selected]="types.type === element.quicksearchable">
              {{ types.type }}
            </option>
          </select>
        </td>
      </ng-container>
      <!-- Editability -->
      <ng-container matColumnDef="editability">
        <th mat-header-cell *matHeaderCellDef>Editability</th>
        <td mat-cell *matCellDef="let element">
          <select name="field_set-0-type" id="id_field_set-0-type" [(ngModel)]="element.editability">
            <option *ngFor="let types of accessLevel; index as t" [value]="types.value"
              [selected]="types.type === element.editability">
              {{ types.type }}
            </option>
          </select>
        </td>
      </ng-container>
      <!-- autoComplete -->
      <ng-container matColumnDef="autoComplete">
        <th mat-header-cell *matHeaderCellDef>Auto Complete</th>
        <td mat-cell *matCellDef="let element">
          <select [(ngModel)]="element.autocomplete" name="field_set-0-type" id="id_field_set-0-type">
            <option *ngFor="let types of accessLevel; index as t" [value]="types.value"
              [selected]="types.type === element.autocomplete">
              {{ types.type }}
            </option>
          </select>
        </td>
      </ng-container>
      <!-- Search -->
      <ng-container matColumnDef="search">
        <th mat-header-cell *matHeaderCellDef>Search</th>
        <td mat-cell *matCellDef="let element">
          <select [(ngModel)]="element.autocomplete" name="field_set-0-type" id="id_field_set-0-type">
            <option *ngFor="let types of accessLevel; index as t" [value]="types.value"
              [selected]="types.type === element.autocomplete">
              {{ types.type }}
            </option>
          </select>
        </td>
      </ng-container>
      <!-- toolTip -->
      <ng-container matColumnDef="toolTip">
        <th mat-header-cell *matHeaderCellDef>Tool Tip</th>
        <td mat-cell *matCellDef="let element">
          <!-- <input  type="text" [(ngModel)]="element.tool_tip" /> -->
          <textarea [(ngModel)]="element.tool_tip"></textarea>
        </td>
      </ng-container>
      <!-- Capture -->
      <ng-container matColumnDef="capture">
        <th mat-header-cell *matHeaderCellDef>Capture</th>
        <td mat-cell *matCellDef="let element">
          <select [(ngModel)]="element.capture" name="field_set-0-type" id="id_field_set-0-type">
            <option *ngFor="let types of accessLevel; index as t" [value]="types.value"
              [selected]="types.type === element.capture">
              {{ types.type }}
            </option>
          </select>
        </td>
      </ng-container>

      <!-- Dropdown -->
      <ng-container matColumnDef="dropdown">
        <th mat-header-cell *matHeaderCellDef>Dropdown</th>
        <td mat-cell *matCellDef="let element">
          <input type="checkbox" [(ngModel)]="element.dropdown" [value]="element.dropdown" />
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" cdkDrag [cdkDragData]="row"></tr>
    </table>
  </div>
  <div class="action-buttons">
    <div class="form-group">
      <a routerLink="/addFields" class="btn btn-default">
        <span class="glyphicon glyphicon-plus"></span> Add Fields
      </a>
      <button type="submit" class="btn btn-primary" (click)="submitTable()" style="margin-left: 10px;">
        <span class="glyphicon glyphicon-floppy-disk"></span> Save
      </button>
    </div>
    <div></div>
  </div>
</div>
<ngx-spinner [fullScreen]="true" type="" size="medium" bdColor="rgba(0, 0, 0, 0)">
  <p class="loading"> <img src="/assets/img/wheel.svg" alt="Girl in a jacket" width="100px" height="100px"> </p>
</ngx-spinner>
<app-footer></app-footer>