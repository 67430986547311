import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  constructor(private http: HttpClient) {}

  getProducts() {
    return this.http.get('/subscribe/products/');
  }
  changeProductPrice(body: any) {
    return this.http.post('/subscribe/products/', body);
  }
  getAllPlans() {
    return this.http.get('/subscribe/plans/');
  }
}
