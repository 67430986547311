<div class="main-heading mt-3">
  <div class="row page-title">
    <div class="col-md-5"></div>
    <h3>
      <a>Global Modification</a>
    </h3>
    <div class="col-md-7">
      <ul class="actions navbar-right">
        <div id="loading" style="display: none"></div>
      </ul>
    </div>
  </div>
</div>
<ng-container *ngIf="!showLayersButton">
  <app-maps-button></app-maps-button>
</ng-container>
<ng-container *ngIf="showLayersButton">
  <app-layers-button></app-layers-button>
</ng-container>
<mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedTab" (selectedIndexChange)="setTabState($event)"
  animationDuration="0ms">
  <ng-container *ngFor="let tab of tabs" (click)="tabSelected(tab)">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="mat-label-text" (click)="tabSelected(tab)">{{ tab.data.label }}</div>
      </ng-template>
      <ng-container *ngComponentOutlet="tab.component"></ng-container>
    </mat-tab>
  </ng-container>
</mat-tab-group>