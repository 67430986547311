<app-header [product]="product"></app-header>
<h1 class="sub-main-head" *ngIf="showForm">Sign Up</h1>
<div class="container" style="margin-bottom: 100px" *ngIf="showForm">
    <form [formGroup]="courtesyForm" method="post" novalidate>
        <input type="hidden" name="csrfmiddlewaretoken"
            value="5VPKeb8SmorbDcAZmBLye0rWPJKylswJWwFrhcZMmms0qpPASLe5iqTHO87STTtB" />
        <div class="div-window">
            <div>
                <div>
                    <div>
                        <div class="h3">Create Account</div>
                    </div>
                </div>
                <div>
                    <input type="text" name="first_name" required="" formControlName="first_name"
                        placeholder="First Name" class="payment-inputs full_lenght_inputs" id="firstname" />
                    <input type="text" name="last_name" required="" placeholder="Last Name"
                        class="payment-inputs full_lenght_inputs" id="lastname" formControlName="last_name" />
                    <input type="text" name="email" required="" placeholder="Email"
                        class="payment-inputs full_lenght_inputs" id="email" readonly formControlName="email" />

                    <input type="password" name="new_password1" data-toggle="password" required #password_field
                        placeholder="Password" class="payment-inputs full_lenght_inputs pyments" id="id_new_password1"
                        style="display: inline-block" formControlName="password_1" /><span
                        title="Click here to show/hide password" class="add-on input-group-addon show-passsword"
                        (click)="
              password_field.type =
                password_field.type === 'password' ? 'text' : 'password'
            "><i class="glyphicon" [ngClass]="
                password_field.type === 'password'
                  ? 'glyphicon-eye-open'
                  : 'glyphicon-eye-close'
              "></i></span>
                    <input type="password" name="new_password2" data-toggle="password" required #password_field2
                        placeholder="Re-enter Password" class="payment-inputs full_lenght_inputs pyments"
                        id="id_new_password2" style="display: inline-block" formControlName="password_2" /><span
                        title="Click here to show/hide password" class="add-on input-group-addon show-passsword"
                        (click)="
              password_field2.type =
                password_field2.type === 'password' ? 'text' : 'password'
            "><i class="glyphicon" [ngClass]="
                password_field2.type === 'password'
                  ? 'glyphicon-eye-open'
                  : 'glyphicon-eye-close'
              "></i></span>
                    <input type="text" name="phone" placeholder="Phone (optional, but needed for providing support)"
                        class="payment-inputs full_lenght_inputs" id="id_phone" [imask]="{ mask: '000-000-0000' }"
                        [unmask]="true" formControlName="phone_number" />
                    <!-- <input type="text" name="company" placeholder="Organization (optional)"
                        class="payment-inputs full_lenght_inputs" id="id_company" [(ngModel)]="user.organization" />
                    <div class="center">
                        <select name="device" class="payment-inputs full_lenght_inputs" id="id_device"
                            [(ngModel)]="user.device">
                            <option *ngFor="let data of industry" [value]="data.value">{{data.name}}</option>
                        </select>
                    </div> -->
                    <input type="text" name="product_type" value="1" hidden="" class="payment-inputs full_lenght_inputs"
                        id="id_product_type" />
                    <input type="text" name="direct_map_login" hidden="" id="id_direct_map_login" value="false" />
                </div>
                <div class="check-box">
                    <span style="font-size: 11px">By creating an account, you agree to ENPLAN
                        <a href="https://mapport.com/terms-service/" target="_blank">Terms of Service</a>
                        and
                        <a href="https://mapport.com/privacy-policy/" target="_blank">Privacy Policy</a>.</span>
                </div>
            </div>
        </div>
        <div class="col-md-12 text-center">
            <button type="submit" (click)="submit()" class="btn gernal-btn"
                style="background-color: #2c84c6; color: white">
                Update
            </button>
        </div>
    </form>
</div>
<app-footer></app-footer>
<ngx-spinner [fullScreen]="true" type="" size="medium" bdColor="rgba(0, 0, 0, 0)">
    <p class="loading"> <img src="/assets/img/wheel.svg" alt="Girl in a jacket" width="100px" height="100px"> </p>
</ngx-spinner>