import { CourtesyMapsComponent } from './../../modules/global-modification/main-global-modification/courtesy-account/courtesy-maps/courtesy-maps.component';
import { CourtesyAddOnsComponent } from './../../modules/global-modification/main-global-modification/courtesy-account/courtesy-add-ons/courtesy-add-ons.component';
import { PagesComponent } from './../../modules/global-modification/main-global-modification/subscribe-pages/pages/pages.component';
import { ProductsComponent } from './../../modules/global-modification/main-global-modification/recurly-plans/products/products.component';
import { RecurlyPlansComponent } from './../../modules/global-modification/main-global-modification/recurly-plans/recurly-plans.component';
import { CreateTableComponent } from './../../modules/global-modification/main-global-modification/create-table/create-table.component';
import { ModelsComponent } from './../../modules/global-modification/main-global-modification/models/models.component';
import { MyMapsComponent } from './../../modules/global-modification/main-global-modification/my-maps/my-maps.component';
import { AllUsersComponent } from './../../modules/global-modification/main-global-modification/all-users/all-users.component';
import { MyAccountComponent } from './../../modules/global-modification/main-global-modification/my-account/my-account.component';
import { LayersComponent } from './../../modules/global-modification/main-global-modification/layers/layers.component';
import { TableTabsComponent } from './../../modules/global-modification/table-tabs/table-tabs.component';
import { GloModMapsComponent } from '../../modules/global-modification/main-global-modification/glo-mod-maps/glo-mod-maps.component';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GloModLayersComponent } from 'src/app/modules/global-modification/main-global-modification/glo-mod-layers/glo-mod-layers.component';
import { TabItem, tabState } from 'src/app/modules/shared/models/tab/tab.model';
import { CustomSeatsComponent } from 'src/app/modules/global-modification/main-global-modification/custom-seats/custom-seats.component';
import { environment } from 'src/environments/environment';
import { SecurityControlsComponent } from 'src/app/modules/global-modification/main-global-modification/security-controls/security-controls.component';
import { AllPlansComponent } from 'src/app/modules/global-modification/main-global-modification/recurly-plans/all-plans/all-plans.component';
import { CourtesyAccountComponent } from 'src/app/modules/global-modification/main-global-modification/courtesy-account/courtesy-account.component';

@Injectable({
  providedIn: 'root',
})
export class TabService {
  activeTab$: Subject<TabItem>;
  private state?: tabState;
  hideTab: any = false;
  token: any;
  hideComponent: Subject<any> = new Subject<any>();

  constructor() {
    this.activeTab$ = new Subject();
    this.token = localStorage.getItem('auth_token');
  }

  getTabs() {
    return [
      new TabItem(
        MyAccountComponent,
        {
          id: 't1',
          label: 'My Account',
          index: 0,
          link: `${environment.operatorDashboardUrl}/parcelviewer/account/${this.token}`,
        },
        false
      ),
      new TabItem(
        AllUsersComponent,
        {
          id: 't2',
          label: 'All Users',
          index: 1,
          link: `${environment.opLink}/users/${this.token}`,
        },
        false
      ),
      new TabItem(
        MyMapsComponent,
        {
          id: 't3',
          label: 'My Maps',
          index: 2,
          link: `${environment.opLink}/my_maps/${this.token}`,
        },
        false
      ),
      new TabItem(
        ModelsComponent,
        {
          id: 't4',
          label: 'Models',
          index: 3,
          link: `${environment.opLink}/modals/${this.token}`,
        },
        false
      ),
      new TabItem(
        CustomSeatsComponent,
        {
          id: 't5',
          label: 'Custom Seats',
          index: 4,
          link: `${environment.opLink}/seats/${this.token}`,
        },
        false
      ),
      new TabItem(
        CreateTableComponent,
        {
          id: 't6',
          label: 'Create Tables',
          index: 5,
          link: `${environment.opLink}/tables/${this.token}`,
        },
        false
      ),
      new TabItem(
        TableTabsComponent,
        { id: 't7', label: 'Global Modification', index: 6, link: '/global' },
        true
      ),
      new TabItem(
        SecurityControlsComponent,
        { id: 't8', label: 'Security', index: 7, link: '/securityControl' },
        true
      ),
      new TabItem(
        RecurlyPlansComponent,
        { id: 't9', label: 'Plans', index: 8, link: '/plans' },
        true
      ),
      new TabItem(
        RecurlyPlansComponent,
        {
          id: 't10',
          label: 'Subscribe Pages',
          index: 9,
          link: '/subscribePages',
        },
        true
      ),
      new TabItem(
        CourtesyAccountComponent,
        {
          id: 't11',
          label: 'Courtesy Account',
          index: 10,
          link: '/courtesyAccount',
        },
        true
      ),
    ];
  }
  getTabsLayer() {
    return [
      new TabItem(
        MyAccountComponent,
        {
          id: 't1',
          label: 'My Account',
          index: 0,
          link: `${environment.operatorDashboardUrl}/parcelviewer/account/${this.token}`,
        },
        false
      ),
      new TabItem(
        AllUsersComponent,
        {
          id: 't2',
          label: 'All Users',
          index: 1,
          link: `${environment.opLink}/users/${this.token}`,
        },
        false
      ),
      new TabItem(
        MyMapsComponent,
        {
          id: 't3',
          label: 'My Maps',
          index: 2,
          link: `${environment.opLink}/my_maps/${this.token}`,
        },
        false
      ),
      new TabItem(
        ModelsComponent,
        {
          id: 't4',
          label: 'Models',
          index: 3,
          link: `${environment.opLink}/modals/${this.token}`,
        },
        false
      ),
      new TabItem(
        CustomSeatsComponent,
        {
          id: 't5',
          label: 'Custom Seats',
          index: 4,
          link: `${environment.opLink}/seats/${this.token}`,
        },
        false
      ),
      new TabItem(
        CreateTableComponent,
        {
          id: 't6',
          label: 'Create Tables',
          index: 5,
          link: `${environment.opLink}/tables/${this.token}`,
        },
        false
      ),
      new TabItem(
        LayersComponent,
        { id: 't7', label: 'Global Modification', index: 6, link: '/global' },
        true
      ),
      new TabItem(
        SecurityControlsComponent,
        { id: 't8', label: 'Security', index: 7, link: '/securityControl' },
        true
      ),
      new TabItem(
        RecurlyPlansComponent,
        { id: 't9', label: 'Plans', index: 8, link: '/plans' },
        true
      ),
      new TabItem(
        RecurlyPlansComponent,
        {
          id: 't10',
          label: 'Subscribe Pages',
          index: 9,
          link: '/subscribePages',
        },
        true
      ),
      new TabItem(
        CourtesyAccountComponent,
        {
          id: 't11',
          label: 'Courtesy Account',
          index: 10,
          link: '/courtesyAccount',
        },
        true
      ),
    ];
  }

  getGlobalTabs() {
    return [
      new TabItem(
        GloModMapsComponent,
        { id: 't1', label: 'Maps', index: 0, link: '' },
        false
      ),
      new TabItem(
        GloModLayersComponent,
        { id: 't2', label: 'Layers', index: 1, link: '' },
        false
      ),
    ];
  }

  getRecurlyTabs() {
    return [
      new TabItem(
        ProductsComponent,
        { id: 't1', label: 'Products', index: 0, link: '' },
        false
      ),
      new TabItem(
        AllPlansComponent,
        { id: 't2', label: 'All Plans', index: 1, link: '' },
        false
      ),
    ];
  }

  getSubscribePagesTabs() {
    return [
      new TabItem(
        PagesComponent,
        { id: 't1', label: 'pages', index: 0, link: '' },
        false
      ),
    ];
  }



  getCourtesyTabs() {
    return [
      new TabItem(
        CourtesyMapsComponent,
        { id: 't1', label: 'Maps', index: 0, link: '' },
        false
      ),
      new TabItem(
        CourtesyAddOnsComponent,
        { id: 't2', label: 'Add-ons', index: 1, link: '' },
        false
      ),
    ];
  }

  setState(state: tabState) {
    this.state = state;
  }

  getState(): tabState {
    return this.state ? this.state : { tabState: 0 };
  }

  public setComponent(value: any) {
    this.hideComponent.next(value);
  }
}
