import { LayerService } from 'src/app/services/layer/layer.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { tabledata } from 'src/app/modules/shared/models/table/table.model';
import { SnackbarService } from 'src/app/services/snackbarService/snackbar.service';
import { TabService } from 'src/app/services/tab/tab.service';
import { PlansService } from 'src/app/services/plan/plans.service';

@Component({
  selector: 'app-all-plans',
  templateUrl: './all-plans.component.html',
  styleUrls: ['./all-plans.component.css'],
})
export class AllPlansComponent implements OnInit {
  allLayers: tabledata[] = [];
  allPlans: any = [];
  currentPlans: any = [];
  totalItems: any;
  currentPages: any = 1;
  pageSize: any = 10;
  totalPages: any;
  startPage!: number;
  endPage!: number;
  startPages!: number;
  endPages!: number;
  startIndex: any;
  endIndex: any;
  djangoAdminUrl: any;
  pages: any;
  token: any;
  disablePerviousBuuton = false;
  disableNextBuuton = false;
  checkboxValue = false;
  searchResultPlan: any = [];
  searchText = '';
  options: any = {
    page: 1,
    search: '',
    size: 5,
  };
  count = 0;
  startPlansIndex = 0;
  finishPlansIndex = 0;
  constructor(
    public LayerService: LayerService,
    public tabService: TabService,
    private service: PlansService,
    public _snackService: SnackbarService,
    public router: Router
  ) {
    (this.tabService.hideTab = false),
      (this.token = localStorage.getItem('auth_token'));
  }

  ngOnInit(): void {
    this.currentPages = 1;
    this.service.getAllPlans().subscribe((res) => {
      this.allPlans = res;
      this.totalItems = this.allPlans.length;
      this.onTableDataChange(1);
      this.paginate(this.totalItems, this.currentPages, this.pageSize);
    });
  }
  allCurrentPages() {
    const startPosition = (this.currentPages - 1) * this.pageSize;
    this.startPlansIndex = startPosition;
    if (startPosition + parseInt(this.pageSize) <= this.totalItems) {
      this.finishPlansIndex = startPosition + parseInt(this.pageSize) - 1;
    } else {
      this.finishPlansIndex = this.totalItems;
    }
    if (this.searchText === '') {
      for (let i = 0; i < this.pageSize; i++) {
        if (startPosition + i <= this.totalItems) {
          this.currentPlans[i] = this.allPlans[startPosition + i];
        } else {
          this.currentPlans.splice(1, i);
        }
      }
    }
    if (this.searchText !== '') {
      const plans = [];
      for (let i = 0; i < this.pageSize; i++) {
        if (startPosition + i <= this.totalItems) {
          plans.push(this.searchResultPlan[startPosition + i]);
        } else {
          plans.splice(1, i);
        }
      }
      this.currentPlans = [...plans];
    }
  }
  search($event: any): void {
    this.searchText = $event.target.value;
    this.searchResultPlan = [];
    if (this.searchText !== '') {
      for (let i = 0; i < this.allPlans.length; i++) {
        const asArray = Object.entries(this.allPlans[i]);
        const filtered = asArray.filter((value) => {
          return value
            .toString()
            .toLowerCase()
            .match(this.searchText.toLowerCase());
        });
        if (filtered.length !== 0) {
          this.searchResultPlan.push(this.allPlans[i]);
        }
      }
      this.totalItems = this.searchResultPlan.length;
      this.onTableDataChange(1);
    } else {
      this.allCurrentPages();
    }
  }
  size(size: any): void {
    this.pageSize = size.target.value;
    this.onTableDataChange(1);
    this.paginate(this.totalItems, this.currentPages, this.pageSize);
  }
  onTableDataChange(event: any) {
    this.currentPages = event;
    this.allCurrentPages();
    this.paginate(this.totalItems, this.currentPages, this.pageSize);
  }

  onbtnClick(pageData: any) {
    this.onTableDataChange(parseInt(pageData));
    this.paginate(this.totalItems, this.currentPages, this.pageSize);
    // if (this.currentPages >= pageData) {
    //   this.currentPages--;
    //   this.onTableDataChange(parseInt(pageData));
    //   this.paginate(this.totalItems, this.currentPages, this.pageSize);
    //   // this.allCurrentPages();
    // } else {
    //   const numPage = this.pages.length;
    //   if (this.currentPages < numPage) {
    //     this.currentPages++;
    //     this.onTableDataChange(parseInt(pageData));
    //     this.paginate(this.totalItems, this.currentPages, this.pageSize);
    //   }
    // }
  }
  prevPage() {
    if (this.currentPages > 1) {
      this.currentPages--;
      this.paginate(this.totalItems, this.currentPages, this.pageSize);
      this.allCurrentPages();
    }
  }
  nextPage() {
    const numPage = this.totalPages;
    if (this.currentPages < numPage) {
      this.currentPages++;
      this.paginate(this.totalItems, this.currentPages, this.pageSize);
      this.allCurrentPages();
    }
  }

  paginate(totalItems = 10, currentPage: any, pageSize: any, maxPages = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      this.startPages = 1;
      this.endPages = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;

      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        this.startPages = 1;
        this.endPages = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        this.startPages = totalPages - maxPages + 1;
        this.endPages = totalPages;
      } else {
        // current page somewhere in the middle
        this.startPages = currentPage - maxPagesBeforeCurrentPage;
        this.endPages = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(
      Array(this.endPages + 1 - this.startPages).keys()
    ).map((i) => this.startPage + i);

    // return object with all pager properties required by the view
    (this.totalItems = totalItems),
      (this.currentPages = currentPage),
      (this.pageSize = pageSize),
      (this.totalPages = totalPages),
      (this.startPage = this.startPages),
      (this.endPage = this.endPages),
      (this.startIndex = startIndex),
      (this.endIndex = endIndex),
      (this.pages = pages);
  }
}
