<app-global-mod-header></app-global-mod-header>
<app-layers-tab></app-layers-tab>
<div class="container">
  <div class="row page-title" style="margin-right: 0px">
    <div class="col-md-7 mt-3 p-0">
      <h3>
        <a routerLink="/global">Global Modification</a> »
        <a routerLink="/global">Layers</a> »
        <a>{{ layerName }}</a>
      </h3>
    </div>
    <div class="col-md-5">
      <ul class="actions navbar-right">
        <li role="presentation">
          <a [routerLink]="disableButton ? null : apMapFeed" [routerLinkActive]="disableButton ? 'is_disabled' : ''">AP
            Map Feed</a>
        </li>
        <li role="presentation">
          <a [routerLink]="disableButton ? null : '/pdfTable'"
            [routerLinkActive]="disableButton ? 'is_disabled' : ''">PDF table</a>
        </li>
        <li role="presentation">
          <a [routerLink]="disableButton ? null : '/labels'"
            [routerLinkActive]="disableButton ? 'is_disabled' : ''">Labels</a>
        </li>
        <li role="presentation">
          <a [routerLink]="disableButton ? null : '/showFields'"
            [routerLinkActive]="disableButton ? 'is_disabled' : ''">Show Fields</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-12 mb-5" style="padding-left: 0px; padding-right: 0px">
    <form action="." method="POST" #form="ngForm" (ngSubmit)="save(form)">
      <!-- use in V2 -->
      <!-- <div class="panel mb-5">
        <div class="panel-header">
          <h3 class="panel-title">GloMod</h3>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <label class="control-label" for="id_name">Is Parent layer?</label>
            <div id="style-control">
              <label class="radio-inline"
                ><input
                  type="radio"
                  checked="checked"
                  value="yes"
                  name="rdo-style"
                  id="rdo-new-style"
                />Yes</label
              >
              <label class="radio-inline"
                ><input
                  type="radio"
                  value="Select Style"
                  name="rdo-style"
                  id="rdo-select-style"
                />No</label
              >
            </div>
          </div>
          <div class="form-group">
            <label class="inheritance" for="Inheritance" style="color: #4444;">Inheritance</label>
          </div>

        </div>
      </div> -->
      <div class="panel mt-5">
        <div class="panel-header">
          <h3 class="panel-title">General</h3>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <label class="control-label" for="id_name">Plural Name</label><input type="text" name="name"
              title="How the UI should refer to this layer as a whole." id="id_name" maxlength="255"
              placeholder="Plural Name" class="form-control" required [(ngModel)]="product.name" #name="ngModel" />
            <span class="text-danger" *ngIf="(name.touched || form.submitted) && name.errors?.required">
              Name is required
            </span>
            <div class="help-block">
              How the UI should refer to this layer as a whole.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_typename">Singular Name</label><input type="text" name="typename"
              title="How the UI should refer to a single feature in this layer." required="" id="id_typename"
              maxlength="255" placeholder="Singular Name" class="form-control" required [(ngModel)]="product.typename"
              #typename="ngModel" />
            <span class="text-danger" *ngIf="
                (typename.touched || form.submitted) &&
                typename.errors?.required
              ">
              Singular Name is required
            </span>
            <div class="help-block">
              How the UI should refer to a single feature in this layer.
            </div>
          </div>
          <div class="form-group">
            <label class="control-label" for="id_comment">Comment</label><input type="text" name="comment"
              title="Show with plural name in inheritance" id="id_typename" maxlength="255" placeholder="Add Comment"
              class="form-control" required [(ngModel)]="product.comment" #comment="ngModel" />
            <div class="help-block">
              Show with plural name in inheritance
            </div>
          </div>


          <div class="form-group">
            <label class="control-label" for="id_select_order">Geometry Type</label><select name="select_order"
              id="id_select_order" class="form-control" required [(ngModel)]="product.select_order"
              #select_order="ngModel" title="The geometry type of this layer (used when selecting features).">
              <option value="7">Marker</option>

              <option value="3" selected="">Point</option>

              <option value="2">Line</option>

              <option value="1">Polygon</option>

              <option value="6">MultiPoint</option>

              <option value="5">MultiLine</option>

              <option value="4">MultiPolygon</option>
            </select>
            <div class="help-block">
              The geometry type of this layer (used when selecting features).
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_zoom">Zoom</label><input type="number" name="zoom" value="6" required
              min="0" title="Enable Layer At (x) Zoom Level." id="id_zoom" placeholder="Zoom" class="form-control"
              [(ngModel)]="product.zoom" #zoom="ngModel" />
            <div class="help-block">Enable Layer At (x) Zoom Level.</div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_visibility">Visible To</label><select name="visibility"
              id="id_visibility" class="form-control" required title="The minimum privilege required to see this layer."
              [(ngModel)]="product.visibility" #visibility="ngModel">
              <option value="-999">Deleted</option>

              <option value="0">Public</option>

              <option value="100">Client</option>

              <option value="200">Reader</option>

              <option value="300">Editor</option>

              <option value="400">Admin</option>

              <option value="500">Demigod</option>

              <option value="999">Nobody</option>
            </select>
            <div class="help-block">
              The minimum privilege required to see this layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_selectability">Selectable By</label><select name="selectability"
              id="id_selectability" class="form-control" required
              title="The minimum privilege required to select this layer." [(ngModel)]="product.selectability"
              #selectability="ngModel">
              <option value="-999">Deleted</option>

              <option value="0">Public</option>

              <option value="100">Client</option>

              <option value="200" selected="">Reader</option>

              <option value="300">Editor</option>

              <option value="400">Admin</option>

              <option value="500">Demigod</option>

              <option value="999">Nobody</option>
            </select>
            <div class="help-block">
              The minimum privilege required to select this layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_editability">Editable By</label><select name="editability"
              id="id_editability" class="form-control" required
              title="The minimum privilege required to edit this layer." [(ngModel)]="product.editability"
              #editability="ngModel">
              <option value="-999">Deleted</option>

              <option value="0">Public</option>

              <option value="100">Client</option>

              <option value="200">Reader</option>

              <option value="300">Editor</option>

              <option value="400">Admin</option>

              <option value="500">Demigod</option>

              <option value="999" selected="">Nobody</option>
            </select>
            <div class="help-block">
              The minimum privilege required to edit this layer.
            </div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label for="id_limit_selectability" title="To select the limit selectability."><input type="checkbox"
                  name="limit_selectability" required id="id_limit_selectability"
                  [(ngModel)]="product.limit_selectability" #limit_selectability="ngModel" />
                Limit Selectability</label>
            </div>
            <div class="help-block">To select limit selectability of the layer.</div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_tool_tip">Selection limit definition</label>
            <textarea name="selection_limit" rows="6" title="Layer selection limit defination." id="id_selection_limit"
              placeholder='{"fieldname" : "column_name", "zoom_10": "100 ac"}' class="form-control-text"
              [(ngModel)]="product.selection_limit"></textarea>


            <div class="help-block">Layer Selection limit definition</div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_tool_tip">APMap Query Fields</label>
            <textarea name="ap_map_query_fields" rows="6" title="AP Map Query Fields." id="id_ap_map_query_fields"
              placeholder='{ "apn_sql_name" : "apn_full", "property_sql_name" : "propertyid" }' class="form-control-text"
              [(ngModel)]="product.ap_map_query_fields"></textarea>


            <div class="help-block">Fields to query on Parcel data to get propertyId.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_tool_tip">Tool Tip</label>
            <textarea name="tool_tip" rows="4" title="Layer tool tip." id="id_tool_tip" placeholder="Tool Tip"
              class="form-control-text" [(ngModel)]="product.tool_tip"></textarea>


            <div class="help-block">Layer tool tip.</div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label for="id_limit_export" title="To put limit on the export data i.e PDF,csv"><input type="checkbox"
                  name="limit_export" required id="id_limit_export" [(ngModel)]="product.limit_export"
                  #limit_export="ngModel" />
                Limit export</label>
            </div>
            <div class="help-block">
              To put limit on the export data i.e PDF,csv
            </div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label for="id_automatic_refresh" title="To automatic refresh of the layer."><input type="checkbox"
                  name="automatic_refresh" required id="id_automatic_refresh" [(ngModel)]="product.automatic_refresh"
                  #automatic_refresh="ngModel" />
                Automatic refresh</label>
            </div>
            <div class="help-block">To automatic refresh of the layer.</div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_automatic_refresh_time">Automatic refresh time</label><input
              type="number" name="automatic_refresh_time" min="0" title="Time of automatic refresh of the layer."
              id="id_automatic_refresh_time" placeholder="Automatic refresh time" class="form-control"
              [(ngModel)]="product.automatic_refresh_time" />

            <div class="help-block">
              Time of automatic refresh of the layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_data_channel">Data Channel</label><select name="data_channel"
              id="id_data_channel" class="form-control" title="To get live data source e.g. Lightning"
              [(ngModel)]="product.data_channel">
              <option value="" selected="">----------------</option>

              <option value="lightning-get_data">Live Lightning Channel</option>

              <option value="demo-get_demo">Demo Lightning Channel</option>
            </select>

            <div class="help-block">To get live data source e.g. Lightning</div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label for="id_capture" title="To add or edit map layer features and data"><input type="checkbox"
                  name="capture" [(ngModel)]="product.capture" id="id_capture" required #capture="ngModel" />
                Active Capture Tool</label>
            </div>
            <div class="help-block">
              To add or edit map layer features and data
            </div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label for="id_is_parcel_layer" title="To display labels on parcels."><input type="checkbox"
                  name="is_parcel_layer" required id="id_is_parcel_layer" [(ngModel)]="product.is_parcel_layer"
                  #is_parcel_layer="ngModel" />
                Is parcel layer</label>
            </div>
            <div class="help-block">To display labels on parcels.</div>
          </div>
        </div>
      </div>
      <div class="action-buttons" *ngIf="editLayer">
        <div class="form-group">
          <a (click)="backToMain()" class="btn btn-default">
            <span class="glyphicon glyphicon-remove"></span> Cancel
          </a>

          <button type="submit" class="btn btn-primary" style="margin-left: 10px;">
            <span class="glyphicon glyphicon-floppy-disk"></span> Save
          </button>
        </div>
      </div>

      <div class="panel" [ngClass]="editLayer ? 'mt-4' : 'mt-5'">
        <div class="panel-header">
          <h3 class="panel-title">Legend</h3>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <label class="control-label" for="id_heading">Layer Group</label><input type="text" name="heading"
              title="The Layer Group that should include this layer in the layers panel." id="id_heading"
              maxlength="255" placeholder="Layer Group" class="form-control" [(ngModel)]="product.heading" />

            <div class="help-block">
              The Layer Group that should include this layer in the layers
              panel.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_heading_tooltip">Layer Group Tooltip</label><input type="text"
              name="heading_tooltip" title="Tooltip of layer group." id="id_heading_tooltip" maxlength="255"
              placeholder="Layer Group Tooltip" class="form-control" [(ngModel)]="product.heading_tooltip" />

            <div class="help-block">Tooltip of layer group.</div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_class_group">Class group</label><select name="class_group"
              id="id_class_group" class="form-control" [(ngModel)]="product.class_group"
              title="The Class Group that should include this layer in the layers panel.">
              <option value="" selected="">---------</option>

              <option value="6:1">6:1</option>

              <option value="Rivers and Streams">Rivers and Streams</option>

              <option value="2:1">2:1</option>

              <option value="10:1">10:1</option>

              <option value="Vacant">Vacant</option>

              <option value="Canals and Ditches">Canals and Ditches</option>

              <option value="Fire Hazard Severity Zones">
                Fire Hazard Severity Zones
              </option>

              <option value="Utilization">Utilization</option>

              <option value="Coverage">Coverage</option>
            </select>

            <div class="help-block">
              The Class Group that should include this layer in the layers
              panel.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_map_order">Display Order</label><input type="number" name="map_order"
              value="1" required min="0"
              title="Where this layer should draw with regards to other layers; higher numbers are on top."
              id="id_map_order" placeholder="Display Order" class="form-control" [(ngModel)]="product.map_order"
              #map_order="ngModel" />
            <div class="help-block">
              Where this layer should draw with regards to other layers; higher
              numbers are on top.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_layer_type">Layer Type</label><select name="layer_type"
              id="id_layer_type" class="form-control" required [(ngModel)]="product.layer_type" #layer_type="ngModel"
              title='Base layers will always appear under the "Base" heading, and will receive radio buttons instead of check boxes.'>
              <option value="overlay" selected="">Regular Layer</option>

              <option value="basemap">Base Layer</option>
            </select>
            <div class="help-block">
              Base layers will always appear under the "Base" heading, and will
              receive radio buttons instead of check boxes.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_unique_group">Unique Group</label><input type="text"
              name="unique_group"
              title="If provided, makes this layer exclusive with respect to other members of the same group."
              id="id_unique_group" maxlength="255" placeholder="Unique Group" class="form-control"
              [(ngModel)]="product.unique_group" />

            <div class="help-block">
              If provided, makes this layer exclusive with respect to other
              members of the same group.
            </div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label for="id_visible_by_default" title="Whether or not this layer is visible when the map loads."><input
                  type="checkbox" name="visible_by_default" required id="id_visible_by_default"
                  [(ngModel)]="product.visible_by_default" #visible_by_default="ngModel" />
                Visible by Default</label>
            </div>
            <div class="help-block">
              Whether or not this layer is visible when the map loads.
            </div>
          </div>

          <div class="form-group">
            <div class="checkbox">
              <label for="id_show_in_legend" title="Whether or not this layer appears in the layers panel."><input
                  type="checkbox" name="show_in_legend" checked="" required id="id_show_in_legend"
                  [(ngModel)]="product.show_in_legend" #show_in_legend="ngModel" />
                Show in Legend</label>
            </div>
            <div class="help-block">
              Whether or not this layer appears in the layers panel.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_icon_url">Icon URL</label><input type="text" name="icon_url"
              title="If supplied, the URL to use instead of GeoServer's default icon." id="id_icon_url" maxlength="255"
              placeholder="Icon URL" class="form-control" [(ngModel)]="product.icon_url" />

            <div class="help-block">
              If supplied, the URL to use instead of GeoServer's default icon.
            </div>
          </div>
        </div>
      </div>
      <div class="action-buttons" *ngIf="editLayer">
        <div class="form-group">
          <a (click)="backToMain()" class="btn btn-default">
            <span class="glyphicon glyphicon-remove"></span> Cancel
          </a>

          <button type="submit" class="btn btn-primary" style="margin-left: 10px;">
            <span class="glyphicon glyphicon-floppy-disk"></span> Save
          </button>
        </div>
      </div>
      <div class="panel" [ngClass]="editLayer ? 'mt-4' : 'mt-5'">
        <div class="panel-header">
          <h3 class="panel-title">Map Server</h3>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <label class="control-label" for="id_map_server">Server URL</label><input type="text" name="map_server"
              value="https://geoserver.mapport.com/geoserver/wms"
              title="The map server URL; http://[1-5].mapport.net/tiles/wms.php for GeoServer." required
              id="id_map_server" maxlength="255" placeholder="Server URL" class="form-control" #map_server="ngModel"
              [(ngModel)]="product.map_server" />
            <span class="text-danger" *ngIf="
                (map_server.touched || form.submitted) &&
                map_server.errors?.required
              ">
              Server URL is required
            </span>
            <div class="help-block">
              The map server URL; http://[1-5].mapport.net/tiles/wms.php for
              GeoServer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_map_params">Map Parameters</label><input type="text" name="map_params"
              title="add parameters to sql view; parameter:value for GeoServer." id="id_map_params" maxlength="255"
              placeholder="Map Parameters" class="form-control" [(ngModel)]="product.map_params" />

            <div class="help-block">
              add parameters to sql view; parameter:value for GeoServer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_map_format">Format</label><select name="map_format" id="id_map_format"
              class="form-control" required title="The tiling scheme used by this layer."
              [(ngModel)]="product.map_format" #map_format="ngModel">
              <option value="WMS">Web Map Service (WMS)</option>

              <option value="XYZ">XYZ Tiles (i.e. MapBox)</option>

              <option value="WFS">Web Feature Service (WFS)</option>
            </select>
            <div class="help-block">The tiling scheme used by this layer.</div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_map_srid">SRID</label><select name="map_srid" id="id_map_srid" required
              class="form-control" [(ngModel)]="product.map_srid" #map_srid="ngModel"
              title="The spatial reference system to ask for when requesting tiles; 3857 for GeoServer.">
              <option value="EPSG:3857">EPSG:3857</option>

              <option value="EPSG_2955">EPSG:2955</option>

              <option value="EPSG_4326">EPSG:4326</option>

              <option value="EPSG_2163">EPSG:2163</option>
            </select>
            <div class="help-block">
              The spatial reference system to ask for when requesting tiles;
              3857 for GeoServer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_cors_keyword">CORS</label><select name="cors_keyword"
              id="id_cors_keyword" class="form-control" [(ngModel)]="product.cors_keyword"
              title='Cross-origin resource sharing; "anonymous" for GeoServer.'>
              <option value="">Don't use CORS.</option>

              <option value="anonymous">Make anonymous CORS requests.</option>

              <option value="use-credentials">
                Make authenticated CORS requests.
              </option>
            </select>

            <div class="help-block">
              Cross-origin resource sharing; "anonymous" for GeoServer.
            </div>
          </div>
        </div>
      </div>
      <div class="action-buttons" *ngIf="editLayer">
        <div class="form-group">
          <a (click)="backToMain()" class="btn btn-default">
            <span class="glyphicon glyphicon-remove"></span> Cancel
          </a>

          <button type="submit" class="btn btn-primary" style="margin-left: 10px;">
            <span class="glyphicon glyphicon-floppy-disk"></span> Save
          </button>
        </div>
      </div>
      <div class="panel" [ngClass]="editLayer ? 'mt-4' : 'mt-5'">
        <div class="panel-header">
          <h3 class="panel-title">Database</h3>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <label class="control-label" for="id_sql_schema">Schema</label><select
              class="form-control db-meta db-schema" name="sql_schema" [(ngModel)]="product.sql_schema"
              (ngModelChange)="onSchemaChange()">
              <option [ngValue]="item.key" *ngFor="let item of layerSchemaInfo | keyvalue">
                {{ item.key }}
              </option>
            </select>

            <div class="help-block">
              The database schema that contains this layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_sql_table">Table</label><select class="form-control db-meta db-table"
              name="sql_table" [(ngModel)]="product.sql_table" (ngModelChange)="onTableChange()">
              <ng-container *ngIf="product.sql_schema">
                <option [ngValue]=""></option>
                <ng-container *ngFor="
                    let item of layerSchemaInfo[product.sql_schema] | keyvalue
                  ">
                  <option [ngValue]="item.key">
                    {{ item.key }}
                  </option>
                </ng-container>
              </ng-container>
            </select>

            <div class="help-block">
              The database table that contains this layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label">ID Column</label><select class="form-control db-meta db-column"
              name="sql_id_field" [(ngModel)]="product.sql_id_field">
              <ng-container *ngIf="product.sql_table && product.sql_schema">
                <option [ngValue]=""></option>
                <ng-container *ngFor="
                    let item of layerSchemaInfo[product.sql_schema][
                      product.sql_table
                    ]
                  ">
                  <option [ngValue]="item">{{ item }}</option>
                </ng-container>
              </ng-container>
            </select>

            <div class="help-block">
              A column that can be used to uniquely identify features in this
              layer.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_sql_name_field">Name Column</label><select
              class="form-control db-meta db-column" name="sql_name_field" [(ngModel)]="product.sql_name_field">
              <ng-container *ngIf="product.sql_table && product.sql_schema">
                <option [ngValue]=""></option>
                <ng-container *ngFor="
                    let item of layerSchemaInfo[product.sql_schema][
                      product.sql_table
                    ]
                  ">
                  <option [ngValue]="item">{{ item}}</option>
                </ng-container>
              </ng-container>
            </select>

            <div class="help-block">
              A column that can be used as a human-friendly name for these
              features.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_sql_geometry_field">Geometry Column</label><select
              class="form-control db-meta db-column" name="sql_geometry_field" [(ngModel)]="product.sql_geometry_field">
              <ng-container *ngIf="product.sql_table && product.sql_schema">
                <option [ngValue]=""></option>
                <ng-container *ngFor="
                    let item of layerSchemaInfo[product.sql_schema][
                      product.sql_table
                    ]
                  ">
                  <option [ngValue]="item">{{ item }}</option>
                </ng-container>
              </ng-container>
            </select>

            <div class="help-block">
              The column that contains the positional information for these
              features.
            </div>
          </div>

          <div class="form-group">
            <label class="control-label" for="id_sql_touch_field">Timestamp Column</label><select
              class="form-control db-meta db-column" name="sql_touch_field" [(ngModel)]="product.sql_touch_field">
              <ng-container *ngIf="product.sql_table && product.sql_schema">
                <option [ngValue]=""></option>
                <ng-container *ngFor="
                    let item of layerSchemaInfo[product.sql_schema][
                      product.sql_table
                    ]
                  ">
                  <option [ngValue]="item">{{ item}}</option>
                </ng-container>
              </ng-container>
            </select>

            <div class="help-block">
              If provided, a timestamp column to be updated whenever a feature
              is modified.
            </div>
          </div>
        </div>
      </div>


      <div class="action-buttons" *ngIf="editLayer">
        <div class="form-group">
          <a (click)="backToMain()" class="btn btn-default">
            <span class="glyphicon glyphicon-remove"></span> Cancel
          </a>

          <button type="submit" class="btn btn-primary" style="margin-left: 10px;">
            <span class="glyphicon glyphicon-floppy-disk"></span> Save
          </button>
        </div>
      </div>

      <!-- BaseMap Style -->
      <app-style-basemap [editLayer]="editLayer" (spinner)="getSpinnerState($event)"
        (passedlayerStyle)="getStyleLayers($event)"></app-style-basemap>
      <!-- BaseMap Style ends -->
    </form>
  </div>
</div>
<ngx-spinner [fullScreen]="true" type="" size="medium" bdColor="rgba(0, 0, 0, 0)">
  <p class="loading"> <img src="/assets/img/wheel.svg" alt="Girl in a jacket" width="100px" height="100px"> </p>
</ngx-spinner>
