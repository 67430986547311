import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CaptureToolService {
  clickedIndex: any;
  drawFeature: any
  constructor(private http: HttpClient) { }

  getLayerGeomTypes(mapId: any) {
    return this.http.get('/capture-tool/get-layer-geom-types/', {
      params: {
        map_id: parseInt(mapId),
      },
    });
  }

  createNewCaptureFeature( body: any) {
    return this.http.post('/capture-tool/create-new-feature/', body);
  }

  updateCaptureFeature( body: any) {
    return this.http.put('/capture-tool/update-feature/', body);
  }

  deleteCaptureFeature(body: any) {
    return this.http.put('/capture-tool/delete-feature/', body);
  }
}
