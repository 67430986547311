import { Component, OnInit } from '@angular/core';
import { TabService } from 'src/app/services/tab/tab.service';

@Component({
  selector: 'app-main-global-modification',
  templateUrl: './main-global-modification.component.html',
  styleUrls: ['./main-global-modification.component.css'],
})
export class MainGlobalModificationComponent implements OnInit {
  pageIndex = [];
  constructor(public tabService: TabService) {
    this.tabService.hideTab = false;
  }

  ngOnInit(): void {
    //
  }
}
