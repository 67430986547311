<app-global-mod-header></app-global-mod-header>
<div class="container tabs mt-5" style="margin-top: 50px">
  <mat-tab-group
    (selectedTabChange)="tabChanged($event)"
    mat-align-tabs="end"
    [selectedIndex]="9"
    animationDuration="0ms"
  >
    <ng-container *ngFor="let tab of Maintab">
      <mat-tab>
        <ng-template
          mat-tab-label
          [dataProvider]="tab"
          *ngIf="
            tab.data.label !== 'Security' &&
            tab.data.label !== 'Global Modification'
          "
        >
          <a
            class="mat-label-text"
            href="{{ tab.data.link }}"
            (click)="tabSelected(tab)"
          >
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template
          mat-tab-label
          [dataProvider]="tab"
          *ngIf="tab.data.label === 'Global Modification'"
        >
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template
          mat-tab-label
          [dataProvider]="tab"
          *ngIf="tab.data.label === 'Security'"
        >
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>

<div class="container">
  <div class="wrapper">
    <div class="main-heading mt-5">
      <div class="row page-title">
        <div class="col-md-5"></div>
        <h4>
          <a class="pageTitle" routerLink="/subscribePages">Subscribe Pages</a> »
          <a class="pageTitle">New  Page</a>
        </h4>
        <div class="col-md-7">
          <ul class="actions navbar-right">
            <div id="loading" style="display: none"></div>
          </ul>
        </div>
      </div>
    </div>
    <form [formGroup]="createPage" (ngSubmit)="onSubmit()">
      <div class="main">
        <div class="pageName">
          <label for="name">Name:</label>
          <div class="error" *ngIf="isSubmitted && createPage.get('name')?.invalid">*Name is required</div>
          <input placeholder="Enter name of operator Dashboard"
                type="text"
                name="name"
                class="form-control"
                [class.is-invalid]="isSubmitted && createPage.get('name')?.invalid"
                formControlName="name"
                required/>
        </div>
        <div class="pageSlug">
          <label class="header">Site</label>
          <div class="slug">
            <label for="slug">URL Slug:</label>
            <div class="error" *ngIf="isSubmitted && createPage.get('slug')?.invalid">*Url slug is required</div>
            <input
                type="text"
                placeholder="URL Slug"
                class="form-control"
                [class.is-invalid]="isSubmitted && createPage.get('slug')?.invalid"
                formControlName="slug"/>
          </div>
        </div>
        <div class="logoTitle">
          <label class="header">Header</label>
          <div class="logo">
            <label for="logo">Logo</label>
            <div class="error" *ngIf="isSubmitted && createPage.get('logo')?.invalid">*Logo url is required</div>
            <input
                type="text"
                placeholder="Logo image URL"
                class="form-control"
                [class.is-invalid]="isSubmitted && createPage.get('logo')?.invalid"
                formControlName="logo"/>
          </div>
          <div class="title">
            <label for="header_title">Title</label>
            <div class="error" *ngIf="isSubmitted && createPage.get('name')?.invalid">*Name is required</div>
            <input
                type="text"
                placeholder="Enter page title/ product name"
                class="form-control"
                [class.is-invalid]="isSubmitted && createPage.get('header_title')?.invalid"
                formControlName="header_title"/>
          </div>
        </div>
        <div class="body">
          <label class="header">Body</label>
          <label class="chooseMap" style="margin-left: 35px;">Choose Maps</label>
          <div class="title">
            <label for="body_title">Title</label>
            <div class="error" *ngIf="isSubmitted && createPage.get('body_title')?.invalid">*Body title is required</div>
            <input
                type="text"
                placeholder="Enter page title/ product name"
                header_title
                class="form-control"
                [class.is-invalid]="isSubmitted && createPage.get('body_title')?.invalid"
                formControlName="body_title"/>
            <label for="body_content" class="chooseMap">Body Content</label>
            <div class="error" *ngIf="isSubmitted && createPage.get('body_content')?.invalid">*Body content is required</div>
            <textarea
                class="bodyContent"
                type="text"
                placeholder="Enter page title/ product name"
                [class.is-invalid]="isSubmitted && createPage.get('body_content')?.invalid"
                formControlName="body_content"> </textarea>
          </div>
          <div class="tableHeaders">
            <label>Table Header</label>
            <div class="container">
              <div class="row">
                <div class="col-md-3">
                  <label for="map_text">Maps Text</label>
                  <div class="error" *ngIf="isSubmitted && createPage.get('map_text')?.invalid">*Map text is required</div>
                  <input
                      type="text"
                      placeholder="Maps"
                      class="form-control"
                      [class.is-invalid]="isSubmitted && createPage.get('map_text')?.invalid"
                      formControlName="map_text"/>
                </div>
                <div class="col-md-3">
                  <label for="monthly_text">Monthly text</label>
                  <div class="error" *ngIf="isSubmitted && createPage.get('monthly_text')?.invalid">*Monthly text is required</div>
                  <input
                      type="text"
                      placeholder="Monthly"
                      class="form-control"
                      [class.is-invalid]="isSubmitted && createPage.get('monthly_text')?.invalid"
                      formControlName="monthly_text"/>
                </div>
                <div class="col-md-3">
                  <label for="annual_text">Annual text</label>
                  <div class="error" *ngIf="isSubmitted && createPage.get('annual_text')?.invalid">*Annual text is required</div>
                  <input
                      type="text"
                      placeholder="Annual"
                      class="form-control"
                      [class.is-invalid]="isSubmitted && createPage.get('annual_text')?.invalid"
                      formControlName="annual_text"/>
                </div>
                <div class="col-md-3">
                  <label for="day_text">Day pass text</label>
                  <div class="error" *ngIf="isSubmitted && createPage.get('day_text')?.invalid">*Day Pass text is required</div>
                  <input
                      type="text"
                      placeholder="Day Pass"
                      class="form-control"
                      [class.is-invalid]="isSubmitted && createPage.get('day_text')?.invalid"
                      formControlName="day_text" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <label for="map_subtext">Maps subtext</label>
                  <div class="error" *ngIf="isSubmitted && createPage.get('map_subtext')?.invalid ">*Map subtext is required</div>
                  <input
                      type="text"
                      placeholder="County name"
                      class="form-control"
                      [class.is-invalid]="isSubmitted && createPage.get('map_subtext')?.invalid"
                      formControlName="map_subtext"/>
                </div>
                <div class="col-md-3">
                  <label for="monthly_subtext">Monthly subtext</label>
                  <div class="error" *ngIf="isSubmitted && createPage.get('monthly_subtext')?.invalid">*Monthly subtext is required</div>
                  <input
                      type="text"
                      placeholder="$49/county"
                      class="form-control"
                      [class.is-invalid]="isSubmitted && createPage.get('monthly_subtext')?.invalid"
                      formControlName="monthly_subtext"/>
                </div>
                <div class="col-md-3">
                  <label for="annual_subtext">Annual subtext</label>
                  <div class="error" *ngIf="isSubmitted && createPage.get('annual_subtext')?.invalid">*Annual subtext is required</div>
                  <input
                      type="text"
                      placeholder="$490/county"
                      class="form-control"
                      [class.is-invalid]="isSubmitted && createPage.get('annual_subtext')?.invalid"
                      formControlName="annual_subtext"/>
                </div>
                <div class="col-md-3">
                  <label for="day_subtext">Day pass subtext</label>
                  <div class="error" *ngIf="isSubmitted && createPage.get('day_subtext')?.invalid">*Subtext is required</div>
                  <input
                      type="text"
                      placeholder="$19/county"
                      class="form-control"
                      [class.is-invalid]="isSubmitted && createPage.get('day_subtext')?.invalid"
                      formControlName="day_subtext"/>
                </div>

              </div>
              <div class="row">
                <div class="col-md-12">
                  <label style="display: inline;">Product Type</label>
                  <div class="error" *ngIf="isSubmitted && createPage.get('product_type')?.invalid ">*Product Type is required</div>
                  <select class="product_type" formControlName="product_type"
                      [class.is-invalid]="isSubmitted && createPage.get('product_type')?.invalid"
                      >
                      <option value="pv-map">Parcel Viewer</option>
                      <option value="wv-map">Wildfire Viewer</option>
                    <option value="custom-map">Custom Map</option>
                  </select>
                </div>

              </div>
            </div>

          </div>
          <div class="addOns">
            <label>Addons</label>
            <div class="collaborator">
              <input  type="checkbox"
              [checked]="addsOn" (change)="addsOnsChange($event)" fromControlName="collab_seats"/> Collaborator Seats
            </div>
            <div class="title">
              <label for="collab_seat_title">Title</label>
              <input type="text" placeholder="Enter section title" formControlName="collab_seat_title"/>
              <label for="collab_seat_content" class="chooseMap">Body Content</label>
              <textarea class="bodyContent" type="text" placeholder="Enter text or {%template%}" formControlName="collab_seat_content"> </textarea>
              <div class="smallBox">
                <div class="monthText">
                  <label for="collab_monthly_text">Monthly Text</label>
                  <textarea class="monthlyText" type="text" placeholder="Enter text or {%template%}" formControlName="collab_monthly_text"> </textarea>
                </div>
                <div class="annualText">
                  <label for="collab_annual_text">Annual Text</label>
                  <textarea class="monthlyText" type="text" placeholder="Enter text or {%template%}" formControlName="collab_annual_text"> </textarea>
                </div>

              </div>
            </div>
          </div>
          <div class="addOns">
            <div class="collaborator">
              <input type="checkbox"
              [checked]="outreach" (change)="outreachChange($event)" formControlName="outreach"/> Outreach
            </div>
            <div class="title">
              <label for="outreach_title">Title</label>
              <input type="text" placeholder="Enter section title" formControlName="outreach_title"/>
              <label for="outreach_content" class="chooseMap">Body Content</label>
              <textarea class="bodyContent" type="text" placeholder="Enter text or {%template%}" formControlName="outreach_content"> </textarea>
              <div class="smallBox">
                <div class="monthText">
                  <label for="outreach_monthly_text">Monthly Text</label>
                  <textarea class="monthlyText" type="text" placeholder="Enter text or {%template%}" formControlName="outreach_monthly_text"> </textarea>
                </div>
                <div class="annualText">
                  <label for="outreach_annual_text">Annual Text</label>
                  <textarea class="monthlyText" type="text" placeholder="Enter text or {%template%}" formControlName="outreach_annual_text"> </textarea>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <label for="footer">Footer</label>
          <div>
            <div class="error" *ngIf="isSubmitted && createPage.get('footer')?.invalid">*Footer is required</div>
            <textarea
                class="bodyContent"
                type="text"
                placeholder="Enter text or {%template%}"
                class="form-control"
                [class.is-invalid]="isSubmitted && createPage.get('footer')?.invalid"
                formControlName="footer"> </textarea>

          </div>

        </div>
        <button type="submit" class="btn btn-primary" style="margin-left: 10px;">
          <span class="glyphicon glyphicon-floppy-disk"></span> Save
        </button>
      </div>
    </form>
  </div>
</div>
<app-footer></app-footer>
<ngx-spinner [fullScreen]="true" type="" size="medium" bdColor="rgba(0, 0, 0, 0)">
  <p class="loading" > <img src="/assets/img/wheel.svg" alt="Girl in a jacket" width="100px" height="100px" > </p>
  </ngx-spinner>

