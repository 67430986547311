import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MapServiceService } from 'src/app/modules/map/services/Map-service/map-service.service';
import { PrePlanService } from 'src/app/modules/map/services/Pre Plan/pre-plan.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-pre-plans-edit',
  templateUrl: './pre-plans-edit.component.html',
  styleUrls: ['./pre-plans-edit.component.css'],
})
export class PrePlansEditComponent implements OnInit {
  prePlanData: any;
  routeFileUpload: any;
  sitePlanFileUpload: any;
  filename: any;
  showMsg = false;
  routeMsg = false;
  routeCheck = false;
  sitePlanCheck = false;
  formData: any;
  routechkbox: any;
  sitePlanchkbox: any;
  sitePlan: any = 'false';
  routePlan: any = 'false'
  prePlanform: FormGroup = new FormGroup({});

  constructor(
    private dialogRef: MatDialogRef<PrePlansEditComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    private mapService: MapServiceService,
    private globalService: GlobalService,
    public prePlanService: PrePlanService
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.sitePlanCheck = false;
    this.routeCheck = false;
    this.prePlanData = this.data;

    this.prePlanform = this.fb.group({
      access: '',
      filename: '',
      inspection_date: '',
      address: '',
      battalion: '',
      c_type: '',
      floor_cons: '',
      wall_cons: '',
      roof_cons: '',
      roof_cover: '',
      vert_openings: '',
      hvac: '',
      elec_shuttoff: '',
      gas_shuttoff: '',
      water_shuttoff: '',
      knox: '',
      alarm_panel: '',
      alarm_reset: '',
      sprinkler: '',
      fdc_loc: '',
      water_loc: '',
      water_type: '',
      source_gpm: '',
      exposures: '',
      hazards: '',
      staging: '',
      parcel_type: '',
      contact: '',
      day_phone: '',
      hours: '',
      night_phone: '',
      length: '',
      width: '',
      height: '',
      special_resources: '',
      insp_by: '',
      site_plan_updated: '',
      nfpa_red: '',
      nfpa_blue: '',
      nfpa_yellow: '',
      nfpa_white: '',
      fire_flow: '',
      city: '',
      latitude: '',
      longitude: '',
    });
    // Set Values
    this.prePlanform.controls['access'].setValue(this.prePlanData.access);
    this.prePlanform.controls['filename'].setValue(this.prePlanData.filename);
    this.prePlanform.controls['inspection_date'].setValue(
      this.prePlanData.inspectionDate
    );
    this.prePlanform.controls['address'].setValue(this.prePlanData.address);
    this.prePlanform.controls['battalion'].setValue(this.prePlanData.battalion);
    this.prePlanform.controls['c_type'].setValue(this.prePlanData.cType);
    this.prePlanform.controls['floor_cons'].setValue(
      this.prePlanData.floorCons
    );
    this.prePlanform.controls['wall_cons'].setValue(this.prePlanData.wallCons);
    this.prePlanform.controls['roof_cons'].setValue(this.prePlanData.roofCons);
    this.prePlanform.controls['roof_cover'].setValue(
      this.prePlanData.roofCover
    );
    this.prePlanform.controls['vert_openings'].setValue(
      this.prePlanData.vertOpenings
    );
    this.prePlanform.controls['hvac'].setValue(this.prePlanData.hvac);
    this.prePlanform.controls['elec_shuttoff'].setValue(
      this.prePlanData.elecShuttoff
    );
    this.prePlanform.controls['gas_shuttoff'].setValue(
      this.prePlanData.gasShuttoff
    );
    this.prePlanform.controls['water_shuttoff'].setValue(
      this.prePlanData.waterShuttoff
    );
    this.prePlanform.controls['knox'].setValue(this.prePlanData.knox);
    this.prePlanform.controls['alarm_panel'].setValue(
      this.prePlanData.alarmPanel
    );
    this.prePlanform.controls['alarm_reset'].setValue(
      this.prePlanData.alarmReset
    );
    this.prePlanform.controls['sprinkler'].setValue(this.prePlanData.sprinkler);
    this.prePlanform.controls['fdc_loc'].setValue(this.prePlanData.fdcLoc);
    this.prePlanform.controls['water_loc'].setValue(this.prePlanData.waterLoc);
    this.prePlanform.controls['water_type'].setValue(
      this.prePlanData.waterType
    );
    this.prePlanform.controls['source_gpm'].setValue(
      this.prePlanData.sourceGpm
    );
    this.prePlanform.controls['exposures'].setValue(this.prePlanData.exposures);
    this.prePlanform.controls['hazards'].setValue(this.prePlanData.hazards);
    this.prePlanform.controls['staging'].setValue(this.prePlanData.staging);
    this.prePlanform.controls['parcel_type'].setValue(
      this.prePlanData.parcelType
    );
    this.prePlanform.controls['contact'].setValue(this.prePlanData.contact);
    this.prePlanform.controls['day_phone'].setValue(this.prePlanData.dayPhone);
    this.prePlanform.controls['hours'].setValue(this.prePlanData.hours);
    this.prePlanform.controls['night_phone'].setValue(this.prePlanData.staging);
    this.prePlanform.controls['length'].setValue(this.prePlanData.length);
    this.prePlanform.controls['width'].setValue(this.prePlanData.width);
    this.prePlanform.controls['height'].setValue(this.prePlanData.height);
    this.prePlanform.controls['special_resources'].setValue(
      this.prePlanData.specialResources
    );
    this.prePlanform.controls['insp_by'].setValue(this.prePlanData.inspBy);
    this.prePlanform.controls['site_plan_updated'].setValue(
      this.prePlanData.sitePlan_updated
    );
    this.prePlanform.controls['nfpa_red'].setValue(this.prePlanData.nfpaRed);
    this.prePlanform.controls['nfpa_blue'].setValue(this.prePlanData.nfpaBlue);
    this.prePlanform.controls['nfpa_yellow'].setValue(
      this.prePlanData.nfpaYellow
    );
    this.prePlanform.controls['nfpa_white'].setValue(
      this.prePlanData.nfpaWhite
    );
    this.prePlanform.controls['fire_flow'].setValue(this.prePlanData.fireFlow);
    this.prePlanform.controls['city'].setValue(this.prePlanData.city);
    this.prePlanform.controls['latitude'].setValue(this.prePlanData.latitude);
    this.prePlanform.controls['longitude'].setValue(this.prePlanData.longitude);
  }
  routeFile(e: any) {
    try {
      this.routeFileUpload = e.target.files[0];
      this.routeMsg = true;
    } catch (error) {
      // console.log('no file was selected...');
    }
  }
  sitePlanFile(e: any) {
    try {
      this.sitePlanFileUpload = e.target.files[0];
      this.showMsg = true;
    } catch (error) {
      // console.log('no file was selected...');
    }
  }
  sitePlanCheckBoxType(value: boolean) {
    this.sitePlanCheck = value;
    if (this.sitePlanCheck === true) {
      this.sitePlan = 'true';
    } else {
      this.sitePlan = 'false';
    }
  }
  routeCheckBoxType(value: boolean) {
    this.routeCheck = value;
    if (this.routeCheck === true) {
      this.routePlan = 'true';
    } else {
      this.routePlan = 'false';
    }
  }
  edit() {
    // const body = {
    //   view_id: this.data.id,
    //   active_status: this.data.status,
    // };
  }

  onSubmit() {
    if (this.routeCheck) {
      this.routechkbox = 'true';
    } else {
      this.routechkbox = 'false';
    }
    if (this.sitePlanCheck) {
      this.sitePlanchkbox = 'true';
    } else {
      this.sitePlanchkbox = 'false';
    }
    if (!this.routeFileUpload) {
      this.routeFileUpload = '';
    }
    if (!this.sitePlanFileUpload) {
      this.sitePlanFileUpload = '';
    }
    if (!this.prePlanData.latitude) {
      this.prePlanData.latitude = '';
    }
    if (!this.prePlanData.longitude) {
      this.prePlanData.longitude = '';
    }
    this.prePlanData = this.prePlanform.value;
    this.formData = new FormData();
    this.formData.append('id', this.data.id.toString());
    this.formData.append('access', this.prePlanData.access);
    this.formData.append('filename', this.prePlanData.filename);
    this.formData.append('inspection_date', this.prePlanData.inspection_date);
    this.formData.append('address', this.prePlanData.address);
    this.formData.append('unit', this.prePlanData.battalion);
    this.formData.append('construction_type', this.prePlanData.c_type);
    this.formData.append('floor_cons', this.prePlanData.floor_cons);
    this.formData.append('wall_cons', this.prePlanData.wall_cons);
    this.formData.append('roof_cons', this.prePlanData.roof_cons);
    this.formData.append('roof_cover', this.prePlanData.roof_cover);
    this.formData.append('vert_openings', this.prePlanData.vert_openings);
    this.formData.append('hvac', this.prePlanData.hvac);
    this.formData.append('elec_shuttoff', this.prePlanData.elec_shuttoff);
    this.formData.append('gas_shuttoff', this.prePlanData.gas_shuttoff);
    this.formData.append('water_shuttoff', this.prePlanData.water_shuttoff);
    this.formData.append('knox', this.prePlanData.knox);
    this.formData.append('alarm_panel', this.prePlanData.alarm_panel);
    this.formData.append('alarm_reset', this.prePlanData.alarm_reset);
    this.formData.append('sprinkler', this.prePlanData.sprinkler);
    this.formData.append('fdc_loc', this.prePlanData.fdc_loc);
    this.formData.append('water_loc', this.prePlanData.water_loc);
    this.formData.append('water_type', this.prePlanData.water_type);
    this.formData.append('source_gpm', this.prePlanData.source_gpm);
    this.formData.append('exposures', this.prePlanData.exposures);
    this.formData.append('hazards', this.prePlanData.hazards);
    this.formData.append('staging', this.prePlanData.staging);
    this.formData.append('parcel_type', this.prePlanData.parcel_type);
    this.formData.append('contact', this.prePlanData.contact);
    this.formData.append('day_phone', this.prePlanData.day_phone);
    this.formData.append('hours', this.prePlanData.hours);
    this.formData.append('night_phone', this.prePlanData.night_phone);
    this.formData.append('length', this.prePlanData.length);
    this.formData.append('width', this.prePlanData.width);
    this.formData.append('height', this.prePlanData.height);
    this.formData.append(
      'special_resources',
      this.prePlanData.special_resources
    );
    this.formData.append('insp_by', this.prePlanData.insp_by);
    this.formData.append(
      'site_plan_updated',
      this.prePlanData.site_plan_updated
    );
    this.formData.append('nfpa_red', this.prePlanData.nfpa_red);
    this.formData.append('nfpa_blue', this.prePlanData.nfpa_blue);
    this.formData.append('nfpa_yellow', this.prePlanData.nfpa_yellow);
    this.formData.append('nfpa_white', this.prePlanData.nfpa_white);
    this.formData.append('fire_flow', this.prePlanData.fire_flow);
    this.formData.append('city', this.prePlanData.city);
    this.formData.append('latitude', (this.prePlanData.latitude = ''));
    this.formData.append('longitude', (this.prePlanData.longitude = ''));

    this.formData.append('route', this.routeFileUpload);
    this.formData.append('site_plan', this.sitePlanFileUpload);
    this.formData.append('clear_route', this.routePlan);
    this.formData.append('clear_site_plan', this.sitePlan);
    this.globalService.toggleLoader(true);
    this.mapService.editPrePlanDocument(this.formData).subscribe((res: any) => {
      setTimeout(() => {
        this.globalService.toggleLoader(false);
        this.prePlanService.refreshPrePlan(res)
        this.filename = '';
        this.dialogRef.close();
      }, 1000);
    });
  }
  onClose() {
    this.filename = '';
    this.dialogRef.close();
  }
}
