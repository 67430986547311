import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-capture-tool-model',
  templateUrl: './error-capture-tool-model.component.html',
  styleUrls: ['./error-capture-tool-model.component.css']
})
export class ErrorCaptureToolModelComponent implements OnInit {
  title: any
  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,) {
    //
   }

  ngOnInit(): void {
    this.title = this.data.title  }


  close(): void {
    this.dialog.closeAll()
  }
}
