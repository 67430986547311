<div id="footer">
    <div class="container footer">
        <div class="row">
            <div>
                © <a class="url-links" href="https://enplan.com">ENPLAN</a>
            </div>
            <div>
                <a class="url-links" href="https://mapport.com">MapPort</a>® is an <a href="https://enplan.com"
                    class="url-links">ENPLAN</a> web map services
                platform.
            </div>
            <div>
                <a href="https://mapport.com/privacy-policy" target="_blank" style="font-weight: 400;">Privacy
                    Policy</a>&nbsp;&nbsp;&nbsp;
                <a href="https://mapport.com/terms-service/" target="_blank" style="font-weight: 400;">Terms of
                    Service</a>
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="mailto:wmsgroup@enplan.com" target="_blank"
                    style="font-weight: 400;">wmsgroup@enplan.com</a>&nbsp;&nbsp;&nbsp;
                <a href="tel:1-530-402-4783" target="_blank" style="font-weight: 400;">(530) 402-4783</a>
            </div>

        </div>
    </div>

</div>