export class createLayer {
  id!: number;
  capture!: boolean;
  name!: string;
  typename!: string;
  comment!: string;
  select_order!: string;
  visibility!: string;
  selectability!: string;
  limit_selectability!: string;
  selection_limit!: string;
  ap_map_query_fields!: string
  editability!: string;
  data_channel!: string;
  zoom!: number;
  map_server!: string;
  map_name!: number;
  map_params!: string;
  map_format!: string;
  cors_keyword!: string;
  map_srid!: string;
  sql_schema!: string;

  sql_table!: string;
  sql_id_field!: string;
  sql_name_field!: string;
  sql_geometry_field!: string;
  sql_touch_field!: string;
  heading!: string;
  heading_tooltip!: string;
  class_group!: string;
  show_order!: string;
  map_order!: string;
  layer_type!: string;

  unique_group!: string;
  icon_url!: string;
  show_in_legend!: string;
  visible_by_default!: string;
  tool_tip!: string;
  limit_export!: string;
  automatic_refresh!: string;
  automatic_refresh_time!: string;
  is_parcel_layer!: string;
}
