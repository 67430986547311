import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapServiceService } from 'src/app/modules/map/services/Map-service/map-service.service';
import { SubscribErrorModalComponent } from 'src/app/modules/shared/components/modals/subscrib-error-modal/subscrib-error-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { CourtesyService } from 'src/app/services/courtesy.service';
import { SnackbarService } from 'src/app/services/snackbarService/snackbar.service';

@Component({
  selector: 'app-courtesy-account-verification',
  templateUrl: './courtesy-account-verification.component.html',
  styleUrls: ['./courtesy-account-verification.component.css'],
})
export class CourtesyAccountVerificationComponent implements OnInit {
  loginStatus: any;
  errorMsg: any;
  id: any;
  showForm: any;
  email: any;
  deviceOption: any;
  user: any = {};
  industry: any;
  product = '';
  courtesyForm!: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private service: CourtesyService,
    private router: Router,
    public dialog: MatDialog,
    public auth: AuthService,
    public fb: FormBuilder,
    private mapService: MapServiceService,
    private spinner: NgxSpinnerService,
    public _snackbar: SnackbarService
  ) {
    this.courtesyForm = this.fb.group({
      first_name: [''],
      last_name: [''],
      email: [''],
      password_1: [''],
      password_2: [''],
      phone_number: [''],
    });
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') || '';
    localStorage.setItem('courtesyId', this.id);
    this.email = this.route.snapshot.paramMap.get('email') || '';
    this.user = {
      email: this.email,
      device: 'Industry',
    };

    this.verifyUser();
  }
  verifyUser() {
    this.service.verifyUser(this.id, this.email).subscribe((res) => {
      this.courtesyForm.controls.first_name.setValue(res.user.first_name);
      this.courtesyForm.controls.last_name.setValue(res.user.last_name);
      this.courtesyForm.controls.email.setValue(res.user.email);
      this.courtesyForm.controls.phone_number.setValue(res.user.phone);
      if (res.success) {
        this.showForm = true;
      } else {
        this.showForm = false;
      }
    });
  }
  submit() {
    if (this.courtesyForm.value.first_name === '') {
      this.dialog.open(SubscribErrorModalComponent, {
        panelClass: 'app-full-bleed-dialog',
        backdropClass: 'bdrop',
        position: {
          top: '10%',
        },
        data: {
          title: 'First name blank.',
          content: 'This field is required.',
        },
      });
      return;
    } else if (this.courtesyForm.value.last_name === '') {
      this.dialog.open(SubscribErrorModalComponent, {
        panelClass: 'app-full-bleed-dialog',
        backdropClass: 'bdrop',
        position: {
          top: '10%',
        },
        data: {
          title: 'Last name blank.',
          content: 'This field is required.',
        },
      });
      return;
    } else if (this.courtesyForm.value.password_2 === '') {
      this.dialog.open(SubscribErrorModalComponent, {
        panelClass: 'app-full-bleed-dialog',
        backdropClass: 'bdrop',
        position: {
          top: '10%',
        },
        data: {
          title: 'Password blank.',
          content: 'This field is required.',
        },
      });
      return;
    } else if (
      this.courtesyForm.value.password_1 !== this.courtesyForm.value.password_2
    ) {
      this.dialog.open(SubscribErrorModalComponent, {
        panelClass: 'app-full-bleed-dialog',
        backdropClass: 'bdrop',
        position: {
          top: '10%',
        },
        data: {
          title: `Password Doesn't Match.`,
          content: 'Passwords Should Match',
        },
      });
      return;
    } else {
      this.spinner.show();
      const body = {
        first_name: this.courtesyForm.value.first_name,
        last_name: this.courtesyForm.value.last_name,
        password: this.courtesyForm.value.password_1,
        email: this.courtesyForm.value.email,
        phone: this.courtesyForm.value.phone_number,
      };
      this.service.updateUser(this.id, body).subscribe(
        () => {
          this.login();
        },
        () => {
          this._snackbar.openSnackBar('An error Occurred');
        }
      );
    }
  }

  login() {
    const body = {
      email: this.email,
      password: this.courtesyForm.value.password_1,
    };
    this.auth.login(body).subscribe(
      (result: any) => {
        if (!result.message) {
          this.auth.setToken(result);
          // localStorage.setItem('signUp', 'true');
          // localStorage.setItem('colSignUp', 'true');
          localStorage.setItem('firstTime', 'true');
          this.router.navigate(['/']);
          this.spinner.hide();
        }
      },
      () => {
        this._snackbar.openSnackBar('An error Occurred');
      }
    );
  }
}
