import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { TabService } from 'src/app/services/tab/tab.service';
import { TabItem } from '../../models/tab/tab.model';

@Component({
  selector: 'app-layers-tab',
  templateUrl: './layers-tab.component.html',
  styleUrls: ['./layers-tab.component.css'],
})
export class LayersTabComponent implements OnInit {
  user: any;
  tabs: TabItem[] | undefined;
  constructor(public router: Router, public tabService: TabService) {}

  ngOnInit(): void {
    this.tabService.activeTab$.subscribe(() => {
      // console.log('active tab set: ', tab);
    });
    this.tabs = this.tabService.getTabsLayer();
    this.tabService.activeTab$.next(this.tabs[6]);
  }
  tabSelected(tab: TabItem) {
    this.tabService.activeTab$.next(tab);
  }
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    if (tabChangeEvent.index == 6) {
      this.router.navigateByUrl('global');
    } else if (tabChangeEvent.index == 7) {
      this.router.navigateByUrl('securityControl');
    }
  };
}
