import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { UpdateNotificationComponent } from 'src/app/modules/dashboard/modals/update-notification/update-notification.component';
import { DashboardServiceService } from 'src/app/modules/dashboard/services/dashboard-service.service';
import { AssessorParcelsService } from 'src/app/modules/map/services/Assessor-Parcels/assessor-parcels.service';
import { MapServiceService } from 'src/app/modules/map/services/Map-service/map-service.service';
import { PropertyDocumentsPaymentComponent } from '../property-documents-payment/property-documents-payment.component';

@Component({
  selector: 'app-property-reports-menu',
  templateUrl: './property-reports-menu.component.html',
  styleUrls: ['./property-reports-menu.component.css']
})
export class PropertyReportsMenuComponent implements OnInit {
  requireCheckbox = false
  arrow = 'keyboard_arrow_up'
  isActive: any
  propertyData: any
  show: any;
  backgroundColor="#bebebe"
  activeClass: any
  firstName: any;
  lastName: any
  lastnumber: any
  paymentInfo: any;
  currentCard: any;
  expiryDate: any
  expired: any = false;
  isDisabled = false
  nextButtonDisable: any = true
  userProfileInfo: any
  byPassPayment: any
  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PropertyReportsMenuComponent>,
    public assessorParcelService: AssessorParcelsService,
    public mapService: MapServiceService,
    private dashboardService: DashboardServiceService,
    private spinner: NgxSpinnerService) {
    this.getReportList()
  }

  ngOnInit(): void {
    this.getUserInfo()
  }

  getUserInfo() {
    this.spinner.show();
    this.dashboardService.getDashboardData().subscribe((res: any) => {

      this.userProfileInfo = res;
      this.byPassPayment = this.userProfileInfo.profile_payment.profile.bypass_payment;
      if(res.message === 'This user has no account on recurly') {
        this.spinner.hide();
        this.assessorParcelService.noPaymentMethod = true
      } else {
        this.paymentInfo = this.userProfileInfo.profile_payment;
        this.spinner.hide();
        if(!this.paymentInfo.payment_info) {
          this.assessorParcelService.noPaymentMethod = true

        } else {
          this.currentCard = this.paymentInfo.payment_info.card_number;
          this.firstName = this.paymentInfo.payment_info.first_name;
          this.lastName = this.paymentInfo.payment_info.last_name;
          this.expiryDate = this.paymentInfo.payment_info.expiry_date;

          this.assessorParcelService.noPaymentMethod = false

        }

        this.spinner.hide();
        if (this.userProfileInfo
        ) {
          this.paymentInfo = this.userProfileInfo.profile_payment;
          this.expired = false;
        } else {
          this.expired = true;
        }

      }
    });
  }
  getReportList() {
    this.assessorParcelService.getAllReportsList().subscribe((res: any) => {
      this.propertyData = res
      if (this.data) {
        for (let i = 0; i < this.propertyData.length; i++) {
          for (let j = 0; j < this.data.reports.length; j++) {
            if (this.propertyData[i].name == this.data.reports[j].name) {
              this.propertyData[i].is_enabled = true
              this.nextButtonDisable = false
            }
          }
        }
      } else {
        for (let i = 0; i < this.propertyData.length; i++) {
            if (this.propertyData[i].is_enabled == true) {
              this.assessorParcelService.reportsList = []
              this.assessorParcelService.reportsList.push(this.propertyData[i])
            }
        }
        this.propertyData = res
      }
    })
  }

  requireCheckBoxType(value: any) {
    if (this.assessorParcelService.reportsList.length > 0) {
      const index = this.assessorParcelService.reportsList.findIndex(i => i.name === value.name);
      if (index != -1) {
        this.assessorParcelService.reportsList.splice(index, 1);
        if(this.assessorParcelService.reportsList.length == 0) {
          this.nextButtonDisable = true
        } else {
          this.nextButtonDisable = false
        }
      } else {
        this.assessorParcelService.reportsList.push(value);
        this.nextButtonDisable = false
      }

    } else {
      this.assessorParcelService.reportsList.push(value);
      this.nextButtonDisable = false
    }


  }

  activeButton(i: any): void {
    this.isActive = null
    if (this.show == i) {
      this.show = -1;
      this.isActive = this.show
    }
    else {
      this.show = i;
      this.isActive = i
    }
  }
  getParcelsFiles(): void {
    this.dialog.open(PropertyDocumentsPaymentComponent, {
      disableClose: true,
      data: {
        list: this.assessorParcelService.reportsList,
        firstName: this.firstName,
        lastName: this.lastName,
        currentCard: this.currentCard,
        expiryDate: this.expiryDate,
        noPaymentMethod: this.assessorParcelService.noPaymentMethod,
        byPassPayment: this.byPassPayment
      },
    });
  }
  closeProperty() {
    this.assessorParcelService.reportsList = []
    this.dialogRef.close()
  }
  closeDialog() {
    this.dialogRef.close()
  }
}
