<mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedTab" (selectedIndexChange)="setTabState($event)"
  animationDuration="0ms">
  <ng-container *ngFor="let tab of tabs" (click)="tabSelected(tab)">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="mat-label-text" (click)="tabSelected(tab)">{{ tab.data.label }}</div>
      </ng-template>
      <ng-container *ngComponentOutlet="tab.component"></ng-container>
    </mat-tab>
  </ng-container>
</mat-tab-group>
