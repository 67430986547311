<div class="modal-body" style="display: grid">
  <h3 id="copy-title">Clone Layer: {{ layerData.name }}</h3>
  <form id="copy-map-form" #form="ngForm" (ngSubmit)="save(form.value)">
    <input type="hidden" name="map_id" value="31" id="old-map-id" />
    <div class="col-md-12" style="margin-bottom: 3%">
      <div class="col-md-6">
        <h4>General options</h4>
        <div class="form-group">
          <label for="map-name">Layer name</label>
          <input
            type="text"
            class="form-control"
            name="layer_name"
            id="map-name"
            [placeholder]="layerData.name"
            [value]="layerData.name"
            [(ngModel)]="layer.layer_name"
            #layer_name="ngModel"
          />
        </div>
        <h4>Clone options</h4>
        <div class="form-check">
          <input
            class="form-check-input"
            name="pdf_table"
            type="checkbox"
            value="true"
            id="copy-layer"
            [(ngModel)]="layer.pdf_table"
            #pdf_table="ngModel"
          />
          <label class="form-check-label" for="copy-layer"
            >Include Pdf Table</label
          >
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            name="show_fields"
            type="checkbox"
            value="true"
            id="copy-fields"
            [(ngModel)]="layer.show_fields"
            #show_fields="ngModel"
          />
          <label class="form-check-label" for="copy-fields"
            >Include Show fields</label
          >
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            name="labels"
            type="checkbox"
            value="true"
            id="copy-groups"
            [(ngModel)]="layer.labels"
            #labels="ngModel"
          />
          <label class="form-check-label" for="copy-groups"
            >Include Labels</label
          >
        </div>
      </div>
    </div>
    <div class="button-group">
      <button
        type="button"
        class="btn m-inline btn-default"
        data-dismiss="modal"
        style="float: left"
        (click)="close()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn m-inline btn-default"
        id="copy-map-btn"
        style="float: right"
        type="submit"
      >
        Okay
      </button>
    </div>
  </form>
</div>
<ngx-spinner [fullScreen]="true" type="" size="medium" bdColor="rgba(0, 0, 0, 0)">
  <p class="loading" > <img src="/assets/img/wheel.svg" alt="Girl in a jacket" width="100px" height="100px" > </p>
  </ngx-spinner>
