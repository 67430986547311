import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MapServiceService } from 'src/app/modules/map/services/Map-service/map-service.service';

@Component({
  selector: 'app-delete-capture',
  templateUrl: './delete-capture.component.html',
  styleUrls: ['./delete-capture.component.css']
})
export class DeleteCaptureComponent implements OnInit {
  text: any
  constructor(private dialog: MatDialog, public mapService: MapServiceService) { }

  ngOnInit(): void {
    this.text = this.mapService.getAllModals.capture_delete
  }
  close(): void {
    this.dialog.closeAll()
  }
}
