<nav
  id="main-prod-header"
  class="navbar navbar-default whitebar"
  style="background-color: #eee"
>
  <div *ngIf="product" class="container">
    <div class="navbar-header row" style="width: 100%">
      <div class="col-md-3" *ngIf="this.product !== 'wildfiredamageviewer'">
        <a
          href="/{{
            product
          }}.mapport.com/?_ga=2.160925693.1140489400.1621849876-1863253860.1621510598"
        >
          <img
            class="logo-img img-responsive"
            id="logo_placeholder"
            src="{{productData.logo}}"
            onerror="this.src='assets/img/map-symbols/Custom_Map_logoV3.svg'"
          />
        </a>
      </div>
      <ng-container *ngIf="this.product === 'wildfiredamageviewer'">
        <div class="col-lg-1">
          <a
            href="/{{
              product
            }}.mapport.com/?_ga=2.160925693.1140489400.1621849876-1863253860.1621510598"
          >
            <img
              class="logo-img img-responsive"
              id="logo_placeholder"
              src="/assets/img/map-symbols/fdp_logo.svg"
            />
          </a>
        </div>
        <div class="col-lg-4" style="display: inline; margin-left: -42px" >
          <h1 class="login-head">
            <a href="https://wildfiredamageviewer.mapport.com"
              >WILDFIRE DAMAGE VIEWER</a
            >
          </h1>
          <p class="login-subhead">
            <a href="https://wildfiredamageviewer.mapport.com"
              >AN ENPLAN WEB MAP SERVICE</a
            >
          </p>
        </div>
      </ng-container>

      <!-- static nav section -->
      <ul style="margin-top: 24px; float: right">
        <li class="list-deco" *ngIf="auth.user">
          <a href={{accountUrl}}>MY ACCOUNT</a>
        </li>
        <li class="list-deco" *ngIf="!auth.user">
          <a href="https://mapport.com/{{product}}/parcel-viewer-news/">NEWS</a>
        </li>
        <li class="list-deco">
          <a href="https://mapport.com/{{product}}/parcel-viewer-support/">SUPPORT</a>
        </li>
        <li class="list-deco" *ngIf="!auth.user">
          <a href="https://mapport.com/{{product}}/parcel-viewer-contact/">CONTACT US</a>
        </li>
        <li
          *ngIf="!auth.user"
          routerLink="/{{ product }}/login"
          class="list-deco menu-button-login"
        >
          <a>LOG IN</a>
        </li>

        <li *ngIf="auth.user" class="list-deco menu-button-login">
          <a (click)="auth.logout()">LOG OUT</a>
        </li>
        <li
          class="list-deco menu-button-subscribe"
          *ngIf="this.product !== 'wildfireviewer'"
        >
          <a href="/parcelviewer/subscribe">SUBSCRIBE</a>
        </li>
      </ul>
    </div>
    <hr />
  </div>
</nav>

<!-- static code here -->
<!-- <nav
  id="main-prod-header"
  class="navbar navbar-default whitebar"
  style="background-color: #eee"
>
  <div *ngIf="product" class="container">
    <div class="navbar-header row" style="width: 100%">
      <div class="col-md-3" *ngIf="this.product !== 'wildfiredamageviewer'">
        <a
          href="/{{
            product
          }}.mapport.com/?_ga=2.160925693.1140489400.1621849876-1863253860.1621510598"
        >
          <img
            class="logo-img img-responsive"
            id="logo_placeholder"
            src="/assets/img/map-symbols/{{ product }}.svg"
          />
        </a>
      </div>
      <ng-container *ngIf="this.product === 'wildfiredamageviewer'">
        <div class="col-lg-1">
          <a
            href="/{{
              product
            }}.mapport.com/?_ga=2.160925693.1140489400.1621849876-1863253860.1621510598"
          >
            <img
              class="logo-img img-responsive"
              id="logo_placeholder"
              src="/assets/img/map-symbols/fdp_logo.svg"
            />
          </a>
        </div>
        <div class="col-lg-4" style="display: inline; margin-left: -42px" >
          <h1 class="login-head">
            <a href="https://wildfiredamageviewer.mapport.com"
              >WILDFIRE DAMAGE VIEWER</a
            >
          </h1>
          <p class="login-subhead">
            <a href="https://wildfiredamageviewer.mapport.com"
              >AN ENPLAN WEB MAP SERVICE</a
            >
          </p>
        </div>
      </ng-container>

      <ul style="margin-top: 24px; float: right">
        <li class="list-deco" *ngIf="auth.user">
          <a href={{accountUrl}}>MY ACCOUNT</a>
        </li>
        <li class="list-deco" *ngIf="!auth.user">
          <a href="https://mapport.com/{{product}}/parcel-viewer-news/">NEWS</a>
        </li>
        <li class="list-deco">
          <a href="https://mapport.com/{{product}}/parcel-viewer-support/">SUPPORT</a>
        </li>
        <li class="list-deco" *ngIf="!auth.user">
          <a href="https://mapport.com/{{product}}/parcel-viewer-contact/">CONTACT US</a>
        </li>
        <li
          *ngIf="!auth.user"
          routerLink="/{{ product }}/login"
          class="list-deco menu-button-login"
        >
          <a>LOG IN</a>
        </li>

        <li *ngIf="auth.user" class="list-deco menu-button-login">
          <a (click)="auth.logout()">LOG OUT</a>
        </li>
        <li
          class="list-deco menu-button-subscribe"
          *ngIf="this.product !== 'wildfireviewer'"
        >
          <a href="/parcelviewer/subscribe">SUBSCRIBE</a>
        </li>
      </ul>
    </div>
    <hr />
  </div>
</nav> -->
