import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CourtesyService } from 'src/app/services/courtesy.service';

@Component({
  selector: 'app-courtesy-maps',
  templateUrl: './courtesy-maps.component.html',
  styleUrls: ['./courtesy-maps.component.css'],
})
export class CourtesyMapsComponent implements OnInit {
  courtesyMapForm!: FormGroup;
  allMaps: any;
  accessList: any;
  constructor(
    private fb: FormBuilder,
    private service: CourtesyService,
    private spinner: NgxSpinnerService
  ) {
    this.service.emitData.subscribe(() => {
      this.service.getMapsData(this.courtesyMapForm.value);
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.service.getAllMaps().subscribe((res) => {
      this.allMaps = res;
      this.spinner.hide();
    });
    this.accessList = this.service.accessLevel;
    this.courtesyMapForm = this.fb.group({
      mapsData: this.fb.array([]),
    });
  }
  mapInfo(): FormArray {
    return this.courtesyMapForm.get('mapsData') as FormArray;
  }

  newMapdata(): FormGroup {
    return this.fb.group({
      map_id: [''],
      privilege: ['100'],
      expiryCheck: true,
      expiry_duration: [{ value: '30', disabled: false }],
    });
  }
  addMaps(): void {
    this.mapInfo().push(this.newMapdata());
  }
  removeMapInfo(empIndex: number) {
    this.mapInfo().removeAt(empIndex);
  }
  durationEnable(empIndex: number) {
    if (this.mapInfo().controls[empIndex].value.expiryCheck === false) {
      this.mapInfo().controls[empIndex].get(['expiry_duration'])?.enable();
    } else {
      this.mapInfo().controls[empIndex].get(['expiry_duration'])?.disable();
    }
  }
}
