import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.css'],
})
export class MessageModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private global: GlobalService
  ) {}

  ngOnInit(): void {
    // method to handle any additional initialization tasks.
  }

  close(): void {
    this.global.messageModalRef.close();
  }
}
