import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareLinkService {
  shareUrlId: any;
  privilegeLevel: any = '100';
  tcpEmbed: any = true;
  basicMode: any = false;
  dialogTemplate: any
  dialogTemplateCheckbox: any
  liveShareId: any
  fullScreenEmbedTool: Subject<any> = new Subject<any>();
  getEmbedUrl: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient) { }

  public shareLink(mapId: any, share: any, body: any): Observable<any> {
    body.map_id = parseInt(mapId);
    body.share_id = share.toString() || '';
    return this.http.post('/share/create-link/', body);
  }
  public setFullScreenIcon(value: any) {
    this.fullScreenEmbedTool.next(value);
  }
  public setUrlEmbed(value: any) {
    this.getEmbedUrl.next(value);
  }
}
