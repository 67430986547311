import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { createSubscribe } from '../../shared/models/subscribe/createSubscribe.model';
import { userData } from '../../shared/models/user/user';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  subscribeNow: Subject<any> = new Subject<any>();
  hideCreateAccount = false;
  getAllModals: any
  constructor(private http: HttpClient) {
    this.dynamicModalCheck()
  }

  callSubscribeFunction(value: any) {
    this.subscribeNow.next(value);
  }

  getRecurlyPlans() {
    return this.http.get('/dashboard/get-recurly-plan-codes/');
  }

  getSubscriptions(data: any) {
    return this.http.post('/subscribe/', data);
  }

  purchasePlans(data: any) {
    return this.http.post('/subscribe/', data);
  }

  captcha(token: any) {
    return this.http.post('/document/verify-captcha/', token);
  }

  rigisterUser(userData: any): Observable<userData> {
    return this.http
      .post<userData>(`/authentication/register-user/`, userData)
      .pipe(shareReplay());
  }

  // Email Verification
  verifyEmail(code: any): Observable<any> {
    return this.http
      .post(`/authentication/verify-otp/`, {
        otp: code,
      })
      .pipe();
  }

  public getDynamicModal(): Observable<any> {
    return this.http.get('/dashboard/dynamic-modals/', {});
  }

  //get all Modals
  public dynamicModalCheck(): void {
    this.getDynamicModal().subscribe((res) => {
      if (res) {
        this.getAllModals = res.Modals
      }
    });
  }

  // Resend Code for Email Verification
  resendCode(): Observable<any> {
    return this.http.post(`/authentication/resend-otp/`, {}).pipe();
  }

  antiBotSecurity(body: any): Observable<any> {
    return this.http.post(`/security/`, body).pipe();
  }
  getSlug(slug: any): Observable<createSubscribe> {
    return this.http.get<createSubscribe>('/subscribe/page/' + slug + '/');
  }
}
