import { Component, OnInit, OnDestroy } from '@angular/core';

import WKT from 'ol/format/WKT';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';

import { ViewAndExportDataComponent } from '../view-and-export-data/view-and-export-data.component';
import { MatDialog } from '@angular/material/dialog';

import { FeatureNameComponent } from '../feature-name/feature-name.component';
import { MapServiceService } from '../../../services/Map-service/map-service.service';
import { SideNavService } from '../../../services/sidenav/side-nav.service';
import { EditFeatureCaptureComponent } from '../capture-tool/edit-feature-capture/edit-feature-capture.component';
import { CaptureToolService } from '../../../services/Capture-Tool/capture-tool.service';

@Component({
  selector: 'app-features-list',
  templateUrl: './features-list.component.html',
  styleUrls: ['./features-list.component.css'],
})
export class FeaturesListComponent implements OnInit, OnDestroy {
  result: any;
  key: any;
  feature: any;
  feature1: any;
  resultLayer: any;
  switchVisibleNow: any = true;
  selected: any = null;
  firstKey: any;
  showSwitch: any;
  objectKeys: any;
  boundaries: any;
  callNow: any = false;
  featureSelecttion: any;
  checkLabel: any;
  legendToolName: any;
  showLabelOnList: any;
  isLabelPresent: any;
  buffer: any;
  layerStyle: any;
  multistyle: any;
  ctrlPress: any;
  resData: any;
  ctrlData: any;
  uniqFeatureData: any;
  featureExist = false;
  defaultView: any = false;
  ctrlRes: any;
  selectedFeatures: any;
  sidenavWidth: any = 200;
  fontSize: any = 11;
  zoomRight: any = 80;
  googleRight: any = 50;
  viewRight: any = 48;
  viewRight1: any = 82;
  closeLeft: any = 80;
  backLeft: any = 80;
  tooltip1: any = 68;
  tooltip2: any = 93;
  multistyle1: any
  tools: any;
  constructor(
    public sidenavService: SideNavService,
    public mapService: MapServiceService,
    private dialog: MatDialog,
    public captureToolService: CaptureToolService
  ) {
    //
  }

  ngOnInit(): void {
    this.mapService.hideHeader.subscribe((res: any) => {
      this.defaultView = res;
    });
    //hover activate
    this.featureSelecttion = this.mapService.mapData.map_data.map_data.feature_selection
    this.mapService.stopHover = true;
    this.sidenavService.sideNavState('open');
    this.mapService.toolName.subscribe((res) => {
      this.legendToolName = res;
    });
    this.sidenavService.ctrlCheck.subscribe((res1: any) => {
      this.ctrlPress = res1;
    });
    this.sidenavService.clickedInfo(0);
    this.objectKeys = Object.keys;
    this.result = this.sidenavService.dataPassToRightPanel;
    this.boundaries = this.mapService.mapData.map_data.map_data.layers;
    this.tools = this.mapService.mapData.map_data.map_data.tools;
    this.sidenavService.isLoadedFeature('Feature');
    this.sidenavService.setRightToolName('FeatureList');
    this.mapService.parcelKey.subscribe((res: any) => {
      this.key = res;
      if (this.key === 'z') {
        this.clickToZoom();
      }
    });
    this.result.sort((a: any, b: any) => {
      return a.label - b.label;
    });

    let conditionCheck: any = false;
    // let conditionCheck1: any = false;
    const layerId = this.result.map((a: any) => a.layer_id);
    for (let i = 0; i < this.boundaries.length; i++) {
      if (
        layerId.includes(this.boundaries[i]?.id.toString()) &&
        this.boundaries[i]?.is_parcel_layer
      ) {
        this.mapService.getbufferShowFiles(true);
        this.mapService.isParcelLayer = true;
        break;
      } else {
        this.mapService.getbufferShowFiles(false);
        this.mapService.isParcelLayer = false;
      }
    }
    for (let i = 0; i < this.result.length; i++) {
      if (this.result[i].label && !conditionCheck) {
        this.isLabelPresent = true;
        conditionCheck = true;
      }
      for (let j = 0; j < this.boundaries.length; j++) {
        if (
          this.result[i].layer_id.toString() ===
          this.boundaries[j].id.toString()
        ) {
          this.result[i].style = this.boundaries[j].style;
          this.result[i].icon = this.boundaries[j].legend.icon;
          this.result[i].typeName = this.boundaries[j].typename;
          this.result[i].nfid = this.boundaries[j].nfid;
          this.result[i].layerName = this.boundaries[j].name;
        }
      }
    }
    console.log(this.result)
    this.hideShowLable();
    this.callNow = false;
  }



  uniqByKeepFirst(a: any, key: any) {
    const seen = new Set();
    return a.filter((item: any) => {
      const k = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }
  callFeatureName(data: any, index: any): void {
    //to prevent zoom
    if (this.mapService.ctrlPress) {
      const res: any = {};
      res.features = [];
      res.features.push(data);
      this.mapService.ctrlPush = this.ctrlPress;
      this.resData = res;

      this.ctrlData = '';
      this.uniqFeatureData = '';
      const dataFeature = this.mapService.ParcelData;
      this.featureExist = false;
      for (let j = 0; j < dataFeature.length; j++) {
        if (this.resData.features[0].id === dataFeature[j].id) {
          dataFeature.splice(j, 1);
          this.featureExist = true;
        }
      }

      if (this.featureExist) {
        this.uniqFeatureData = dataFeature;
      } else {
        this.ctrlData = this.resData.features.concat(dataFeature);
        this.uniqFeatureData = this.uniqByKeepFirst(
          this.ctrlData,
          (it: any) => it.id
        );
      }
      // localStorage.removeItem('seletedFeature');
      this.mapService.ParcelData = [];
      this.mapService.ParcelData = this.uniqFeatureData;
      // localStorage.setItem(
      //   'seletedFeature',
      //   JSON.stringify(this.uniqFeatureData)
      // );
      this.ctrlRes = { features: this.uniqFeatureData };
      this.selectedFeatures = this.ctrlRes.features;
      this.mapService.parcel = '';
      this.mapService.parcels = [];
      for (let i = 0; i < this.selectedFeatures.length; i++) {
        if (!('is_annotation' in this.selectedFeatures[i])) {
          this.mapService.multiParcelId.push(this.selectedFeatures[i].id);
          // multiParcel
          this.mapService.multiParcel = false;
          // parcel ID
          this.mapService.parcels.push(this.selectedFeatures[i].id);
          this.mapService.parcel = [
            ...new Set(this.mapService.parcels),
          ].toString();
          // Feature Layer Id
          this.mapService.featureLayersId.push(
            this.selectedFeatures[i].layer_id
          );
          this.mapService.featureLayerId = [
            ...new Set(this.mapService.featureLayersId),
          ].toString();
        }
      }

      this.sidenavService.decideComponent(this.ctrlRes);
    } else {
      this.mapService.selectedParcels = [];
      this.sidenavService.activeFeatureList[index] = true;
      this.sidenavService.FeatureListsplice = true;
      this.sidenavService.setFeatureNameView = true;
      this.mapService.parcel = data.id;
      //to prevent zoom
      this.mapService.result.getSource().clear();
      this.sidenavService.isLoadedFeature('Featuresdsds');
      console.log(this.mapService.captureLayerData)
      console.log(data)
      if(this.mapService.captureLayerData) {
        if(data.layer_id == this.mapService.captureLayerData.id) {
          this.sidenavService.rightMenuClicked({
            component: EditFeatureCaptureComponent,
            data: data,
            history: false,
            push: true,
          });
        } else {
          this.sidenavService.rightMenuClicked({
            component: FeatureNameComponent,
            data: data,
            history: false,
            push: true,
          });
          this.captureToolService.clickedIndex = null
          this.mapService.captureLayerData = null
        }
      } else {
        this.sidenavService.rightMenuClicked({
          component: FeatureNameComponent,
          data: data,
          history: false,
          push: true,
        });
        this.captureToolService.clickedIndex = null
        this.mapService.captureLayerData = null
      }
    }
  }

  fire(e: any) {
    e.stopPropagation();
  }

  clickToZoom() {
    const backSource = this.mapService.result.getSource();
    this.mapService.mapLayer.getView().fit(backSource.getExtent());
  }

  goBack() {
    this.sidenavService.isLoadedFeature('Fesdsadature');
    if (this.mapService.result) {
      this.mapService.result.getSource().clear();
    }
    this.sidenavService.maintainHistory();
  }
  closeRightpanel() {
    this.sidenavService.panelSize(true);
    // localStorage.removeItem('seletedFeature');
    this.mapService.ParcelData = [];
    this.mapService.polygonGeometry = '';
    this.mapService.pointGeometry = '';
    this.mapService.innerBuffer = '';
    this.mapService.parcel = '';
    this.mapService.featureLayerId = '';
    this.sidenavService.activeFeatureList = [];
    if (this.mapService.result) {
      this.mapService.result.getSource().clear();
    }
    this.sidenavService.isLoadedFeature('Fesdsadature');
    this.sidenavService.closeRightSideNav();
    this.mapService.innerBuffer = []
    this.mapService.bufferPolygonGeometry = []
    this.mapService.hideRunButton = true;
    // this.sidenavService.sideNavState('close');
    // this.sidenavService.infoSideNav('closed');
  }

  viewAndExportData(): void {
    if (this.result.length !== 0) {
      this.dialog.open(ViewAndExportDataComponent, {
        disableClose: true,
        data: {
          features: this.result,
        },
      });
    }
  }

  hover(r: any): void {
    const f = r;
    const format = new WKT();
    this.feature1 = format.readFeature(f.ctrd_wkt);
    this.feature1.set('type', 'point');
    const style = new Style({
      image: new Icon({
        src: '/assets/img/marker_resize.png',
        imgSize: [80, 150],
        offset: [-20, -37],
        scale: 1.0,
        opacity: 1.0,
      }),
    });
    this.feature1.setStyle(style);
    this.mapService.stopHover = true;
    this.mapService.listResult.addFeatures([this.feature1]);

    this.feature = format.readFeature(f.geom_wkt);
    this.feature.set('type', 'polygon');
    const polystyle = new Style({
      stroke: new Stroke({
        color: this.featureSelecttion.single_feature_stroke,
        width: this.featureSelecttion.single_feature_stroke_width,
      }),
    });
    this.feature.setStyle(polystyle);
    // this.mapService.selectedParcels.push(this.feature);
    this.mapService.stopHover = true;
    this.mapService.listResult.addFeatures([this.feature]);
  }

  unHover(): void {
    this.feature.setStyle(new Style({}));
    this.feature1.setStyle(new Style({}));
  }

  showLabelSwitch(index: any, event: any): void {
    setTimeout(() => {
      if (!this.callNow) {
        if (index == 0 && event.target.id == 'result_label') {
          return;
        }
        if (index == 1 && event.target.id == 'result_label') {
          this.showSwitch = !this.showSwitch;
        } else if (index == 0 && event.target.id != 'result_label') {
          this.showSwitch = false;
        }
      } else {
        this.callNow = false;
      }
    }, 1);
  }

  hideShowLable(): void {
    this.mapService.selectedParcels = [];
    this.callNow = true;
    if (this.sidenavService.checkLabel) {
      this.showLabelOnList = true;
      const backSource = this.mapService.result.getSource();
      const features = this.mapService.listResult.getFeatures();
      for (let i = 0; i < features.length; i++) {
        if (features[i].get('buffer') == 'Buffer') {
          // console.log('buffer');
        } else {
          backSource.removeFeature(features[i]);
        }
      }
      this.sidenavService.saveFeatureListData = this.result;
      if (this.result.length) {
        this.resultLayer = this.result[0].layer_id;
      }
      if (this.result.length == 0) {
        this.sidenavService.isLoadedFeature('Featusadasre');
      }
      this.mapService.multipleFeature.push(this.result);
      let text: any = '1';
      for (let i = 0; i < this.result.length; i++) {
        const f = this.result[i];
        const format = new WKT();
        this.feature = format.readFeature(f.geom_wkt);
        const point = this.feature.getGeometry().getCoordinates();
        this.feature.set('type', 'polygon');
        this.feature.set('number', this.result[i].id);
        text = this.result[i].label;
        if (point.length == 2) {
          this.multistyle = new Style({
            image: new Icon({
              src: '/assets/img/marker3.png',
              imgSize: [80, 150],
              offset: [-35, -54],
              scale: 1.5,
              opacity: 1.0,
            }),
            text: new Text({
              font: 'arial',
              scale: 2,
              text: this.switchVisibleNow ? text?.toString() : '',
              overflow: true,
              fill: new Fill({
                color: '#000000',
              }),
              stroke: new Stroke({
                color: 'white',
                width: 2,
              }),
            }),
          });
        } else {
          this.multistyle = new Style({
            fill: new Fill({
              color: this.featureSelecttion.multi_feature_fill,
            }),
            stroke: new Stroke({
              color: this.featureSelecttion.multi_feature_stroke,
              width: this.featureSelecttion.multi_feature_stroke_width,
            }),
            text: new Text({
              font: 'arial',
              scale: 2,
              text: this.switchVisibleNow ? text?.toString() : '',
              overflow: true,
              fill: new Fill({
                color: '#000000',
              }),
              stroke: new Stroke({
                color: 'white',
                width: 2,
              }),
            }),
          });
        }
        this.feature.setStyle(this.multistyle);
        this.feature.set('dataObject', this.result[i]);
        // this.mapService.selectedParcels.push(this.feature);
        this.mapService.listResult.addFeatures([this.feature]);
      }
    } else {
      this.showLabelOnList = false;
      const backSource = this.mapService.result.getSource();
      const features = this.mapService.listResult.getFeatures();
      for (let i = 0; i < features.length; i++) {
        if (features[i].get('buffer') == 'Buffer') {
          //do nothing
        } else {
          backSource.removeFeature(features[i]);
        }
      }
      this.sidenavService.saveFeatureListData = this.result;
      if (this.result.length) {
        this.resultLayer = this.result[0].layer_id;
      }
      if (this.result.length == 0) {
        this.sidenavService.isLoadedFeature('Featusadasre');
      }
      this.mapService.multipleFeature.push(this.result);
      for (let i = 0; i < this.result.length; i++) {
        const f = this.result[i];
        const format = new WKT();
        this.feature = format.readFeature(f.geom_wkt);
        const point = this.feature.getGeometry().getCoordinates();
        this.feature.set('type', 'polygon');
        this.feature.set('number', this.result[i].id);
        if (point.length == 2) {
          this.multistyle = new Style({
            image: new Icon({
              src: '/assets/img/marker3.png',
              imgSize: [80, 150],
              offset: [-35, -54],
              scale: 1.5,
              opacity: 1.0,
            }),
          });
          this.multistyle = new Style({
            fill: new Fill({
              color: this.featureSelecttion.multi_feature_fill,
            }),
            stroke: new Stroke({
              color: this.featureSelecttion.multi_feature_stroke,
              width: this.featureSelecttion.multi_feature_stroke_width,
            }),
          });
        } else {
          this.multistyle = new Style({
            fill: new Fill({
              color: this.featureSelecttion.multi_feature_fill,
            }),
            stroke: new Stroke({
              color: this.featureSelecttion.multi_feature_stroke,
              width: this.featureSelecttion.multi_feature_stroke_width,
            }),
          });
        }
        // this.feature.setStyle(this.multistyle1);
        this.feature.setStyle(this.multistyle);
        this.feature.set('dataObject', this.result[i]);
        this.mapService.selectedParcels.push(this.feature);
        this.mapService.listResult.addFeatures([this.feature]);
      }
    }
  }
  ngOnDestroy(): void {
    this.sidenavService.saveFeatureListData = this.result;
    this.sidenavService.setFeatureNameView = true;
  }

  isEmptyObject(obj: any) {
    return obj && Object.keys(obj).length === 0;
  }
}
