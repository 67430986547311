import { SnackbarService } from './../../../../../services/snackbarService/snackbar.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { showFields } from 'src/app/modules/shared/models/showFields/showFields';
import { TabItem } from 'src/app/modules/shared/models/tab/tab.model';
import { AuthService } from 'src/app/services/auth.service';
import { ShowFieldsService } from 'src/app/services/show-fields/show-fields.service';
import { TabService } from 'src/app/services/tab/tab.service';
import { first } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-show-fields',
  templateUrl: './show-fields.component.html',
  styleUrls: ['./show-fields.component.css'],
})
export class ShowFieldsComponent implements OnInit {
  user: any;
  product: any;
  tabs: TabItem[] | undefined;
  layerName!: string;
  apMapFeed: any;
  //for drag drop in table
  @ViewChild('table') table: any;
  fieldCheckbox = true;
  //datasource for table
  dataSource: any;

  //data fetching from backend
  backendDummyData: showFields = new showFields();

  layerId!: number;

  //drop down for Type
  type: any = [
    { type: 'Date', value: 'Date' },
    { type: 'Date + Time', value: 'Date + Time' },
    { type: 'Decimal', value: 'Decimal' },
    { type: 'Hyperlink', value: 'Hyperlink' },
    { type: 'Integer', value: 'Integer' },
    { type: 'Text Line', value: 'Text Line' },
    { type: 'Text Block', value: 'Text Block' },
    { type: 'Formatted Text', value: 'Formatted Text' },
  ];

  //drop down for Visibility,Queryability,Editability,Auto Complete,Search,Capture
  accessLevel: any = [
    { type: 'Deleted', value: '-999' },
    { type: 'Public', value: '0' },
    { type: 'Client', value: '100' },
    { type: 'Reader', value: '200' },
    { type: 'Editor', value: '300' },
    { type: 'Admin', value: '400' },
    { type: 'Demigod', value: '500' },
    { type: 'Nobody', value: '999' },
  ];

  //mat table header
  displayedColumns: any = [
    'position',
    '+',
    'name',
    'dbColumn',
    'type',
    'unit',
    'visibility',
    'queryability',
    'editability',
    'autoComplete',
    'search',
    'toolTip',
    'capture',
    'dropdown',
  ];
  constructor(
    private authService: AuthService,
    public router: Router,
    public tabService: TabService,
    public showFieldService: ShowFieldsService,
    public _snackbar: SnackbarService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.showFieldService.layerdata = localStorage.getItem('layerData');
    if (this.showFieldService.layerdata) {
      this.product = JSON.parse(this.showFieldService.layerdata);
      this.backendDummyData.layer = this.product.id;
      this.apMapFeed = `/layers/${this.product.id}/apmapfeed`;
      this.layerId = this.product.id;
      this.layerName = this.product.name;
      this.spinner.show();
      this.showFieldService
        .getShowFieldsById(this.product.id)
        .pipe(first())
        .subscribe({
          next: (res: any) => {
            this.spinner.hide();
            this.dataSource = res;
            for (let i = 0; i < this.dataSource?.length; i++) {
              this.dataSource[i].map_order = i;
            }
            for (let i = 0; i < this.dataSource?.length; i++) {
              this.dataSource[i].visible = true;
            }
          },
          error: () => {
            this.spinner.hide();
            this._snackbar.openSnackBar('Something went wrong');
          },
        });
    }
  }
  dropTable(event: CdkDragDrop<any[]>) {
    const prevIndex = this.dataSource.findIndex(
      (d: any) => d === event.item.data
    );
    moveItemInArray(this.dataSource, prevIndex, event.currentIndex);
    for (let i = 0; i < this.dataSource?.length; i++) {
      this.dataSource[i].map_order = i;
    }
    this.table.renderRows();
  }

  submitTable(): void {
    for (let i = 0; i < this.dataSource?.length; i++) {
      this.dataSource[i].layer = this.product.id;
    }
    const body = {
      data: this.dataSource,
    };
    this.spinner.show();
    this.showFieldService
      .updateAddField(body)
      .pipe(first())
      .subscribe({
        next: () => {
          this.spinner.hide();
          this._snackbar.openSnackBar('update Successfully');
        },
        error: () => {
          this.spinner.hide();
          this._snackbar.openSnackBar('Something went wrong');
        },
      });
  }

  updateAllComplete() {
    // console.log('update +');
  }
  onChange(value: any, fieldsData: any) {
    delete fieldsData.visible;
    if (value == false) {
      this.showFieldService
        .deleteAddField(fieldsData.id)
        .pipe(first())
        .subscribe({
          next: () => {
            this.ngOnInit();
            this._snackbar.openSnackBar('Delete Successfully');
          },
          error: () => {
            this._snackbar.openSnackBar('Something went wrong');
          },
        });
    }
  }

  logout() {
    this.authService.logout();
    window.location.href = `${environment.operatorDashboardUrl}/login/`;
  }

  backToLayer() {
    this.router.navigate([`/layers/${this.layerId}`]);
  }
}
