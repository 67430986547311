<div
  id="dashboard-modal"
  class="fade-scale in"
  style="background-color: rgba(0, 0, 0, 0.1); display: block"
>
  <div
    class="modal-dialog"
    style="margin-top: 5%; display: table; width: auto; margin: 0 !important"
  >
    <div
      class="modal-content"
      style="
        border: solid 1px #aaaaaa;
        padding: 10px;
        height: auto;
        width: 650px;
      "
    >
      <div class="modal-body" [innerHtml]="html | sanitizeHtml"></div>

      <div class="div">
        <span
          ><input
            (change)="onChange($event)"
            type="checkbox"
            id="dont-show"
            *ngIf="dismiss && !browser_version"
          />{{ dismiss_message }}</span
        >
        <button
          id="dont-show-btn"
          type="button"
          class="btn gernal-btn"
          data-dismiss="modal"
          (click)="dismissUsers(id)"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>
