import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssessorParcelsService {
  reportsList: any [] = [];
  noPaymentMethod:any;
  parcelPropertyId: any;
  constructor(private http: HttpClient,) { }

  getMapFeed(layerId: any, mapId: any, shareId: any) {
    return this.http.get(`/document/active-map-feed/?layer_id=${parseInt(layerId)}&map_id=${parseInt(mapId)}&share_id=${shareId.toString()}`, {

    });
  }
  getAssessorParcel(layerId: any, apn: any) {
    return this.http.get('/document/assessor-parcel-map/', {
      params: {
        layer_id: parseInt(layerId),
        apn: apn.toString(),
      },
    });
  }
  getAllReportsList() {
    return this.http.get('/document/get-reports-list/', {});
  }
  getUserReports() {
    return this.http.get('/document/get-downloaded-reports/', {});
  }
  getParcelsReortsStatus(featureId: any, layerId: any, reports: any, mapId: any):Observable<any> {
    return this.http.get<any>(`/document/get-reports-status/?feature_id=${parseInt(featureId)}&layer_id=${parseInt(layerId)}&reports=${reports}&map_id=${parseInt(mapId)}`, {

    });
  }
  getParcelsReorts(featureId: any, layerId: any, reports: any, mapId: any) {
    return this.http.get(`/document/get-reports/?feature_id=${parseInt(featureId)}&layer_id=${parseInt(layerId)}&reports=${reports}&map_id=${parseInt(mapId)}`, {

    });
  }
  getAllReports(body: any): Observable<any> {
    return this.http.post('/document/reports-payment/', body, {})

  }



}
