import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Circle as CircleStyle, Stroke, Style } from 'ol/style';
import { easeOut } from 'ol/easing';
import { getVectorContext } from 'ol/render';
import { unByKey } from 'ol/Observable';
export function addLayerData(map: any, baseMap: any, mapService: any) {
  const duration = 3000;

  mapService.lightningSource = new VectorSource({
    wrapX: false,
  });
  const vector = new VectorLayer({
    source: mapService.lightningSource,
  });
  map.addLayer(vector);

  function flash(feature: any) {
    const start = Date.now();
    const flashGeom = feature.getGeometry().clone();
    const listenerKey = baseMap.on('postrender', animate);

    function animate(event: any) {
      const frameState = event.frameState;
      const elapsed = frameState.time - start;
      if (elapsed >= duration) {
        unByKey(listenerKey);
        return;
      }
      const vectorContext = getVectorContext(event);
      const elapsedRatio = elapsed / duration;
      // radius will be 5 at start and 30 at end.
      const radius = easeOut(elapsedRatio) * 25 + 5;
      const opacity = easeOut(1 - elapsedRatio);

      const style = new Style({
        image: new CircleStyle({
          radius: radius,
          stroke: new Stroke({
            color: 'rgba(255, 0, 0, ' + opacity + ')',
            width: 0.25 + opacity,
          }),
        }),
      });

      vectorContext.setStyle(style);
      vectorContext.drawGeometry(flashGeom);
      // tell OpenLayers to continue postrender animation
      map.render();
    }
  }
  mapService.lightningSource.on('addfeature', function (e: any) {
    flash(e.feature);
  });

}