import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService) { 
    //
  }

  ngOnInit(): void {
    //
  }

  toggleSpinner(value:any){
    if(value){
      this.spinner.show();
    }else{
      this.spinner.hide();
    }
  
    
  }

}
