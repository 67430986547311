import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LabelsComponent } from './main-global-modification/layers/labels/labels.component';
import { LayersComponent } from './main-global-modification/layers/layers.component';
import { PdfTableComponent } from './main-global-modification/layers/pdf-table/pdf-table.component';
import { AddFieldsComponent } from './main-global-modification/layers/show-fields/add-fields/add-fields.component';
import { ShowFieldsComponent } from './main-global-modification/layers/show-fields/show-fields.component';
import { MainGlobalModificationComponent } from './main-global-modification/main-global-modification.component';
import { MyAccountComponent } from './main-global-modification/my-account/my-account.component';
import { AllUsersComponent } from './main-global-modification/all-users/all-users.component';
import { MyMapsComponent } from './main-global-modification/my-maps/my-maps.component';
import { ModelsComponent } from './main-global-modification/models/models.component';
import { CustomSeatsComponent } from './main-global-modification/custom-seats/custom-seats.component';
import { CreateTableComponent } from './main-global-modification/create-table/create-table.component';
import { SecurityControlsComponent } from './main-global-modification/security-controls/security-controls.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { ApMapFeedComponent } from './main-global-modification/layers/ap-map-feed/ap-map-feed.component';
import { RecurlyPlansComponent } from './main-global-modification/recurly-plans/recurly-plans.component';
import { ProductsComponent } from './main-global-modification/recurly-plans/products/products.component';
import { AllPlansComponent } from './main-global-modification/recurly-plans/all-plans/all-plans.component';
import { SubscribePagesComponent } from './main-global-modification/subscribe-pages/subscribe-pages.component';
import { PagesComponent } from './main-global-modification/subscribe-pages/pages/pages.component';
import { NewPageComponent } from './main-global-modification/subscribe-pages/new-page/new-page.component';
import { EditPageComponent } from './main-global-modification/subscribe-pages/edit-page/edit-page.component';
import { CourtesyAccountComponent } from './main-global-modification/courtesy-account/courtesy-account.component';
import { CourtesyMapsComponent } from './main-global-modification/courtesy-account/courtesy-maps/courtesy-maps.component';
import { CourtesyAddOnsComponent } from './main-global-modification/courtesy-account/courtesy-add-ons/courtesy-add-ons.component';
const routes: Routes = [
  // Global Modification routes goes here here
  {
    path: 'global',
    pathMatch: 'full',
    component: MainGlobalModificationComponent,
  },
  { path: 'layers', component: LayersComponent },
  { path: 'layers/:id', component: LayersComponent },
  { path: 'showFields', component: ShowFieldsComponent },
  { path: 'addFields', component: AddFieldsComponent },
  { path: 'labels', component: LabelsComponent },
  { path: 'pdfTable', component: PdfTableComponent },
  { path: 'layers/:id/apmapfeed', component: ApMapFeedComponent },

  { path: 'securityControl', component: SecurityControlsComponent },
  { path: 'plans', component: RecurlyPlansComponent },
  { path: 'subscribePages', component: SubscribePagesComponent },
  { path: 'page', component: NewPageComponent },
  { path: 'edit-page/:id', component: EditPageComponent },
  { path: 'courtesyAccount', component: CourtesyAccountComponent },
];

@NgModule({
  declarations: [
    MyAccountComponent,
    AllUsersComponent,
    MyMapsComponent,
    ModelsComponent,
    CustomSeatsComponent,
    CreateTableComponent,
    SecurityControlsComponent,
    RecurlyPlansComponent,
    ProductsComponent,
    AllPlansComponent,
    SubscribePagesComponent,
    PagesComponent,
    NewPageComponent,
    EditPageComponent,
    CourtesyAccountComponent,
    CourtesyMapsComponent,
    CourtesyAddOnsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    SharedModule,
    MatTabsModule,
  ],
})
export class GlobalModificationModule {}
