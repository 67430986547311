import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-share-expired-modal-custom',
  templateUrl: './live-share-expired-modal-custom.component.html',
  styleUrls: ['./live-share-expired-modal-custom.component.css']
})
export class LiveShareExpiredModalCustomComponent implements OnInit {

  constructor() { 
    ///
  }

  ngOnInit(): void {
    //
  }

}
