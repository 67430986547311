import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapServiceService } from 'src/app/modules/map/services/Map-service/map-service.service';

@Component({
  selector: 'app-live-share-modal',
  templateUrl: './live-share-modal.component.html',
  styleUrls: ['./live-share-modal.component.css'],
})
export class LiveShareModalComponent implements OnInit {
  expiryTime: any;
  hours_message: any;
  minute_message: any;
  hourss: any;
  dataDemo?: any;
  htmlCheckbox: any
  isReadOnly = true
  expiryValue = false;
  showCheckobox = true;
  liveShareBody = {}
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private mapService: MapServiceService
  ) {
    setTimeout(() => {
      this.liveShareBody = this.mapService.getAllModals.live_share_info
    }, 500);
  }

  ngOnInit(): void {
    this.expiryTime = this.data.title;
    this.htmlCheckbox = this.data.htmlCheckbox
    if(this.htmlCheckbox) {
      this.isReadOnly = true;
      this.showCheckobox = false
    } else {
      this.isReadOnly = false;
    }
    // Set the date we're counting down to
    const countDownDate = new Date(this.expiryTime).getTime();

    // Update the count down every 1 second
    const x = setInterval(() => {
      // Get todays date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      if (days >= 2) {
        this.dataDemo = document.getElementById('demo');
        this.dataDemo.innerHTML = days + ' days ' + hours + ' hours';
      } else if (days < 2) {
        this.hourss = hours + days * 24;

        if (this.hourss < 2) {
          this.hours_message = ' hour';
        } else {
          this.hours_message = ' hours';
        }
        if (minutes < 2) {
          this.minute_message = ' minute';
        } else {
          this.minute_message = ' minutes';
        }
        this.dataDemo = document.getElementById('demo');
        if(this.dataDemo) {
          this.dataDemo.innerHTML =
          this.hourss +
          this.hours_message +
          ' and ' +
          minutes +
          this.minute_message;
        }

      }

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x);
        this.dataDemo = document.getElementById('demo');
        this.dataDemo.innerHTML = 'EXPIRED';
      }
    }, 1000);
  }

  term(e: any): void {
    if (e.target.checked) {
      this.isReadOnly = false;
      this.expiryValue = true;
    } else {
      this.isReadOnly = true;
    }
  }
  close(): void {
    this.dialog.closeAll();
  }
}
