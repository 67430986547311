import { SnackbarService } from './../../../../services/snackbarService/snackbar.service';
import { LayerService } from './../../../../services/layer/layer.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { filter, tap, first } from 'rxjs/operators';
import { TabService } from 'src/app/services/tab/tab.service';
import { createLayer } from '../../models/new-layer/newLayer.model';
import { CloneLayerComponent } from 'src/app/modules/global-modification/modals/clone-layer/clone-layer.component';

@Component({
  selector: 'app-layers-button',
  templateUrl: './layers-button.component.html',
  styleUrls: ['./layers-button.component.css'],
})
export class LayersButtonComponent implements OnInit {
  @Input()
  courses: createLayer[] = [];

  @Output()
  private layerChanged = new EventEmitter();
  constructor(
    public router: Router,
    private dialog: MatDialog,
    public _snackService: SnackbarService,
    public tabService: TabService,
    public LayerService: LayerService
  ) {}

  ngOnInit(): void {
    //
  }

  btnClick() {
    localStorage.setItem('setEdit', '0');
    localStorage.removeItem('layerData');
    this.tabService.hideTab = true;
  }

  btnClone() {
    if (this.LayerService.layerData.length == 1) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '600px';
      dialogConfig.data = this.LayerService.layerData[0];
      const dialogRef = this.dialog.open(CloneLayerComponent, dialogConfig);
      dialogRef
        .afterClosed()
        .pipe(
          filter((val) => !!val),
          tap(() => this.layerChanged.emit())
        )
        .subscribe();
    } else {
      this._snackService.openSnackBar('Please select one layer to copy.');
    }
  }

  btnDelete() {
    this.LayerService.deleteLayer(this.LayerService.layerIds)
      .pipe(first())
      .subscribe({
        next: () => {
          this.LayerService.layerData = [];
          this.LayerService.refreshLayer('res');
          this._snackService.openSnackBar('Layer delete successfully');
          this.LayerService.layerIds = [];
        },
        error: () => {
          this._snackService.openSnackBar('Please select layers to delete.');
          this.LayerService.layerIds = [];
        },
      });
  }
}
