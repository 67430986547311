import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs/public-api';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TabItem } from 'src/app/modules/shared/models/tab/tab.model';
import { SubscribeService } from 'src/app/services/subscribe/subscribe.service';
import { TabService } from 'src/app/services/tab/tab.service';
import { MatDialog } from '@angular/material/dialog';
import { SubscribErrorModalComponent } from 'src/app/modules/shared/components/modals/subscrib-error-modal/subscrib-error-modal.component';

@Component({
  selector: 'app-new-page',
  templateUrl: './new-page.component.html',
  styleUrls: ['./new-page.component.css'],
})
export class NewPageComponent implements OnInit, OnDestroy {
  showLayersButton = false;
  hideTab: any = false;
  tabs: TabItem[] | undefined;
  Maintab: TabItem[] | undefined;
  selectedTab = 0;
  addsOn = false;
  outreach = false;
  isSubmitted = false;
  isVisible = false;
  constructor(
    public tabService: TabService,
    public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private service: SubscribeService,
    private route: Router,
    private spinner: NgxSpinnerService
  ) {}

  createPage = this.fb.group({
    name: ['', [Validators.required]],
    slug: ['', Validators.required],
    logo: ['', Validators.required],
    header_title: ['', Validators.required],
    product_type: ['', Validators.required],
    body_title: ['', Validators.required],
    body_content: ['', Validators.required],
    map_text: ['', Validators.required],
    monthly_text: ['', Validators.required],
    annual_text: ['', Validators.required],
    day_text: ['', Validators.required],
    map_subtext: ['', Validators.required],
    monthly_subtext: ['', Validators.required],
    annual_subtext: ['', Validators.required],
    day_subtext: ['', Validators.required],
    collab_seats: false,
    collab_seat_title: [{ value: '', disabled: true }],
    collab_seat_content: [{ value: '', disabled: true }],
    collab_monthly_text: [{ value: '', disabled: true }],
    collab_annual_text: [{ value: '', disabled: true }],
    outreach: false,
    outreach_title: [{ value: '', disabled: true }],
    outreach_content: [{ value: '', disabled: true }],
    outreach_monthly_text: [{ value: '', disabled: true }],
    outreach_annual_text: [{ value: '', disabled: true }],
    footer: ['', Validators.required],
  });

  ngOnInit(): void {
    // Tab State Handling
    const state = this.tabService.getState();
    // Set to 0 if tabState not present
    this.selectedTab = state.tabState || 0;

    if (state.tabState === 1) {
      this.showLayersButton = true;
    } else {
      this.showLayersButton = false;
    }
    // Show State
    this.tabService.activeTab$.subscribe(() => {
      // console.log('active tab set: ', tab);
    });
    this.Maintab = this.tabService.getTabs();
    this.tabService.activeTab$.next(this.Maintab[8]);
  }
  tabSelected(tab: TabItem) {
    this.tabService.activeTab$.next(tab);
    this.tabService.hideTab = true;
  }

  setTabState(tabId: number): void {
    this.selectedTab = tabId;
    this.tabService.setState({ tabState: tabId });
    if (tabId === 1) {
      this.showLayersButton = true;
    } else {
      this.showLayersButton = false;
    }
  }
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    if (tabChangeEvent.index == 6) {
      this.router.navigateByUrl('global');
    } else if (tabChangeEvent.index == 7) {
      this.router.navigateByUrl('securityControl');
    }
  };
  ngOnDestroy() {
    this.tabService.setState({ tabState: this.selectedTab });
  }
  addsOnsChange(event: Event) {
    this.addsOn = (<HTMLInputElement>event.target).checked;
    if (this.addsOn) {
      this.createPage.controls['collab_seat_title'].enable();
      this.createPage.controls['collab_seat_content'].enable();
      this.createPage.controls['collab_monthly_text'].enable();
      this.createPage.controls['collab_annual_text'].enable();
    } else {
      this.createPage.controls['collab_seat_title'].disable();
      this.createPage.controls['collab_seat_content'].disable();
      this.createPage.controls['collab_monthly_text'].disable();
      this.createPage.controls['collab_annual_text'].disable();
    }
  }
  outreachChange(event: Event) {
    this.outreach = (<HTMLInputElement>event.target).checked;
    if (this.outreach) {
      this.createPage.controls['outreach_title'].enable();
      this.createPage.controls['outreach_content'].enable();
      this.createPage.controls['outreach_monthly_text'].enable();
      this.createPage.controls['outreach_annual_text'].enable();
    } else {
      this.createPage.controls['outreach_title'].disable();
      this.createPage.controls['outreach_content'].disable();
      this.createPage.controls['outreach_monthly_text'].disable();
      this.createPage.controls['outreach_annual_text'].disable();
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.createPage.invalid) {
      this.dialog.open(SubscribErrorModalComponent, {
        data: { title: 'Error', content: 'Fill the Required Input fields' },
      });
      return;
    }
    const body = {
      name: this.createPage.value.name,
      slug: this.createPage.value.slug,
      logo: this.createPage.value.logo,
      header_title: this.createPage.value.header_title,
      product_type: this.createPage.value.product_type,
      body_title: this.createPage.value.body_title,
      body_content: this.createPage.value.body_content,
      map_text: this.createPage.value.map_text,
      monthly_text: this.createPage.value.monthly_text,
      annual_text: this.createPage.value.annual_text,
      day_text: this.createPage.value.day_text,
      map_subtext: this.createPage.value.map_subtext,
      monthly_subtext: this.createPage.value.monthly_subtext,
      annual_subtext: this.createPage.value.annual_subtext,
      day_subtext: this.createPage.value.day_subtext,
      collab_seats: this.addsOn,
      collab_seat_title:
        this.createPage.value.collab_seat_title === undefined
          ? ''
          : this.createPage.value.collab_seat_title,
      collab_seat_content:
        this.createPage.value.collab_seat_content === undefined
          ? ''
          : this.createPage.value.collab_seat_content,
      collab_monthly_text:
        this.createPage.value.collab_monthly_text === undefined
          ? ''
          : this.createPage.value.collab_monthly_text,
      collab_annual_text:
        this.createPage.value.collab_annual_text === undefined
          ? ''
          : this.createPage.value.collab_annual_text,
      outreach: this.outreach,
      outreach_title:
        this.createPage.value.outreach_title === undefined
          ? ''
          : this.createPage.value.outreach_title,
      outreach_content:
        this.createPage.value.outreach_content === undefined
          ? ''
          : this.createPage.value.outreach_content,
      outreach_monthly_text:
        this.createPage.value.outreach_monthly_text === undefined
          ? ''
          : this.createPage.value.outreach_monthly_text,
      outreach_annual_text:
        this.createPage.value.outreach_annual_text === undefined
          ? ''
          : this.createPage.value.outreach_annual_text,
      footer: this.createPage.value.footer,
    };
    this.spinner.show();
    this.service.createPage(body).subscribe(() => {
      this.route.navigate(['/subscribePages']);
      this.spinner.hide();
    });
  }
}
