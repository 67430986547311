export class pdfTabel {
  id?: number
  map_id_tabel?: any ;
  apn?: number;
  layer_id_tabel?: number;
  owner_name?: string;
  address1?: string;
  address2?: string;
  visibility?: boolean
}
