import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs/public-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubscribErrorModalComponent } from 'src/app/modules/shared/components/modals/subscrib-error-modal/subscrib-error-modal.component';
import { createSubscribe } from 'src/app/modules/shared/models/subscribe/createSubscribe.model';
import { TabItem } from 'src/app/modules/shared/models/tab/tab.model';
import { SubscribeService } from 'src/app/services/subscribe/subscribe.service';
import { TabService } from 'src/app/services/tab/tab.service';

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.css'],
})
export class EditPageComponent implements OnInit, OnDestroy {
  showLayersButton = false;
  hideTab: any = false;
  tabs: TabItem[] | undefined;
  Maintab: TabItem[] | undefined;
  selectedTab = 0;
  getPage = false;
  addsOn = false;
  outreach = false;
  createPage!: FormGroup;
  activePage!: number;
  isSubmitted = false;
  isVisible = false;
  subscribePage: createSubscribe = new createSubscribe();
  constructor(
    public tabService: TabService,
    public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private service: SubscribeService,
    private _activatedRoute: ActivatedRoute,
    private route: Router,
    private spinner: NgxSpinnerService
  ) {
    this._activatedRoute.params.subscribe((params) => {
      this.activePage = params.id;
    });
    this.spinner.show();
    this.service.getPage(this.activePage).subscribe((res) => {
      this.subscribePage = res;
      this.getPage = true;
      this.addsOn = this.subscribePage.collab_seats;
      this.outreach = this.subscribePage.outreach;
      this.createDataPage();
      this.setAddsOnAndOutreach();
      this.spinner.hide();
    });
  }
  createDataPage() {
    this.createPage = this.fb.group({
      name: [this.subscribePage.name, [Validators.required]],
      slug: [this.subscribePage.slug, [Validators.required]],
      logo: [this.subscribePage.logo, [Validators.required]],
      header_title: [this.subscribePage.header_title, [Validators.required]],
      product_type: [this.subscribePage.product_type, [Validators.required]],
      body_title: [this.subscribePage.body_title, [Validators.required]],
      body_content: [this.subscribePage.body_content, [Validators.required]],
      map_text: [this.subscribePage.map_text, [Validators.required]],
      monthly_text: [this.subscribePage.monthly_text, [Validators.required]],
      annual_text: [this.subscribePage.annual_text, [Validators.required]],
      day_text: [this.subscribePage.day_text, [Validators.required]],
      map_subtext: [this.subscribePage.map_subtext, [Validators.required]],
      monthly_subtext: [
        this.subscribePage.monthly_subtext,
        [Validators.required],
      ],
      annual_subtext: [
        this.subscribePage.annual_subtext,
        [Validators.required],
      ],
      day_subtext: [this.subscribePage.day_subtext, [Validators.required]],
      collab_seats: this.subscribePage.collab_seats,
      collab_seat_title: [
        { value: this.subscribePage.collab_seat_title, disabled: true },
      ],
      collab_seat_content: [
        { value: this.subscribePage.collab_seat_content, disabled: true },
      ],
      collab_monthly_text: [
        { value: this.subscribePage.collab_monthly_text, disabled: true },
      ],
      collab_annual_text: [
        { value: this.subscribePage.collab_annual_text, disabled: true },
      ],
      outreach: this.subscribePage.outreach,
      outreach_title: [
        { value: this.subscribePage.outreach_title, disabled: true },
      ],
      outreach_content: [
        { value: this.subscribePage.outreach_content, disabled: true },
      ],
      outreach_monthly_text: [
        { value: this.subscribePage.outreach_monthly_text, disabled: true },
      ],
      outreach_annual_text: [
        { value: this.subscribePage.outreach_annual_text, disabled: true },
      ],
      footer: [this.subscribePage.footer, [Validators.required]],
    });
  }
  setAddsOnAndOutreach() {
    if (this.addsOn) {
      this.createPage.controls['collab_seat_title'].enable();
      this.createPage.controls['collab_seat_content'].enable();
      this.createPage.controls['collab_monthly_text'].enable();
      this.createPage.controls['collab_annual_text'].enable();
    } else {
      this.createPage.controls['collab_seat_title'].disable();
      this.createPage.controls['collab_seat_content'].disable();
      this.createPage.controls['collab_monthly_text'].disable();
      this.createPage.controls['collab_annual_text'].disable();
    }
    if (this.outreach) {
      this.createPage.controls['outreach_title'].enable();
      this.createPage.controls['outreach_content'].enable();
      this.createPage.controls['outreach_monthly_text'].enable();
      this.createPage.controls['outreach_annual_text'].enable();
    } else {
      this.createPage.controls['outreach_title'].disable();
      this.createPage.controls['outreach_content'].disable();
      this.createPage.controls['outreach_monthly_text'].disable();
      this.createPage.controls['outreach_annual_text'].disable();
    }
  }
  ngOnInit(): void {
    // Tab State Handling
    const state = this.tabService.getState();
    // Set to 0 if tabState not present
    this.selectedTab = state.tabState || 0;

    if (state.tabState === 1) {
      this.showLayersButton = true;
    } else {
      this.showLayersButton = false;
    }
    // Show State
    this.tabService.activeTab$.subscribe(() => {
      // console.log('active tab set: ', tab);
    });
    this.Maintab = this.tabService.getTabs();
    this.tabService.activeTab$.next(this.Maintab[8]);
  }
  tabSelected(tab: TabItem) {
    this.tabService.activeTab$.next(tab);
    this.tabService.hideTab = true;
  }

  setTabState(tabId: number): void {
    this.selectedTab = tabId;
    this.tabService.setState({ tabState: tabId });
    if (tabId === 1) {
      this.showLayersButton = true;
    } else {
      this.showLayersButton = false;
    }
  }
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    if (tabChangeEvent.index == 6) {
      this.router.navigateByUrl('global');
    } else if (tabChangeEvent.index == 7) {
      this.router.navigateByUrl('securityControl');
    }
  };
  ngOnDestroy() {
    this.tabService.setState({ tabState: this.selectedTab });
  }
  addsOnsChange(event: Event) {
    this.addsOn = (<HTMLInputElement>event.target).checked;
    if (this.addsOn) {
      this.createPage.controls['collab_seat_title'].enable();
      this.createPage.controls['collab_seat_content'].enable();
      this.createPage.controls['collab_monthly_text'].enable();
      this.createPage.controls['collab_annual_text'].enable();
    } else {
      this.createPage.controls['collab_seat_title'].disable();
      this.createPage.controls['collab_seat_content'].disable();
      this.createPage.controls['collab_monthly_text'].disable();
      this.createPage.controls['collab_annual_text'].disable();
    }
  }
  outreachChange(event: Event) {
    this.outreach = (<HTMLInputElement>event.target).checked;
    if (this.outreach) {
      this.createPage.controls['outreach_title'].enable();
      this.createPage.controls['outreach_content'].enable();
      this.createPage.controls['outreach_monthly_text'].enable();
      this.createPage.controls['outreach_annual_text'].enable();
    } else {
      this.createPage.controls['outreach_title'].disable();
      this.createPage.controls['outreach_content'].disable();
      this.createPage.controls['outreach_monthly_text'].disable();
      this.createPage.controls['outreach_annual_text'].disable();
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.createPage.invalid) {
      this.dialog.open(SubscribErrorModalComponent, {
        data: { title: 'Error', content: 'Fill the Required Input fields' },
      });
      return;
    }
    const body = {
      name: this.createPage.value.name,
      slug: this.createPage.value.slug,
      logo: this.createPage.value.logo,
      header_title: this.createPage.value.header_title,
      product_type: this.createPage.value.product_type,
      body_title: this.createPage.value.body_title,
      body_content: this.createPage.value.body_content,
      map_text: this.createPage.value.map_text,
      monthly_text: this.createPage.value.monthly_text,
      annual_text: this.createPage.value.annual_text,
      day_text: this.createPage.value.day_text,
      map_subtext: this.createPage.value.map_subtext,
      monthly_subtext: this.createPage.value.monthly_subtext,
      annual_subtext: this.createPage.value.annual_subtext,
      day_subtext: this.createPage.value.day_subtext,
      collab_seats: this.addsOn,
      collab_seat_title:
        this.createPage.value.collab_seat_title === undefined
          ? ''
          : this.createPage.value.collab_seat_title,
      collab_seat_content:
        this.createPage.value.collab_seat_content === undefined
          ? ''
          : this.createPage.value.collab_seat_content,
      collab_monthly_text:
        this.createPage.value.collab_monthly_text === undefined
          ? ''
          : this.createPage.value.collab_monthly_text,
      collab_annual_text:
        this.createPage.value.collab_annual_text === undefined
          ? ''
          : this.createPage.value.collab_annual_text,
      outreach: this.outreach,
      outreach_title:
        this.createPage.value.outreach_title === undefined
          ? ''
          : this.createPage.value.outreach_title,
      outreach_content:
        this.createPage.value.outreach_content === undefined
          ? ''
          : this.createPage.value.outreach_content,
      outreach_monthly_text:
        this.createPage.value.outreach_monthly_text === undefined
          ? ''
          : this.createPage.value.outreach_monthly_text,
      outreach_annual_text:
        this.createPage.value.outreach_annual_text === undefined
          ? ''
          : this.createPage.value.outreach_annual_text,
      footer: this.createPage.value.footer,
    };
    this.spinner.show();
    this.service.updatePage(this.activePage, body).subscribe(() => {
      this.route.navigate(['/subscribePages']);
      this.spinner.hide();
    });
  }
}
