// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://dev.mapport.com/api',
  recaptcha: {
    siteKey: '6LfIaeUhAAAAAKyTH9mngNitqkvuY3BdKjURwHD0',
  },
  recurly: {
    recurlyKey: 'ewr1-QggekpCdO9I4zdY1yojb5S',
  },
  opLink: 'https://opdev.mapport.com/login_from_trans',
  operatorDashboardUrl: 'https://opdev.mapport.com',
  newFutureModal: 'dev.mapport.com/login',
  originUrl: 'https://dev.mapport.com',
  invoiceUrl: 'https://oneclout.recurly.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
