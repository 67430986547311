<div class="table-responsive">
  <table class="table table-responsive table-borderless mt-5">
    <thead>
      <tr class="bg-light">
        <th scope="col" width="5%">
          <input [(ngModel)]="selectAllCheck" (change)="selectAll()" type="checkbox" />
        </th>
        <th scope="col" width="60%">Page Name</th>
        <th scope="col" width="40%" style="text-align: center">
          <a
            type="submit"
            class="btn btn-primary"
            style="margin-left: 10px"
            routerLink="/page"
          >
            New Page
          </a>
          <button
            type="submit"
            class="btn btn-danger"
            style="margin-left: 10px"
            (click)="deletePages()"
          >
            Delete Page
          </button>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="dataLoaded">
      <tr *ngFor="let page of pagesList; let i = index">
        <th scope="row"><input [(ngModel)]="selectedItems[i].isSelected" (change)="checkBox($event, page.id)" type="checkbox" /></th>
        <td style="text-align: start">{{page.name}}</td>
        <td>
          <a type="submit" class="btn btn-default" style="margin-left: 10px">
            Django Admin
          </a>
          <button
            type="submit"
            class="btn btn-primary"
            style="margin-left: 10px"
            routerLink="/edit-page/{{page.id}}"
          >
            Edit Page
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ngx-spinner [fullScreen]="true" type="" size="medium" bdColor="rgba(0, 0, 0, 0)">
  <p class="loading" > <img src="/assets/img/wheel.svg" alt="Girl in a jacket" width="100px" height="100px" > </p>
  </ngx-spinner>
