import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { labelData } from 'src/app/modules/shared/models/labels/lable';
import { LayerService } from 'src/app/services/layer/layer.service';
import { SnackbarService } from 'src/app/services/snackbarService/snackbar.service';
import { ApMapFeed } from 'src/app/modules/shared/models/map-feed/mapFeed';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-ap-map-feed',
  templateUrl: './ap-map-feed.component.html',
  styleUrls: ['./ap-map-feed.component.css'],
})
export class ApMapFeedComponent implements OnInit {
  layerName!: string;
  labelData: labelData = new labelData();
  ApMapFeed: ApMapFeed = new ApMapFeed();
  product: any;
  editTable = false;
  on = true;
  off = false;
  layerId!: number;
  feedFields: any;
  selectedFeed: any;
  constructor(
    public layerService: LayerService,
    public _snackbar: SnackbarService,
    public router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.layerService.layerdata = localStorage.getItem('layerData');
    if (this.layerService.layerdata) {
      this.product = JSON.parse(this.layerService.layerdata);
      this.labelData.layer_id_labels = this.product.id;
      this.layerName = this.product.name;
      this.layerId = this.product.id;
      this.labelData.map_id_labels = null;
    }
    this.spinner.show();
    this.layerService
      .getApnFields(this.product.id)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          this.feedFields = res.fields;
          if (res.ap_map_feed.length > 0) {
            // this.ApMapFeed.county = res.ap_map_feed[0].county
            this.ApMapFeed.table = res.ap_map_feed[0].table;
          }
          this.spinner.hide();
        },
        error: () => {
          this._snackbar.openSnackBar('Something went wrong');
          this.spinner.hide();
        },
      });
  }
  selectApnField(e: any) {
    this.ApMapFeed.table = e.target.value;
  }

  submitForm() {
    this.ApMapFeed.layer = this.product.id;
    this.spinner.show();
    if (!this.ApMapFeed.table) {
      this._snackbar.openSnackBar('Please Select APN Fields');
      this.spinner.hide();
    } else {
      this.layerService
        .addMapFeed(this.product.id, this.ApMapFeed)
        .pipe(first())
        .subscribe({
          next: (res: any) => {
            // this.ApMapFeed.county = res.county
            this.ApMapFeed.table = res.table;
            this._snackbar.openSnackBar('Save successfully!');
            this.spinner.hide();
          },
          error: () => {
            this._snackbar.openSnackBar('Something went wrong');
            this.spinner.hide();
          },
        });
    }
  }
  backToLayer() {
    this.router.navigate([`/layers/${this.layerId}`]);
  }
}
