import { SnackbarService } from './../../../../services/snackbarService/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { createLayer } from 'src/app/modules/shared/models/new-layer/newLayer.model';
import { LayerService } from 'src/app/services/layer/layer.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { finalize, first, tap } from 'rxjs/operators';
import { getMapStyle } from 'src/app/test_data/mapStyle';
import { MatDialog } from '@angular/material/dialog';
import { SubscribErrorModalComponent } from 'src/app/modules/shared/components/modals/subscrib-error-modal/subscrib-error-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-layers',
  templateUrl: './layers.component.html',
  styleUrls: ['./layers.component.css'],
})
export class LayersComponent implements OnInit {
  color: any = 'primary';
  product: createLayer = new createLayer();
  layerId!: number;
  layerName!: string;
  apMapFeed: any;
  mapStyle: any;
  baseMap: any;
  schemaData: any = [];
  layerSchemaInfo: any = [];
  showTable = false;
  showOtherOption = false;
  editLayer = false;
  disableButton = false;
  layerStyles: any[] = [];
  constructor(
    public layerService: LayerService,
    private router: Router,
    public _snackbar: SnackbarService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {
    this.getSchemaInfo();
    this.mapStyle = getMapStyle();
    this.baseMap = this.mapStyle.dataBasemap;
  }

  ngOnInit(): void {
    //
    this.product.visibility = '100';
    this.product.selectability = '100';
    this.product.editability = '999';
    this.product.visible_by_default = 'true';
    this.product.show_in_legend = 'true';
    this.product.map_format = 'WMS';
    this.product.map_srid = 'EPSG:3857';
    this.product.cors_keyword = '';
  }
  changeSchema(): void {
    this.showTable = true;
  }
  changeTable() {
    this.showOtherOption = true;
  }
  onSchemaChange() {
    if (!(this.product.sql_table in this.layerSchemaInfo)) {
      this.product.sql_table = '';
    }
  }
  onTableChange() {
    const tableArray =
      this.layerSchemaInfo[this.product.sql_schema][this.product.sql_table];
    if (
      !tableArray.find((child: string) => child === this.product.sql_id_field)
    ) {
      this.product.sql_id_field = '';
    }
    if (
      !tableArray.find((child: string) => child === this.product.sql_name_field)
    ) {
      this.product.sql_name_field = '';
    }
    if (
      !tableArray.find(
        (child: string) => child === this.product.sql_geometry_field
      )
    ) {
      this.product.sql_geometry_field = '';
    }
    if (
      !tableArray.find(
        (child: string) => child === this.product.sql_touch_field
      )
    ) {
      this.product.sql_touch_field = '';
    }
  }
  save(form: NgForm) {
    if (form.value.name == undefined || form.value.name === '') {
      this.dialog.open(SubscribErrorModalComponent, {
        panelClass: 'app-full-bleed-dialog',
        backdropClass: 'bdrop',
        position: {
          top: '10%',
        },
        data: {
          title: 'Plural Name blank.',
          content: 'This field is required.',
        },
      });
      return;
    } else if (form.value.typename == undefined || form.value.typename === '') {
      this.dialog.open(SubscribErrorModalComponent, {
        panelClass: 'app-full-bleed-dialog',
        backdropClass: 'bdrop',
        position: {
          top: '10%',
        },
        data: {
          title: 'Singular Name blank.',
          content: 'This field is required.',
        },
      });
    } else if (
      form.value.map_server == undefined ||
      form.value.map_server === ''
    ) {
      this.dialog.open(SubscribErrorModalComponent, {
        panelClass: 'app-full-bleed-dialog',
        backdropClass: 'bdrop',
        position: {
          top: '10%',
        },
        data: {
          title: 'Server URL blank',
          content: 'This field is required.',
        },
      });
    } else {
      if (this.editLayer) {
        this.spinner.show();
        const obj = {
          layer_styles: this.layerStyles,
        };
        Object.assign(form.value, obj);
        this.layerService
          .editLayer(this.layerId, form.value)
          .pipe(first())
          .subscribe({
            next: (res) => {
              this.spinner.hide();
              localStorage.removeItem('layerData');
              localStorage.setItem('layerData', JSON.stringify(res).toString());
              this._snackbar.openSnackBar('Layer update successfully');
              this.disableButton = false;
              this.router.navigate(['/global']);
            },
            error: () => {
              this.spinner.hide();
              this._snackbar.openSnackBar('Something went wrong');
            },
          });
      } else {
        this.spinner.show();

        const obj = {
          layer_styles: this.layerStyles,
        };
        Object.assign(form.value, obj);

        this.layerService
          .createLayer(form.value)
          .pipe(first())
          .subscribe({
            next: (res: any) => {
              this.spinner.hide();
              localStorage.removeItem('layerData');
              localStorage.setItem('layerData', JSON.stringify(res).toString());
              this._snackbar.openSnackBar('Layer added successfully');
              this.disableButton = false;
              this.router.navigate(['/global']);
            },
            error: () => {
              this.spinner.hide();
              this._snackbar.openSnackBar('Something went wrong');
            },
          });
      }
    }
  }

  getSchemaInfo() {
    this.spinner.show();
    this.layerService
      .getLayerSchema()
      .pipe(
        tap((res) => {
          this.layerSchemaInfo = res.data;
        }),
        finalize(() => {
          this.layerService.layerdata = localStorage.getItem('layerData');
          if (this.layerService.layerdata) {
            this.editLayer = true;
            this.product = JSON.parse(this.layerService.layerdata);
            this.layerId = this.product.id;
            this.layerName = this.product.name;
            this.apMapFeed = `/layers/${this.layerId}/apmapfeed`;
          } else {
            this.disableButton = true;
            this.layerName = 'New Layer';
            this.product.map_server =
              'https://geoserver.mapport.com/geoserver/wms';
          }
          this.spinner.hide();
        })
      )
      .subscribe();
  }

  backToMain() {
    this.router.navigate(['/global']);
  }
  backToLayer() {
    this.router.navigate([`/layers/${this.layerId}`]);
  }
  getSpinnerState(event: any) {
    if (event) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  getStyleLayers(event: any) {
    this.layerStyles = event;
  }
}
