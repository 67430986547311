import { MapServiceService } from './../../map/services/Map-service/map-service.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardServiceService {
  welcomeModal: any;
  mapsAndProfile: any = null;
  subscription: any;
  callApi: any;
  mapId: any;
  allModals: any
  user: Subject<any> = new Subject<any>();
  dataLoaded: Subject<any> = new Subject<any>();
  redirectSettingUpdated: Subject<any> = new Subject<any>();
  clearData: Subject<any> = new Subject<any>();
  showBackToDemigod: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient, private mapService: MapServiceService) {
    //
  }

  isDataLoaded(value: any): void {
    this.dataLoaded.next(value);
  }

  clear(value: any): void {
    this.clearData.next(value);
  }

  settingUpdated(value: any): void {
    this.redirectSettingUpdated.next(value);
  }

  setBackToDemigod(value: any): void {
    this.showBackToDemigod.next(value);
  }

  setUserInfo(value: any): void {
    this.user.next(value);
  }

  getIndustryList(): { name: string; value: string }[] {
    const industry = [
      {
        name: 'Industry',
        value: 'Industry',
      },
      {
        name: 'Agriculture',
        value: 'Agriculture',
      },
      {
        name: 'Appraisal',
        value: 'Appraisal',
      },
      {
        name: 'Attorney',
        value: 'Attorney',
      },
      {
        name: 'Brokerage',
        value: 'Brokerage',
      },
      {
        name: 'Contracting',
        value: 'Contracting',
      },
      {
        name: 'Development',
        value: 'Development',
      },
      {
        name: 'Education',
        value: 'Education',
      },
      {
        name: 'Engineering/Architecture',
        value: 'Engineering/Architecture',
      },
      {
        name: 'Federal',
        value: 'Federal',
      },
      {
        name: 'Finance',
        value: 'Finance',
      },
      {
        name: 'Insurance',
        value: 'Insurance',
      },
      {
        name: 'Investment',
        value: 'Investment',
      },
      {
        name: 'Law Enforcement',
        value: 'Law Enforcement',
      },
      {
        name: 'Municipal',
        value: 'Municipal',
      },
      {
        name: 'Native American Tribe',
        value: 'Native American Tribe',
      },
      {
        name: 'News Media',
        value: 'News Media',
      },
      {
        name: 'Non-Profit/Volunteering',
        value: 'Non-Profit/Volunteering',
      },
      {
        name: 'Oil/Energy/Solar/Greentech',
        value: 'Oil/Energy/Solar/Greentech',
      },
      {
        name: 'Other',
        value: 'Other',
      },
      {
        name: 'Recreation',
        value: 'Recreation',
      },
      {
        name: 'State',
        value: 'State',
      },
      {
        name: 'Utility',
        value: 'Utility',
      },
    ];
    return industry;
  }

  hideWelcomeModal(body: any): Observable<any> {
    return this.http.put('/dashboard/hide-welcome-modal/', body);
  }

  getDashboardData(): Observable<any> {
    return this.http.get('/dashboard/', {
      params: {
        current_url: window.location.href,
      },
    });
  }

  sendCollabEmail(
    productType: any,
    email: any,
    collabMapId: any,
    privewText: any
  ): Observable<any> {
    return this.http.get('/collaborator/send-email/', {
      params: {
        current_url: window.location.href,
        select_product_type: productType.toString(),
        emails: email.toString(),
        collab_map_id: parseInt(collabMapId),
        preview_text: privewText.toString(),
      },
    });
  }

  public getDynamicModal(mapid: any = '', liveShare: any): Observable<any> {
    return this.http.get('/dashboard/get-all-modals/', {
      params: {
        map_id: mapid,
        is_live_share: liveShare,
      },
    });
  }

  deleteCollaborators(body: any): Observable<any> {
    return this.http.put('/collaborator/delete/', body);
  }

  changePassword(body: any): Observable<any> {
    return this.http.post('/dashboard/password-reset/', body);
  }

  updateProfile(body: any): Observable<any> {
    return this.http.put('/dashboard/edit-profile/', body);
  }

  editAdOns(body: any): Observable<any> {
    return this.http.put('/dashboard/edit-adds-on/', body);
  }

  editPayment(body: any): Observable<any> {
    return this.http.put('/dashboard/edit-payment-info/', body);
  }

  unsubMaps(body: any): Observable<any> {
    return this.http.put('/dashboard/remove-map-subscription/', body);
  }

  getPaymentHistory(): Observable<any> {
    return this.http.get('/dashboard/get-payment-history/');
  }

  loginRedirectSetting(body: any): Observable<any> {
    return this.http.put('/dashboard/update-direct-login-status/', body);
  }
}
