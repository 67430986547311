<div class="modal-body modal-padding">
  <h5 mat-dialog-title class="main-header" (click)="goBackToReports(); closeDialog()">
    <img *ngIf="!gatheringDocuments && !statusDone" class="left-arrow-icon" src="/assets/img/left-arrow.png" />
  </h5>
  <button [disabled]="closeButtonDimmed" mat-button (click)="closeCross()"
    [ngClass]="closeButtonDimmed ? 'close-icon-demmied' : 'close-icon'">
    <mat-icon>close</mat-icon>
  </button>
  <div class="row">
    <div class="col-md-12 mb-4 mt-5">
      <div *ngIf="!gatheringDocuments && !statusDone" >
        <div [ngClass]="show ? 'payment-information-new' : 'payment-information'">
          <h5 class="title">Confirm Payment Information</h5>
          <p class="p-info" *ngIf="show">Enter new payment information.</p>
        </div>
        <div class="payment-info-panel" *ngIf="!show">
          <div class="user-info">
            <p class="first-name">{{ firstName }}</p>
            <p class="last-name">{{ lastName }}</p>
          </div>
          <div class="card-info">
            <img class="atm-card" src="/assets/img/atm-card.png" />
            <p class="card-number">{{ currentCard }}</p>
            <p class="card-number">{{ expiryDate }}</p>
            <p class="card-number">***</p>
          </div>
          <ngx-spinner [fullScreen]="true" type="" size="large" bdColor="rgba(0, 0, 0, 0)">
            <img style="width: 100px" src="/assets/img/wheel.svg" alt="Loading..." width="100px" height="100px" />
          </ngx-spinner>
        </div>
        <app-payment *ngIf="show" class="applyNewCss1"></app-payment>
        <div class="button-wrapper1" *ngIf="show">
          <button class="comment-button-style" (click)="submitPayment()">
            OK
          </button>
          <button class="comment-button-style" (click)="back()">Back</button>
        </div>
        <div class="button-wrapper" *ngIf="!show">
          <img src="/assets/img/recurly/powered_recurly.png" />
          <img class="recurly-images" src="/assets/img/recurly/TSYS.png" />
          <button class="comment-button-style" (click)="editPaymentInfo()" *ngIf="!gatheringDocuments">
            Edit
          </button>
        </div>
      </div>

      <div [ngClass]="show ? 'patment-main' : 'patment-main-final'">
        <h5 class="confirm-order" *ngIf="!hideProceedButton">
          Confirm Report Choices
        </h5>
        <h5 class="confirm-order" *ngIf="hideProceedButton">Your Order</h5>
        <ul class="order-list">
          <li class="order" *ngFor="let item of listReports; index as i">
            <label style="font-weight: 500;" [innerHtml]="item.name|sanitizeHtml"></label>
            <div style="padding: 3px 0px 0px 0px;">
              <label class="doc-price" style="text-align: end; width: 100px">${{ item.price }}</label>
              <label style="text-align: end; width: 100px" class="doc-available">
                <img class="left-arrow-icon" *ngIf="item.is_loading === true" src="/assets/img/wheel.svg" />{{
                item.status }}</label>
            </div>
          </li>
        </ul>
        <div class="user-info">
          <label *ngIf="!gatheringDocuments && !statusDone" class="note-text" style="
              font-size: 12px;
              width: 385px;
              margin-left: 5px;
              font-weight: 100 !important;
              color: #000;
            ">
            <b>Note:</b> Some reports or documents may not be available in our
            system. You will be charged only for those successfully
            delivered.</label>
          <div class="totalPayment">
            <label class="total">Total</label>
            <label [ngClass]="!gatheringDocuments && !statusDone ? 'total-price' : 'total-price-note'">${{ sum
              }}</label>
          </div>
        </div>

        <div class="button-wrapper2" *ngIf="!hideProceedButton">
          <button class="comment-button-style" (click)="goBackToReports(); closeDialog()" [disabled]="show">
            Back
          </button>
          <button class="comment-button-style" download (click)="proceed()" [disabled]="show">
            Proceed
          </button>
        </div>
        <div *ngIf="gatheringDocuments && !hideMessage" class="gatherDocuments">
          <div class="loading">Gathering your documents</div>
          <p class="gatherDocumentText">
            Your documents will start downloading automatically as available.
          </p>
        </div>
        <div *ngIf="statusDone" class="gatherDocuments">
          <p class="gatherDocumentText">
            <b>Note:</b> Some reports or documents may not be available in our
            system.<br> You will be charged only for those successfully
            delivered.
          </p>
          <button class="comment-button-style-exist" (click)="existModal()">
            Exit
          </button>
        </div>
        <!-- <div *ngIf="statusNotDone" class="gatherDocuments">
        <div class="done">Sorry!</div>
        <p class="gatherDocumentText">Reports are not available.</p>
        <button class="comment-button-style-exist" (click)="existModal()">
          Exit
        </button>
      </div> -->
      </div>
    </div>
  </div>
  <a id="submit-pdf1" hidden download></a>
</div>
