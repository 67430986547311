<app-global-mod-header></app-global-mod-header>
<!-- <app-layers-tab></app-layers-tab> -->
<div class="container tabs mt-5" style="margin-top: 50px">
  <mat-tab-group (selectedTabChange)="tabChanged($event)" mat-align-tabs="end" [selectedIndex]="10"
    animationDuration="0ms">
    <ng-container *ngFor="let tab of Maintab">
      <mat-tab>
        <ng-template mat-tab-label [dataProvider]="tab" *ngIf="
            tab.data.label !== 'Security' &&
            tab.data.label !== 'Global Modification'
          ">
          <a class="mat-label-text" href="{{ tab.data.link }}" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template mat-tab-label [dataProvider]="tab" *ngIf="tab.data.label === 'Global Modification'">
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template mat-tab-label [dataProvider]="tab" *ngIf="tab.data.label === 'Security'">
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <ng-template mat-tab-label [dataProvider]="tab" *ngIf="tab.data.label === 'courtesyAccount'">
          <a class="mat-label-text" (click)="tabSelected(tab)">
            {{ tab.data.label }}
          </a>
        </ng-template>
        <!-- <ng-container *ngComponentOutlet="tab.component"></ng-container> -->
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>
<div class="container">
  <div class="row page-title" style="margin-right: 0px">
    <div class="col-md-7 mt-3 p-0">
      <h3>
        <a>All Users</a> »
        <a>New Courtesy Account</a>
      </h3>
    </div>

  </div>
  <div class="row">
    <form [formGroup]="accountForm">
      <div class="col-md-3 col">
        <input type="hidden" name="csrfmiddlewaretoken"
          value="ym4J4ObjYCrtAfzyAH41rDiqlgyPO2bag8WqEOBHy6iPS3wOzj9kQ4Zmhv1qYNAf" />

        <h1>Details</h1>

        <div class="form-group">
          <label class="control-label">First Name</label>
          <input type="text" formControlName="firstName" class="form-control" maxlength="255" />
        </div>

        <div class="form-group">
          <label class="control-label">Last Name</label>
          <input type="text" formControlName="lastName" class="form-control" maxlength="255" />
        </div>

        <div class="form-group">
          <label class="control-label">Email Address</label>
          <input type="email" formControlName="email" required="" class="form-control" maxlength="255" />
        </div>

        <div class="form-group">
          <label class="control-label">Phone Number</label>
          <input type="text" formControlName="phone" class="form-control" maxlength="255" />
        </div>
      </div>
    </form>

    <div class="col-md-9 col" style="margin-top: 25px">
      <div id="exTab2">
        <app-courtesy-tabs></app-courtesy-tabs>
      </div>
    </div>
    <div class="col-md-2 col" style="float: right; text-align: center; margin-top: 10px;">
      <button type="submit" class="btn btn-primary submit" [disabled]=submitDisable (click)="submit()">Send
        Invite</button>
    </div>
  </div>
</div>
<ngx-spinner [fullScreen]="true" type="" size="medium" bdColor="rgba(0, 0, 0, 0)">
  <p class="loading"> <img src="/assets/img/wheel.svg" alt="Girl in a jacket" width="100px" height="100px"> </p>
</ngx-spinner>