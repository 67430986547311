import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { showFields } from 'src/app/modules/shared/models/showFields/showFields';

@Injectable({
  providedIn: 'root'
})
export class ShowFieldsService {
  layerdata: any
  constructor(private http: HttpClient,) { }

  //Get Table By Id
  public getShowFieldsById(id: any): Observable<showFields> {
    return this.http.get<showFields>(`/glomod/show_fields/?layer_id=${id}`);
  }
  //Get Add Show Fields By Id
  public getAddFieldsById(id: any): Observable<showFields> {
    return this.http.get<showFields>(`/glomod/fields-operation/?layer_id=${id}`);
  }
  //post Show Fields
  public saveAddFields(id:any, data: any): Observable<showFields> {
    return this.http.post<showFields>(`/glomod/fields-operation/?layer_id=${id}`, data);
  }
  //update Show Fields
  public updateAddField(data: any): Observable<showFields> {
    return this.http.put<showFields>(`/glomod/fields-operation/`, data);
  }
  //delete Show Fields
  public deleteAddField(id: any): Observable<showFields> {
    return this.http.delete<showFields>(`/glomod/fields-operation/?ids=${id}`);
  }
}
