<div class="modal-dialog">
  <div class="modal-body" style="overflow-y: hidden">
    <div class="row">
      <div class="col-md-12">
        <h5 style="font-size: 20px">
          The <strong>Live Share</strong> link used to reach this map has
          expired.
        </h5>
        <h5 style="font-size: 20px">We hope you found the map useful.</h5>

        <p style="line-height: 10px; margin-top: 30px">
          Need more information?
        </p>
        <p style="line-height: 10px">
          Go to the
          <a href="https://mapport.com" target="_blank">MapPort</a> website, or
          contact ENPLAN
        </p>
        <p style="line-height: 10px">Monday-Friday 9am-5pm Pacific</p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="link">
          <a href="mailto:wmsgroup@enplan.com" target="_blank"
            >wmsgroup@enplan.com</a
          >
          &nbsp;&nbsp;&nbsp;
          <a href="tel:1-530-402-4783" target="_blank">(530) 402-4783</a>
          <a href="/login" style="float: right; margin-right: 10px">Log In</a>
        </div>
      </div>
    </div>
  </div>
</div>
