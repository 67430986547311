<div class="modal-body modal-padding">
  <h5 mat-dialog-title class="main-header">Documents and Property Reports Menu</h5>
  <button mat-button class="close-icon" (click)="closeProperty()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="chooseReports">
    <h6 class="choose-reports">
      Choose Reports
    </h6>
    <img class="arrow-icon" src="/assets/img/down-arrow-down-arrow-png-transparent-png.png" />
  </div>
  <div class="row">
    <div class="col-md-12 mb-4 mt-5">
      <ul class="topSymbollist">
        <ng-container *ngFor="let item of propertyData; index as i">
          <li class="toplist d-flex flex-row align-items-center" style="display: flex">
            <a (click)="activeButton(i)">
              <mat-icon *ngIf="isActive !== i">keyboard_arrow_up</mat-icon>
              <mat-icon *ngIf="isActive === i">keyboard_arrow_down</mat-icon>
            </a>
            <div [ngClass]="isActive === i ? 'top-list-active': 'top-list'">
              <div class="top-brand" (click)="activeButton(i)">
                <h4 class="documnent-name" [innerHtml]="item.name|sanitizeHtml"></h4>
              </div>
              <ng-container ></ng-container>
              <div class="users-pro" >
                <input type="checkbox" name="requireCheckbox" [(ngModel)]="item.is_enabled"
                  (ngModelChange)="requireCheckBoxType(item)" />
                <div class="price">
                  <label>${{ item.price }}</label>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="description" *ngIf="isActive === i" [innerHtml]="item.description | sanitizeHtml">
            </div>
          </li>
        </ng-container>
        <label style="font-size: 12px; width: 459px;margin-left: 26px; font-weight: 100 !important;color: #000;">
          <b>Note:</b> Some reports or documents may not be available in our system. You will be charged only for those
          successfully delivered.</label>
      </ul>
      <div class="comments mt-5">
        <div class="button-wrapper">
          <button class="comment-button-style" [disabled]="nextButtonDisable"
            (click)="getParcelsFiles(); closeDialog()">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner [fullScreen]="true" type="" size="large" bdColor="rgba(0, 0, 0, 0)">
  <img style="width: 100px" src="/assets/img/wheel.svg" alt="Loading..." width="100px" height="100px" />
</ngx-spinner>
