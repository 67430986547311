<div class="modal-dialog">
  <div class="modal-body" [innerHtml]="liveShareBody | sanitizeHtml">

  </div>
  <div>
    <p>
      You have <span id="demo"></span> to explore all this map has to offer.
    </p>
  </div>
  <div class="modal-footer">
    <div  class="checkbox_temp" >
        <span [innerHtml]="htmlCheckbox | sanitizeHtml"></span>
        <input
        *ngIf="!showCheckobox"
        type="checkbox"
        [(ngModel)]="expiryValue"
        (change)="term($event)"
        id="dont-show"
      />
      </div>
    <button
      type="button"
      class="btn btn-default btn-lg accept-btn"
      (click)="close()"
      [disabled]="isReadOnly"
      id="go-to-map"
    >
      Go to map!
    </button>
  </div>
</div>
