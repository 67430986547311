import { Observable, Subject } from 'rxjs';

import { DynamicModalComponent } from 'src/app/modules/map/modals/dynamic-modal/dynamic-modal.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ShareLinkService } from '../Share-Link/share-link.service';

@Injectable({
  providedIn: 'root',
})
export class MapServiceService {
  baseMap: Subject<string> = new Subject<string>();
  currentZoom: Subject<any> = new Subject<any>();
  captureFeature: Subject<any> = new Subject<any>();
  layerGeomType: Subject<any> = new Subject<any>();
  deleteCaptureFeatureData: Subject<any> = new Subject<any>();
  bMap: Subject<any> = new Subject<any>();
  defaultValue: Subject<any> = new Subject<any>();
  tabIcon: Subject<any> = new Subject<any>();
  parcelKey: Subject<any> = new Subject<any>();
  closeLeft: Subject<any> = new Subject<any>();
  checkChannel: Subject<any> = new Subject<any>();
  captureToolRefresh: Subject<any> = new Subject<any>();
  refreshBookMark: Subject<any> = new Subject<any>();
  isGeolock: Subject<any> = new Subject<any>();
  setPoint: Subject<any> = new Subject<any>();
  setPolygon: Subject<any> = new Subject<any>();
  baseMapOnClicktoZoom: Subject<any> = new Subject<any>();
  layerHeadingForbookMark: Subject<any> = new Subject<any>();
  changeLayerClickToZoom: Subject<any> = new Subject<any>();
  layerHeadingForbookMarkClose: Subject<any> = new Subject<any>();
  bookMarkMsg: Subject<any> = new Subject<any>();
  hideHeader: Subject<any> = new Subject<any>();
  animate: Subject<any> = new Subject<any>();
  dynamicModalSub: Subject<any> = new Subject<any>();
  toolName: Subject<any> = new Subject<any>();
  drawInteraction: Subject<any> = new Subject<any>();
  legendLiveshare: Subject<number> = new Subject<number>();
  bufferShowFiles: Subject<any> = new Subject<any>();
  drawFeatureData: Subject<any> = new Subject<any>();
  //Arrays
  overLayId: any = [];
  overLayIds: any = [];
  selectedBufferData: any = [];
  allBuffersFeatures: any = [];
  selectedFeature: any = [];
  ChangeLayerClickToZoom: any = [];
  bufferIds: Array<any> = [];
  ParcelData: Array<any> = [];
  saveCtrlData: any = [];
  layersData: Array<any> = [];
  bookMarkShowLayers: Array<any> = [];
  layerName: any = [];
  dataHistroy: Array<any> = [];
  selectedParcels: Array<any> = [];
  multipleFeature: Array<any> = [];
  multiParcelId: any = [];
  dynamicModalOpenCurrently: any = [];
  removeInteraction: any;
  featureLayersId: any = [];
  parcels: any = [];
  apnValues: any = [];
  dyamicModalREf: any = [];
  bufferPolygonGeometry: any = []
  //objects
  startStopTimes: any = {};
  headers!: { mapId: number };
  refineLayersData: any = {};
  //Boolean
  zoomMax = false;
  templatetoolTips: any = false;
  hideRunButton = true;
  checkDefualtView = false;
  ctrlCheck = false;
  shareLinkData = false;
  panelSize = false;
  setbookMarkZoom = false;
  closePanel = false;
  isParcelLayer = false;
  annotationLable = false;
  showParcelLabel = false;
  searchResult = false;
  bookMarkTool: any = false;
  movendWork: any = true;
  stopHover = true;
  elementsVisibility: any = false;
  checkLabel: any = false;
  isLiveShareLink: any = 'false';
  perviousLabel: any = '0';
  //Any
  isCheckboxInput: any = true;
  default_view: any;
  DrawLayer: any;
  DEMLayer: any
  previousId: any;
  ctrlPress: any;
  vectorDrawSource: any
  geoLocationLayer: any
  setAdvanceBuffer: any;
  overLay: any;
  toolNameForMetrics = false;
  embed: any;
  isModalInput: any = true;
  featureId: any;
  ctrlPush: any;
  templates: any;
  getAllModals: any
  fontSize: any = 11;
  uniqFeatureData: any;
  drawBox: any;
  checkExpiry: any;
  expiryTime: any;
  defaultModal: any;
  collId: any;
  shareCurrentMap: any;
  reqBox: any;
  userUpdate: any;
  chat_display_message: any;
  index: any = 0;
  zoomSaveView: any;
  innerBuffer: any = [];
  annotationDrawLabel: any;
  idleTimeout: any = 6;
  event: any;
  currentMapZoom: any;
  currentPageName: any = 'default-page-name';
  totalTimeOnPage: any = 0;
  timeSpentOnPageInSeconds: any = 0;
  stopTime: any;
  captureVectorLayer: any;
  header: any;
  previousLable: any;
  extentData: any;
  toolId: any;
  currentMapLoad: any;
  lightningSource: any;
  captureLayerIndex: any;
  captureLayerData: any;
  currentMap: any;
  toolData: any;
  mapData: any;
  Mapslug: any;
  leftNavOpenClose: any;
  collabratorId: any;
  captureFeactureData: any;
  data: any;
  annotationFeature: any;
  dataLogin: any;
  mapLayer: any;
  currZoom: any;
  lastLabel: any = '0';
  listResult: any;
  result: any;
  dataCounter = 0;
  type: any;
  showBuffferField: any;
  zoomType: any;
  streetViewLayer: any;
  zoomLayer: any;
  allBtns: any;
  bookMarkType: any;
  selectedLayersId: any;
  baseLayerName: any;
  eventClick: any;
  mapName: any;
  pointGeometry: any;
  polygonGeometry: any;
  parcel: any;
  multiParcel: any;
  featureLayerId: any;
  shareId: any = '';
  showLegend: any;
  shareData: any;
  userAccessLevel: any;
  toolNameLayer: any;
  layersId: any;
  uploadFileData: any;
  prePlanData: any;
  pdfPath: any;
  mapsData: any;
  accessMapApi: any;
  addNewFeature: any;
  featureIcon: any;
  selectLayer: any;
  layerGeomTypes: any;
  ipAddress: any;
  pageUrl: any;
  mapId: any;
  counter = 0;
  zoomBoxCounter = 0;
  mainFolder: any;
  layerGeoType: any;
  mapAccessLevel: any;
  captureLayer: any;
  layerGeometry: any;
  captureLayersId: any;
  mapInterface: any;
  setZoomvalue: any;
  captureDrawSource: any;
  preSelectedAnnotation: any = '';
  annotationShare: any = [];
  annotationFolderShare: any = [];
  selectedColor: any;
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private router: Router,
    public shareLinkService: ShareLinkService,
    private route: ActivatedRoute
  ) {
    this.route.queryParamMap.subscribe((params) => {
      setTimeout(() => {
        this.shareLinkService.shareUrlId = params.get('share');
      }, 1000);
    });

    // this.dynamicMapModalCheck()
  }

  getLabelDataField(mapid: any, layerId: any, features: any): Observable<any> {
    return this.http.get('/data/label-data-fields/', {
      params: {
        map_id: parseInt(mapid),
        layer_id: parseInt(layerId),
        layer_features: features.toString(),
        share_id: this.shareId || '',
      },
    });
  }

  public dataView(body: any): Observable<any> {
    const body1 = body;
    if(body1.download_type == "mail_label") {
      body1.display_selection_id = false
    } else {
      body1.display_selection_id = this.checkLabel.toString();
    }

    return this.http.post('/data/create-report/', body1);
  }

  public dismissDynamicModal(id: any): Observable<any> {
    const body = {
      modal_id: +id,
    };
    return this.http.post('/dashboard/dismiss-user/', body);
  }

  public getDynamicModal(mapid: any = '', liveShare: any): Observable<any> {
    return this.http.get('/dashboard/get-all-modals/', {
      params: {
        map_id: mapid,
        is_live_share: liveShare,
      },
    });
  }

  public getMapDynamicModal(): Observable<any> {
    return this.http.get('/dashboard/dynamic-modals/', {});
  }

  //get all Modals
  public dynamicMapModalCheck(): void {
    this.getMapDynamicModal().subscribe((res) => {
      if (res) {
        this.getAllModals = res.Modals
      }
    });
  }

  closeDynamicModalThroughSubject(value: any) {
    this.dynamicModalSub.next(value);
  }

  public handleDynamicModal(data: any) {
    if (this.dynamicModalOpenCurrently.includes(data.id)) {
      return;
    } else {
      this.dyamicModalREf.push(
        this.dialog.open(DynamicModalComponent, {
          data: {
            all_data: data,
            index: this.index,
          },
        })
      );
      this.index = this.index + 1;
      this.dynamicModalOpenCurrently.push(data.id);
      this.dynamicModalSub.subscribe((res: any) => {
        this.dyamicModalREf[res].close();
      });
    }
  }

  public getPdfDataField(
    layerId: any,
    mapId: any,
    features: any
  ): Observable<any> {
    return this.http.get('/data/get-pdf-data-fields/', {
      params: {
        map_id: mapId,
        layer_id: layerId.toString(),
        layer_features: features,
        share_id: this.shareId || '',
        selection_id: this.checkLabel,
      },
    });
  }

  public querySearch(
    layerId: any,
    values: any,
    operators: any,
    fieldkeys: any,
    mapId: any,
    viewBounds: any
  ): Observable<any> {
    const subLevel = localStorage.getItem('subLevel');
    let params: any;
    if (subLevel) {
      params = {
        layer_id: parseInt(layerId),
        values: values.toString(),
        operators: operators.toString(),
        field_keys: fieldkeys.toString(),
        map_id: parseInt(mapId),
        access_level: subLevel,
        share_id: this.shareId || '',
        view_bounds : viewBounds
      };
    } else {
      params = {
        layer_id: parseInt(layerId),
        values: values.toString(),
        operators: operators.toString(),
        field_keys: fieldkeys.toString(),
        map_id: parseInt(mapId),
        share_id: this.shareId || '',
        view_bounds : viewBounds
      };
    }
    return this.http.get('/map_data/query-tool-search/', {
      params: params,
    });
  }

  public getPrePlan(apnKey: any): Observable<any> {
    return this.http.get('/document/preplan/get/', {
      params: {
        apn: apnKey.toString(),
      },
    });
  }

  public editPrePlan(editId: any): Observable<any> {
    return this.http.get('/document/preplan/edit/', {
      headers: {
        id: editId,
      },
    });
  }

  public editPrePlanDocument(body: any): Observable<any> {
    return this.http.put('/document/preplan/edit/', body);
  }

  getMapData(slug: string, type: any, share: any, colId: any): Observable<any> {
    let params: any;
    const subLevel = localStorage.getItem('subLevel');
    if (subLevel) {
      params = {
        map_name: slug,
        type: type,
        share_id: share,
        coll_id: colId,
        access_level: subLevel,
      };
    } else {
      params = {
        map_name: slug,
        type: type,
        share_id: share,
        coll_id: colId,
      };
    }

    return this.http.get('/map/access/', {
      params: params,
    });
  }

  getSearchCoordinates(
    mapId: any,
    layersIds: any,
    wkt: any,
    point: any,
    parcelLength: any,
    slectedUnion: any,
    ctrlCheck: any,
    polygonAnnotation: any,
    linesAnnotation: any,
    pointSAnnotation: any,
    mapResolution: any,
    selectedFeature: any,
    preSelected: any,
    annotationId: any,
    zoom: any
  ): Observable<any> {
    let params: any;
    const subLevel = localStorage.getItem('subLevel');
    const shareId = localStorage.getItem('shareId');
    if(ctrlCheck == false){
      this.perviousLabel = 0
    }
    if (subLevel) {
      params = {
        map_id: mapId,
        layer_ids: layersIds.toString(),
        wkt: wkt.toString(),
        point_coordinates: point,
        parcel_length: parcelLength.toString(),
        selected_union: slectedUnion.toString(),
        ctrl_check: ctrlCheck,
        polygon_annotation: polygonAnnotation,
        line_annotation: linesAnnotation,
        point_annotation: pointSAnnotation.toString(),
        map_resolution: mapResolution,
        selected_feature: selectedFeature.toString(),
        last_label: parseInt(this.perviousLabel),
        share_id: shareId || '',
        pre_selected_annotation: preSelected.toString(),
        annotation_id: annotationId.toString(),
        access_level: subLevel,
        zoom: zoom
      };
    } else {
      params = {
        map_id: mapId,
        layer_ids: layersIds.toString(),
        wkt: wkt.toString(),
        point_coordinates: point,
        parcel_length: parcelLength.toString(),
        selected_union: slectedUnion.toString(),
        ctrl_check: ctrlCheck,
        polygon_annotation: polygonAnnotation,
        line_annotation: linesAnnotation,
        point_annotation: pointSAnnotation.toString(),
        map_resolution: mapResolution,
        selected_feature: selectedFeature.toString(),
        last_label: parseInt(this.perviousLabel),
        share_id: shareId || '',
        pre_selected_annotation: preSelected.toString(),
        annotation_id: annotationId.toString(),
        zoom: zoom
      };
    }
    return this.http.get('/map_data/coordinates-based-search/', {
      params: params,
    });
  }

  public searchParcels(body: any): Observable<any> {
    const subLevel = localStorage.getItem('subLevel');
    const shareId = localStorage.getItem('shareId');
    if (subLevel) {
      body = {...body, share_id: shareId || '', last_label: parseInt(this.perviousLabel),access_level: subLevel,}
    } else {
      body = {...body, share_id: shareId || '', last_label: parseInt(this.perviousLabel),}
    }

    return this.http.post('/map_data/coordinates-based-search-post/', body);
  }

  getUserData(): Observable<any> {
    return this.http.get('/dashboard/', {
      params: {
        current_url: window.location.href,
      },
    });
  }

  getParcelData(
    methodType: any,
    pid: any,
    mapid: any,
    pointCoordinates: any,
    wkt: any,
    autocomplete: any,
    multiparcel: any,
    lid: any,
    query: any,
    search: any,
    apnLabels: any
  ): Observable<any> {
    const hasNumber = /\d/;
    let quertType: any;
    if (search && hasNumber.test(search)) {
      quertType = '2';
    } else if (search && !hasNumber.test(search)) {
      quertType = '1';
    } else {
      quertType = '';
    }
    const subLevel = localStorage.getItem('subLevel');
    let params: any;
    if (subLevel) {
      params = {
        method_type: methodType,
        parcel_ids: pid.toString(),
        map_id: mapid,
        point_coordinates: pointCoordinates,
        wkt: wkt,
        autocomplete: autocomplete,
        multi_parcel: multiparcel,
        layer_ids: lid.toString(),
        query_type: quertType,
        search_keywords: search,
        apn_labels: apnLabels,
        share_id: this.shareId || '',
        access_level: subLevel,
      };
    } else {
      params = {
        method_type: methodType,
        parcel_ids: pid.toString(),
        map_id: mapid,
        point_coordinates: pointCoordinates,
        wkt: wkt,
        autocomplete: autocomplete,
        multi_parcel: multiparcel,
        layer_ids: lid.toString(),
        query_type: quertType,
        search_keywords: search,
        apn_labels: apnLabels,
        share_id: this.shareId || '',
      };
    }

    return this.http.get('/map_data/search/', {
      params: params,
    });
  }

  getClientInfo(token: any) {
    return this.http.get('/authentication/demigod-client-login/', {
      headers: {
        Authorization: token,
      },
    });
  }

  public setMapData(data: any) {
    this.mapData = data;
  }

  public createAnnotationFolder(body: any): Observable<any> {
    return this.http.post('/folder/viewset/', body);
  }

  public editAnnotationFolder(body: any, id: any): Observable<any> {
    return this.http.patch(`/folder/viewset/${id}/`, body);
  }

  public deleteAnnotationFolder(id: any): Observable<any> {
    return this.http.delete(`/folder/viewset/${id}/`);
  }

  public moveAnnotationToFolder(body: any): Observable<any> {
    return this.http.post(`/annotation/manage-folder/`, body);
  }

  public createAnnotation(body: any): Observable<any> {
    return this.http.post('/annotation/create/', body);
  }

  public editAnnotation(body: any): Observable<any> {
    return this.http.put('/annotation/update/', body);
  }

  public deleteAnnotation(body: any): Observable<any> {
    return this.http.put('/annotation/delete/', body);
  }

  uploadAnnotationFile(body: any) {
    return this.http.post('/annotation/upload/', body);
  }
  public getAnnotation(id: any): Observable<any> {
    return this.http.get('/annotation/get/', {
      params: {
        map_id: parseInt(id),
        share_id: this.shareId || '',
      },
    });
  }

  public activityLogs(body: any): Observable<any> {
    return this.http.post('/map/update-activity-logs/', body, {});
  }

  public dynamicModalCheck(id: any, live: any, isPage: any, isMap: any,isPrint: any = null): void {
    if(!isPrint) {
      let liveShare: any;
      if (live == 'true') {
        liveShare = true;
      } else {
        liveShare = false;
      }
      this.getDynamicModal(id, liveShare).subscribe((res) => {
        if (res) {
          // console.log(res, 'res');
          let dynamicModal: any;
          let mapName: any;
          let id: any;
          let nameID: any;
          let slug: any;
          if (this.mapData) {
            mapName = this.mapData.map_data.map_data.name;
            id = this.mapData.map_data.map_data.id;
            nameID = mapName.concat(',', id.toString());
            slug = this.Mapslug;
          }

          //handling dynamic modals based on properties
          if (res.Pages && isPage) {
            for (let i = 0; i < res.Pages.length; i++) {
              //all pages check
              if (res.Pages[i].all_pages == true && res.Pages[i].show) {
                //specific maps or pages
                dynamicModal = {
                  html: res.Pages[i].html,
                  time: res.Pages[i].dismiss_duration * 1000,
                  dismiss: res.Pages[i].dismiss,
                  dismiss_message: res.Pages[i].dismiss_message,
                  id: res.Pages[i].id,
                  browser_version: res.Pages[i].browser_version,
                };
                this.handleDynamicModal(dynamicModal);
                //specific pages
              } else if (
                res.Pages[i].specific_maps_or_pages == true &&
                res.Pages[i].show
              ) {
                // console.log(this.router.url, 'url', window.location.href, 'href');
                if (
                  (res.Pages[i].specific_maps_list.includes(nameID) ||
                    res.Pages[i].specific_maps_list.includes(id) ||
                    res.Pages[i].specific_maps_list.includes(mapName) ||
                    res.Maps[i].specific_maps_list.includes(slug) ||
                    res.Pages[i].specific_maps_list.includes(
                      window.location.href
                    ) ||
                    res.Pages[i].specific_maps_list.includes(this.router.url)) &&
                  res.Pages[i].show
                ) {
                  dynamicModal = {
                    html: res.Pages[i].html,
                    time: res.Pages[i].dismiss_duration * 1000,
                    dismiss: res.Pages[i].dismiss,
                    dismiss_message: res.Pages[i].dismiss_message,
                    id: res.Pages[i].id,
                    browser_version: res.Pages[i].browser_version,
                  };
                  this.handleDynamicModal(dynamicModal);
                }
                // all maps
              } else if (
                res.Pages[i].all_maps == true &&
                res.Pages[i].show &&
                isMap
              ) {
                dynamicModal = {
                  html: res.Pages[i].html,
                  time: res.Pages[i].dismiss_duration * 1000,
                  dismiss: res.Pages[i].dismiss,
                  dismiss_message: res.Pages[i].dismiss_message,
                  id: res.Pages[i].id,
                  browser_version: res.Pages[i].browser_version,
                };
                this.handleDynamicModal(dynamicModal);
              }
            }
          }
          //for maps
          if (res.Maps && isMap) {
            for (let i = 0; i < res.Maps.length; i++) {
              //all maps
              if (res.Maps[i].all_maps == true && res.Maps[i].show) {
                dynamicModal = {
                  html: res.Maps[i].html,
                  time: res.Maps[i].dismiss_duration * 1000,
                  dismiss: res.Maps[i].dismiss,
                  dismiss_message: res.Maps[i].dismiss_message,
                  id: res.Maps[i].id,
                  browser_version: res.Maps[i].browser_version,
                };
                this.handleDynamicModal(dynamicModal);
              }
              // all pages
              else if (res.Maps[i].all_pages == true && res.Maps[i].show) {
                dynamicModal = {
                  html: res.Maps[i].html,
                  time: res.Maps[i].dismiss_duration * 1000,
                  dismiss: res.Maps[i].dismiss,
                  dismiss_message: res.Maps[i].dismiss_message,
                  id: res.Maps[i].id,
                  browser_version: res.Maps[i].browser_version,
                };
                this.handleDynamicModal(dynamicModal);
              }

              //specific maps or pages
              else if (
                res.Maps[i].specific_maps_or_pages == true &&
                res.Maps[i].show
              ) {
                if (
                  (res.Maps[i].specific_maps_list.includes(nameID) ||
                    res.Maps[i].specific_maps_list.includes(id) ||
                    res.Maps[i].specific_maps_list.includes(mapName) ||
                    res.Maps[i].specific_maps_list.includes(slug) ||
                    res.Maps[i].specific_maps_list.includes(
                      window.location.href
                    ) ||
                    res.Maps[i].specific_maps_list.includes(this.router.url)) &&
                  res.Maps[i].show
                ) {
                  dynamicModal = {
                    html: res.Maps[i].html,
                    time: res.Maps[i].dismiss_duration * 1000,
                    dismiss: res.Maps[i].dismiss,
                    dismiss_message: res.Maps[i].dismiss_message,
                    id: res.Maps[i].id,
                    browser_version: res.Maps[i].browser_version,
                  };
                  this.handleDynamicModal(dynamicModal);
                }
              }
            }
          }

          if(res.Templates) {
            this.templates = res.Templates
          }
          if(res.Templates) {
            this.templates = res.Templates
          }

        }
      });
    }

  }

  //collaboration Registration
  invitationVerification(id: any, email: any) {
    return this.http.get('/collaborator/invitation-verification/', {
      params: {
        inviter_id: parseInt(id),
        invitee_email: email.toString(),
      },
    });
  }
  createCollaborator(body: any) {
    return this.http.post('/collaborator/create-account/', body, {});
  }

  collaboratorDestroySession(body: any) {
    return this.http.put('/map/destroy-seat/', body, {});
  }
  //for sending email through chat
  sendEmailThroughChat(replyEmail: any, emailBody: any): Observable<any> {
    return this.http.get('/send/support-email/', {
      params: {
        user_email: replyEmail.toString(),
        message: emailBody.toString(),
      },
    });
  }
  //Subject Values
  public setMapsData(value: string) {
    this.bMap.next(value);
  }

  //Subject Values

  public setcapture(value: any) {
    this.captureFeature.next(value);
  }
  public setIcon(value: any) {
    this.tabIcon.next(value);
  }
  public setGeomType(value: any) {
    this.layerGeomType.next(value);
  }
  public setPointGeometry(value: any) {
    this.setPoint.next(value);
  }
  public setPolygonGeometry(value: any) {
    this.setPolygon.next(value);
  }
  public setCurrentZoom(value: any) {
    this.currentZoom.next(value);
    this.setZoomvalue = value;
  }
  public getDefaultValue(value: any) {
    this.defaultValue.next(value);
  }
  public setBookMarkMsg(value: any) {
    this.bookMarkMsg.next(value);
  }
  public getHideHeader(value: any) {
    this.hideHeader.next(value);
  }
  public baseMapClicked(value: string) {
    this.baseMap.next(value);
  }

  public liveShareLegend(value: number) {
    this.legendLiveshare.next(value);
  }

  public setParcelKey(value: any) {
    this.parcelKey.next(value);
  }
  public setClosePanel(value: any) {
    this.closeLeft.next(value);
  }

  public setCaptureRefresh(value: any) {
    this.captureToolRefresh.next(value);
  }
  public setRefreshBookMark(value: any) {
    this.refreshBookMark.next(value);
  }
  public deleteCaptueFeature(value: any) {
    this.deleteCaptureFeatureData.next(value);
  }

  public setChannel(value: any) {
    this.checkChannel.next(value);
  }
  public setGeoLockTool(value: any) {
    this.isGeolock.next(value);
  }

  public setAnimate(value: any) {
    this.animate.next(value);
  }

  public setBaseMapOnClicktoZoom(value: any) {
    this.baseMapOnClicktoZoom.next(value);
  }

  public setLayerHeadingForbookMark(value: any) {
    this.layerHeadingForbookMark.next(value);
  }
  public setLayerHeadingForbookMarkClose(value: any) {
    this.layerHeadingForbookMarkClose.next(value);
  }

  public setChangeLayerClickToZoom(value: any) {
    this.changeLayerClickToZoom.next(value);
  }

  public getToolName(value: any) {
    this.toolName.next(value);
    this.toolNameLayer = value;
  }
  public getdrawEnable(value: any) {
    this.drawInteraction.next(value);
  }
  public getbufferShowFiles(value: any) {
    this.bufferShowFiles.next(value);
  }
  public getdrawFeature(value: any) {
    this.drawFeatureData.next(value);
  }
  //get Layer Ids
  getLayersId() {
    this.layersId = [];
    const allLayers = this.mapLayer.getLayers().getArray();

    for (let i = 0; i <= allLayers.length; i++) {
      if (
        allLayers[i]?.getProperties().isChecked == true &&
        allLayers[i]?.getProperties().allType == 'layers'
      ) {
        if (allLayers[i].getProperties().zoomlevel <= this.setZoomvalue) {
          if (this.toolNameLayer == 'Live Share') {
            this.layersId.push(allLayers[i].getProperties().id);
          } else if (this.toolNameLayer == 'Bookmark') {
            this.layersId.push(allLayers[i].getProperties().id);
          } else if (this.toolNameLayer == 'Download View') {
            this.layersId.push(allLayers[i].getProperties().id);
          } else if (this.toolNameLayer == 'save_view') {
            this.layersId.push(allLayers[i].getProperties().id);
          } else if (this.toolNameLayer == 'generate_pdf') {
            this.layersId.push(allLayers[i].getProperties().id);
          } else if (this.toolNameLayer == 'Print View') {
            this.layersId.push(allLayers[i].getProperties().id);
          } else {
            this.layersId.push(allLayers[i].getProperties().id);
          }

          allLayers[i].setVisible(true);
          allLayers[i].set('checked', true);
          this.layerName.push(allLayers[i].getProperties().title);
        } else {
          allLayers[i].setVisible(false);
          allLayers[i].set('checked', false);
        }
      }
    }
    return this.layersId;
  }

  //Timer For Activity Logging
  startTimer() {
    const pageName = this.currentPageName;
    if (this.startStopTimes[pageName] === undefined) {
      this.startStopTimes[pageName] = [];
    } else {
      const arrayOfTimes = this.startStopTimes[pageName];
      const latestStartStopEntry = arrayOfTimes[arrayOfTimes.length - 1];
      if (
        latestStartStopEntry !== undefined &&
        latestStartStopEntry.stopTime === undefined
      ) {
        // Can't start new timer until previous finishes.
        return;
      }
    }
    this.startStopTimes[pageName].push({
      startTime: new Date(),
      stopTime: undefined,
    });
  }
  stopTimer() {
    const pageName = this.currentPageName;
    const arrayOfTimes = this.startStopTimes[pageName];
    if (arrayOfTimes === undefined || arrayOfTimes.length === 0) {
      // Can't stop timer before you've started it.
      return;
    }
    if (arrayOfTimes[arrayOfTimes.length - 1].stopTime === undefined) {
      arrayOfTimes[arrayOfTimes.length - 1].stopTime = new Date();
    }
  }

  getTimeOnPage() {
    this.startTimer();
    const arrayOfTimes = this.startStopTimes[this.currentPageName];
    if (arrayOfTimes === undefined) {
      // Can't get time on page before you've started the timer.
      return;
    }
    for (let i = 0; i < arrayOfTimes.length; i++) {
      const startTime = arrayOfTimes[i].startTime;
      this.stopTime = arrayOfTimes[i].stopTime;
      if (this.stopTime === undefined) {
        this.stopTime = new Date();
      }
      const difference = this.stopTime - startTime;
      this.timeSpentOnPageInSeconds += difference / 1000;
    }
    this.totalTimeOnPage = Number(this.timeSpentOnPageInSeconds);
    return this.totalTimeOnPage;
  }
  activityLoging(eventname: any, ip: any) {
    const body = {
      map_id: this.mapId.toString(),
      event: 'Click',
      page: this.pageUrl,
      basemap: this.shareCurrentMap,
      event_name: eventname,
      ip: ip,
      time: this.getTimeOnPage().toString(),
      point: '',
      wkt: '',
    };
    this.activityLogs(body).subscribe(
      () => {
        // console.log('a');
      },
      (error: any) => {
        if (error.status == 403) {
          location.reload();
        }
      }
    );
  }
}
