import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-share-expired-modal',
  templateUrl: './live-share-expired-modal.component.html',
  styleUrls: ['./live-share-expired-modal.component.css']
})
export class LiveShareExpiredModalComponent implements OnInit {

  constructor() {
    //
   }

  ngOnInit(): void {
    //
  }

}
