import { Component, OnDestroy, OnInit } from '@angular/core';
import { TabService } from 'src/app/services/tab/tab.service';
import { TabItem } from '../../shared/models/tab/tab.model';

@Component({
  selector: 'app-table-tabs',
  templateUrl: './table-tabs.component.html',
  styleUrls: ['./table-tabs.component.css'],
})
export class TableTabsComponent implements OnInit, OnDestroy {
  showLayersButton = false;
  hideTab: any = false;
  tabs: TabItem[] | undefined;
  selectedTab = 0;
  constructor(public tabService: TabService) {}

  ngOnInit(): void {
    // Tab State Handling
    const state = this.tabService.getState();
    // Set to 0 if tabState not present
    this.selectedTab = state.tabState || 0;

    if (state.tabState === 1) {
      this.showLayersButton = true;
    } else {
      this.showLayersButton = false;
    }
    // Show State
    this.tabService.activeTab$.subscribe(() => {
      // console.log('active tab set: ', tab);
    });
    this.tabs = this.tabService.getGlobalTabs();
  }
  tabSelected(tab: TabItem) {
    this.tabService.activeTab$.next(tab);
    this.tabService.hideTab = true;
  }

  setTabState(tabId: number): void {
    this.selectedTab = tabId;
    this.tabService.setState({ tabState: tabId });
    if (tabId === 1) {
      this.showLayersButton = true;
    } else {
      this.showLayersButton = false;
    }
  }

  ngOnDestroy() {
    this.tabService.setState({ tabState: this.selectedTab });
  }
}
