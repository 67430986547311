import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maps-button',
  templateUrl: './maps-button.component.html',
  styleUrls: ['./maps-button.component.css']
})
export class MapsButtonComponent implements OnInit {

  constructor() {
      //
  }

  ngOnInit(): void {
      //
  }

}
