import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MapServiceService } from '../../services/Map-service/map-service.service';

@Component({
  selector: 'app-dynamic-modal',
  templateUrl: './dynamic-modal.component.html',
  styleUrls: ['./dynamic-modal.component.css'],
})
export class DynamicModalComponent implements OnInit {
  html: any;
  dismiss: any;
  dismiss_message: any;
  id: any;
  ModalHTML: any;
  browser_version: any;
  check: any;
  index: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private mapService: MapServiceService
  ) { }

  ngOnInit(): void {
    this.html = this.data.all_data.html;
    this.dismiss = this.data.all_data.dismiss;
    this.id = this.data.all_data.id;
    this.dismiss_message = this.data.all_data.dismiss_message;
    this.browser_version = this.data.all_data.browser_version;
    this.index = this.data.index;
  }

  dismissUsers(id: any) {
    if (this.check) {
      this.mapService.dismissDynamicModal(id).subscribe(() => {
        this.mapService.closeDynamicModalThroughSubject(this.index);
      });
    } else {
      this.mapService.closeDynamicModalThroughSubject(this.index);
    }
  }

  onChange(evt: any) {
    this.check = evt.target.checked;
  }
}
