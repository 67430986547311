import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-glo-mod-maps',
  templateUrl: './glo-mod-maps.component.html',
  styleUrls: ['./glo-mod-maps.component.css']
})
export class GloModMapsComponent implements OnInit {

  constructor() {
    //gfhgfhf
   }

  ngOnInit(): void {
    //
  }


}
