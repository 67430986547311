<mat-sidenav-container class="example-container" [hasBackdrop]="false">
  <mat-sidenav
    opened
    [style.width.%]="sidenavWidth"
    id="left_panel"
    [ngStyle]="{ transition: 'all 0.6s linear , top 0s linear  ' }"
    #leftSidenav
    mode="over"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    >
    <mat-nav-list>
      <mat-list-item class="logo-heading">
        <h3 class="toolname">
          <img class="bottom" src="/assets/img/tools/layers.png" />
          Legend
        </h3>
      </mat-list-item>
      <!-- Component render here -->
      <mat-accordion multi>
        <!-- Static Annotation -->
        <mat-expansion-panel *ngIf="userAccessLevel === 500 && tools.annotator" (opened)="openAnnotation()"
          (closed)="closeAnnotation()" class="annotation" hideToggle="true">
          <mat-expansion-panel-header class="annotation_header">
            <mat-panel-title class="base"> Annotation </mat-panel-title>
            <mat-panel-description>
              <mat-icon class="arrow_icon" *ngIf="annot">arrow_drop_down</mat-icon>
              <mat-icon class="arrow_icon" *ngIf="!annot">arrow_drop_up</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="annotation_toggles accordion">
            <div class="annot-div">
              <img src="assets/img/tools/annotation_point.png" />
              <label for="pointCheck">Points<input (click)="showAnnotation('Point', $event)"
                  [(ngModel)]="annotCheckboxState.checked1" type="checkbox" id="pointCheck" />
              </label>
            </div>

            <div class="annot-div">
              <img src="assets/img/tools/annotation_line.png" />
              <label for="lineCheck">Lines<input (click)="showAnnotation('Line', $event)" name="line" class="show_lines"
                  id="lineCheck" type="checkbox" [(ngModel)]="annotCheckboxState.checked2" /></label>
            </div>
            <div class="annot-div polygon">
              <img src="assets/img/tools/annotation_polygon.png" />
              <label for="polyCheck">Polygons<input (click)="showAnnotation('Polygon', $event)" name="polygon"
                  class="show_polygons" type="checkbox" id="polyCheck" [(ngModel)]="annotCheckboxState.checked3" /></label>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Dynamic any layer and sub headings. -->

        <!-- (opened)="openBoundariesByHeading(l, key)"
        (closed)="closeBoundariesByHeading(l, key)" -->

        <mat-expansion-panel class="panel2 layers" #panelLayer *ngFor="let key of objectKeys(refineLayersData); index as l"
          [expanded]="panelHandler[key]" hideToggle="true" id="panel{{l}}" (opened)="panelHandler[key] = key"
          (closed)="panelHandler[key] = null">
          <!-- [tooltip]="refineLayersData[key].headingToolTip" [options]="myOptions" -->
          <mat-expansion-panel-header (click)=" onCtrlKey($event,l,key)">
            <mat-panel-title style="padding-bottom: 5px !important" class="base">
              {{ key }}
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon class="arrow_icon" *ngIf="panelHandler[key]">arrow_drop_down</mat-icon>
              <mat-icon class="arrow_icon" *ngIf="!panelHandler[key]">arrow_drop_up</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="accordion">
            <ng-container *ngFor="let key1 of objectKeys(refineLayersData[key]); index as i">
              <ng-container *ngIf="key1 === 'layers'">
                <ul id="Boundaries">
                  <ng-container *ngFor="let data of refineLayersData[key][key1]; index as m">
                    <li zoom="15" class="overlay-li">
                      <span class="legend-icon" [ngStyle]="{
                          'background-image':
                          objectKeys(data.style).length ===0
                              ? 'url(' + data.legend.icon + ')'
                              : 'url(' +
                                data.style[sideNavService.currentLoadedMap].icon +
                                ')'
                        }"></span>
                      <label>
                        <p data-name="Property Data" class="legend-name mytooltip" [tooltip]="data.tool_tip"
                          [options]="myOptions" [ngClass]="
                            currentZoom < data.zoomlevel ? 'disabled' : 'enabled'
                          ">
                          {{ data.name }} <span class="tooltiptext"></span>
                        </p>
                        <input *ngIf="!data.legend.group" (change)="onChangeMapLayer($event, data.name, 1, key, data.id)"
                          type="checkbox" value="{{ data.id }}" id="{{ 'layer' + m }}" [(ngModel)]="data.map.visible"
                           [ngStyle]="{'opacity': currentZoom < data.zoomlevel ? '0.3' : '1'}" />
                        <input  name="radioBtn0" *ngIf="data.legend.group" (change)="onChangeMapLayer($event, data.name, 2, key, data.id,data)"
                          type="radio" [value]="true" id="{{ 'layer' + m }}" [(ngModel)]="data.map.visible"
                          [checked]="data.map.visible===true"
                           [ngStyle]="{'opacity': currentZoom < data.zoomlevel ? '0.3' : '1'}" />
                      </label>
                      <span class="zoom-to-style zoom-into-see" *ngIf="currentZoom < data.zoomlevel">ZOOM TO SEE</span><span
                        class="zoom-to-style zoom-out-see">ZOOM OUT TO SEE</span>
                      <span class="zoom-to-style max_zoom_to">MAX ZOOM REACHED</span>
                    </li>
                  </ng-container>
                </ul>
              </ng-container>
              <!-- (opened)="openBoundariesBySubGroup(i + l, key1, key)" (closed)="closeBoundariesBySubGroup(i + l, key1)" -->
              <ng-container *ngIf="key1 !== 'layers'">
                <mat-expansion-panel style="background-color: none !important" hideToggle="true"
                  [expanded]="panelHandler[key1]" class="subExpansionPanel" (opened)="panelHandler[key1]=key1"
                  (closed)="panelHandler[key1] = null" (click)=" onCtrlKeySubHeading($event,i + l,key,key1)">
                  <mat-expansion-panel-header style="
                      background-color: transparent !important;
                      padding-left: 0px !important;
                      width: 100% !important;
                    ">
                    <ng-container *ngFor="let data1 of refineLayersData[key][key1]; index as m">
                      <ng-container *ngIf="m === 0">
                        <mat-panel-title class="base subdropdown" [tooltip]="data1.legend.class_group_tooltip"
                          [options]="myOptions3" style="margin-right: 51px !important">
                          {{ key1 }}
                        </mat-panel-title>
                      </ng-container>
                    </ng-container>
                    <mat-panel-description style="margin-right: 2px !important">
                      <mat-icon class="arrow_icon sub_icon subArrowIcon" *ngIf="panelHandler[key1]">arrow_drop_down</mat-icon>
                      <mat-icon class="arrow_icon sub_icon subArrowIcon" *ngIf="!panelHandler[key1]">arrow_drop_up</mat-icon>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <ul *ngFor="let data1 of refineLayersData[key][key1]" id="Boundaries">
                    <li zoom="15" class="overlay-li submenuUl">
                      <span class="legend-icon" *ngIf="data1.name !== 'Parcels'" [ngStyle]="{
                          'background-image': 'url(' + data1.legend.icon + ')'
                        }"></span>
                      <span class="legend-icon" *ngIf="data1.name === 'Parcels'" [ngStyle]="{
                          'background-image':
                            'url(' +
                            data1.style[sideNavService.currentLoadedMap].icon +
                            ')'
                        }"></span>
                      <label>
                        <p data-name="Property Data" class="legend-name mytooltip" [tooltip]="data1.tool_tip"
                          [options]="myOptions2" [ngClass]="
                            currentZoom < data1.zoomlevel ? 'disabled' : 'enabled'
                          ">
                          {{ data1.name }} <span class="tooltiptext"></span>
                        </p>

                        <input *ngIf="data1.legend.group" (change)="onChangeMapLayer($event, data1.name, 2, key1,data1.id,data1)"
                          type="radio" [value]="true" [(ngModel)]="data1.map.visible" [checked]="data1.map.visible===true"
                          class="inputSubMenu"
                          name="radioBtn"

                          [ngStyle]="{'opacity': currentZoom < data1.zoomlevel ? '0.3' : '1'}" />

                        <input *ngIf="!data1.legend.group" (change)="onChangeMapLayer($event, data1.name, 1 , key, data1.id)"
                          type="checkbox" value="{{ data1.id }}" [(ngModel)]="data1.map.visible"
                          [ngStyle]="{'opacity': currentZoom < data1.zoomlevel ? '0.3' : '1'}"
                          [ngModelOptions]="{ standalone: true }" class="inputSubMenu" />
                      </label>
                      <span class="zoom-to-style zoom-into-see" *ngIf="currentZoom < data1.zoomlevel">ZOOM TO SEE</span><span
                        class="zoom-to-style zoom-out-see">ZOOM OUT TO SEE</span>
                      <span class="zoom-to-style max_zoom_to">MAX ZOOM REACHED</span>
                    </li>
                  </ul>
                </mat-expansion-panel>
              </ng-container>
            </ng-container>
          </div>
        </mat-expansion-panel>
        <!--Static BaseMaps Accordion -->
        <mat-expansion-panel  hideToggle="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="base"> Base </mat-panel-title>
            <mat-panel-description>
              <mat-icon class="arrow_icon" *ngIf="baseMapsState">arrow_drop_down</mat-icon>
              <mat-icon class="arrow_icon" *ngIf="!baseMapsState">arrow_drop_up</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="accordion">
            <ul id="Base" *ngFor="let map of sortDisplayedMap; let i = index">
              <li *ngIf="map.status" zoom="undefined" class="overlay-li mapClass" style="min-height: 0px !important">
                <span></span><label style="max-width: 100% !important; margin-left: 10px !important">
                  <p style="color: #333 !important" data-name="NAIP 2020" class="legend-name mytooltip _tooltip"
                    [tooltip]="map.tool_tip" [options]="myOptions1"
                    [ngClass]="currentZoom > map.zoom ? 'disabled' : 'enabled'">
                    {{ map.name
                    }}<span id="basemap_tooltip" class="tooltiptext"></span>
                  </p>
                  <input (change)="onChangeBaseMap($event, map.title, map.name)" default-visibility="false" type="radio"
                    name="baselayer" [disabled]="currentZoom > map.zoom" [checked]="map.activeMap === true" />
                </label><span *ngIf="currentZoom > map.zoom" class="zoom-to-style zoom-out-see-map">ZOOM OUT TO SEE</span>
                <span *ngIf="currentZoom === map.zoom && map.activeMap === true" class="zoom-to-style max_zoom_to-map">MAX
                  ZOOM REACHED</span>
              </li>
            </ul>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- Component render here  end -->
    </mat-nav-list>
    <ngx-spinner [fullScreen]="true" type="" size="medium" bdColor="rgba(0, 0, 0, 0)">
      <p class="loading" > <img src="/assets/img/wheel.svg" alt="Girl in a jacket" width="100px" height="100px" > </p>
      </ngx-spinner>
    </mat-sidenav
  >

</mat-sidenav-container>

