import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-share-expired-modal-wv',
  templateUrl: './live-share-expired-modal-wv.component.html',
  styleUrls: ['./live-share-expired-modal-wv.component.css']
})
export class LiveShareExpiredModalWvComponent implements OnInit {

  constructor() { 
    //
  }

  ngOnInit(): void {
    //
  }

}
