import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { secControl } from 'src/app/modules/shared/models/securityControls/securityControls';
import { TabItem } from 'src/app/modules/shared/models/tab/tab.model';
import { SecurityControlsService } from 'src/app/services/Security/security-controls.service';
import { SnackbarService } from 'src/app/services/snackbarService/snackbar.service';
import { TabService } from 'src/app/services/tab/tab.service';
@Component({
  selector: 'app-security-controls',
  templateUrl: './security-controls.component.html',
  styleUrls: ['./security-controls.component.css'],
})
export class SecurityControlsComponent implements OnInit {
  securityData: secControl = new secControl();
  tabs: TabItem[] | undefined;
  onCap = 'on';
  offCap = 'off';
  onOtp = 'on';
  offOtp = 'off';
  auto = 'auto';
  auto1 = 'auto';
  constructor(
    public SecurityService: SecurityControlsService,
    public _snackbar: SnackbarService,
    public router: Router,
    public tabService: TabService
  ) {}

  ngOnInit(): void {
    this.tabService.activeTab$.subscribe(() => {
      // console.log('active tab set: ', tab);
    });
    this.tabs = this.tabService.getTabsLayer();
    this.tabService.activeTab$.next(this.tabs[7]);
    this.SecurityService.getcontrols().subscribe((res: any) => {
      if (res.controls.google_captcha === 'on') {
        this.securityData.visibilityCap = 'on';
      } else if (res.controls.google_captcha === 'auto') {
        this.securityData.visibilityCap = 'auto';
      } else {
        this.securityData.visibilityCap = 'off';
      }
      if (res.controls.otp === 'on') {
        this.securityData.visibilityOtp = 'on';
      } else if (res.controls.otp === 'auto') {
        this.securityData.visibilityOtp = 'auto';
      } else {
        this.securityData.visibilityOtp = 'off';
      }
    });
  }
  cap(event: any) {
    switch (event) {
      case 'on':
        this.securityData.visibilityCap = 'on';
        break;
      case 'auto':
        this.securityData.visibilityCap = 'auto';
        break;
      default:
        this.securityData.visibilityCap = 'off';
        break;
    }
  }
  otp(event: any) {
    switch (event) {
      case 'on':
        this.securityData.visibilityOtp = 'on';
        break;
      case 'auto':
        this.securityData.visibilityOtp = 'auto';
        break;
      default:
        this.securityData.visibilityOtp = 'off';
        break;
    }
  }
  submitForm() {
    const body = {
      otp: this.securityData.visibilityOtp,
      google_captcha: this.securityData.visibilityCap,
    };
    this.SecurityService.setSecurity(body)
      .pipe(first())
      .subscribe(() => {
        this._snackbar.openSnackBar('Security Controls Save successfully');
      });
  }
  tabSelected(tab: TabItem) {
    this.tabService.activeTab$.next(tab);
  }
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    if (tabChangeEvent.index == 6) {
      this.router.navigateByUrl('global');
    } else if (tabChangeEvent.index == 7) {
      this.router.navigateByUrl('securityControl');
    } else if (tabChangeEvent.index == 8) {
      this.router.navigateByUrl('plans');
    } else if (tabChangeEvent.index == 9) {
      this.router.navigateByUrl('subscribePages');
    }
  };
}
