import { Component, OnDestroy, OnInit } from '@angular/core';
import { TabItem } from '../../models/tab/tab.model';
import { TabService } from 'src/app/services/tab/tab.service';

@Component({
  selector: 'app-courtesy-tabs',
  templateUrl: './courtesy-tabs.component.html',
  styleUrls: ['./courtesy-tabs.component.css'],
})
export class CourtesyTabsComponent implements OnInit, OnDestroy {
  tabs: TabItem[] | undefined;
  selectedTab = 0;
  constructor(public tabService: TabService) {}

  ngOnInit(): void {
    // Tab State Handling
    const state = this.tabService.getState();
    // Set to 0 if tabState not present
    this.selectedTab = state.tabState || 0;
    // Show State
    this.tabService.activeTab$.subscribe(() => {
      // console.log('active tab set: ', tab);
    });
    this.tabs = this.tabService.getCourtesyTabs();
  }
  tabSelected(tab: TabItem) {
    this.tabService.activeTab$.next(tab);
    this.tabService.hideTab = true;
  }

  setTabState(tabId: number): void {
    this.selectedTab = tabId;
    this.tabService.setState({ tabState: tabId });
  }

  ngOnDestroy() {
    this.tabService.setState({ tabState: this.selectedTab });
  }
}
