import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SideNavService } from '../../services/sidenav/side-nav.service';
import { MapServiceService } from '../../services/Map-service/map-service.service';

@Component({
  selector: 'app-query-limit',
  templateUrl: './query-limit.component.html',
  styleUrls: ['./query-limit.component.css'],
})
export class QueryLimitComponent implements OnInit {
  limit:any
  message: any
  constructor(private dialog: MatDialog,private sideNavService:SideNavService,private mapSerice: MapServiceService) {
    //
  }

  ngOnInit(): void {
   this.limit = this.sideNavService.searchLimit
   this.message = this.mapSerice.getAllModals.query_limit_template
  }
  close(): void {
    this.dialog.closeAll();
  }
}
