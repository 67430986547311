import { Component, OnInit } from '@angular/core';
import WKT from 'ol/format/WKT';

import { Icon, Stroke, Style } from 'ol/style';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UpdateCaptureComponent } from '../update-capture/update-capture.component';
import { DeleteCaptureComponent } from '../delete-capture/delete-capture.component';
import { ErrorCaptureToolModelComponent } from '../error-capture-tool-model/error-capture-tool-model.component';
import { CaptureToolService } from 'src/app/modules/map/services/Capture-Tool/capture-tool.service';
import { MapServiceService } from 'src/app/modules/map/services/Map-service/map-service.service';
import { SideNavService } from 'src/app/modules/map/services/sidenav/side-nav.service';

@Component({
  selector: 'app-edit-feature-capture',
  templateUrl: './edit-feature-capture.component.html',
  styleUrls: ['./edit-feature-capture.component.css'],
})
export class EditFeatureCaptureComponent implements OnInit {
  form = new FormGroup({});
  featureName: any;
  feature: any;
  feature1: any;
  resultLayer: any;
  keys: any;
  mapId: any;
  layerId: any;
  apn: any;
  boundaries: any;
  captureData: any;
  editCaptureData: any = [];
  delCaptureFeature: any;
  editID: any;
  val: any;
  formArr: any;
  key: any;
  formTemplate: any;
  userGroup: any = {};
  captureLayerId: any;
  fields: any;
  fieldsData: any;
  captureFeatureName: any;
  controls: any = [];
  editControls: any = [];
  editData: any = [];
  captureLayerName: any
  editIcon: any;
  layerIcon: any;
  deleteCapture = '<span>Delete this feature.</span>';
  save = '<span>Save</span>';
  myOptions12 = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class12',
  };
  myOptions13 = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class12',
  };
  myOptions14 = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class14',
  };
  myOptions15 = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class15',
  };
  myOptions16 = {
    showDelay: 500,
    'tooltip-class': 'new-tooltip-class14',
  };
  constructor(
    public sideNavService: SideNavService,
    public mapService: MapServiceService,
    public dialog: MatDialog,
    public captureToolService: CaptureToolService
  ) {}

  ngOnInit(): void {
    this.editIcon = this.mapService.featureIcon;
    this.captureLayerName = this.mapService.captureLayer.name
    this.featureName = this.sideNavService.dataPassToRightPanel;
    this.mapService.parcelKey.subscribe((res: any) => {
      this.key = res;
      if (this.key === 'z') {
        this.clickToZoom();
      }
    });

    this.mapId = this.mapService.mapData.map_data.map_data.id.toString();
    this.boundaries = this.mapService.mapData.map_data.map_data.layers;
    this.mapService.captureFeature.subscribe((res: any) => {
      this.val = res;
    });
    this.mapService.result.getSource().clear();
    this.drawFeature();
  }
  drawFeature() {
    const source = this.mapService.captureVectorLayer.getSource();
    const features = source.getFeatures();
    features.forEach((feature: any) => {
      source.removeFeature(feature);
    });
    this.sideNavService.clickedInfo(0);
    this.captureFeatureName = this.featureName;
    this.layerIcon = this.featureName?.layer_id;
    this.editID = this.captureFeatureName.id;
    this.captureLayerId = this.captureFeatureName.layer_id;

    if (this.captureFeatureName) {
      for (const keyData of Object.keys(this.captureFeatureName.data)) {
        for (const keyFields of Object.keys(this.captureFeatureName.fields)) {
          if (keyData == keyFields) {
            this.editCaptureData.push({
              id: keyFields,
              name: this.captureFeatureName.fields[keyFields],
              data: this.captureFeatureName.data[keyData],
            });
          }
        }
      }
      for (let j = 0; j < this.boundaries.length; j++) {
        if (this.boundaries[j].id == +this.captureFeatureName.layer_id) {
          for (let i = 0; i < this.boundaries[j].fields.length; i++) {
            for (const keyFields of Object.keys(
              this.captureFeatureName.fields
            )) {
              if (this.boundaries[j].fields[i].id == +keyFields) {
                for (let q = 0; q < this.editCaptureData.length; q++) {
                  if (this.editCaptureData[q].id == +keyFields) {
                    this.editCaptureData[q].type =
                      this.boundaries[j].fields[i].type;
                    this.editCaptureData[q].dropdown =
                      this.boundaries[j].fields[i].dropdown;
                    this.editCaptureData[q].dropdowns =
                      this.boundaries[j].fields[i].dropdowns;
                    this.editCaptureData[q].visible =
                      this.boundaries[j].fields[i].visible;
                  }
                }
              }
            }
          }
        }
      }
      for (const config of this.editCaptureData) {
        this.userGroup[config.name] = new FormControl(config.value || '');
      }
      this.form = new FormGroup(this.userGroup);
      this.mapService.dataHistroy.push(this.captureFeatureName);
      this.layerId = this.captureFeatureName.layer_id;
    }
    const f = this.captureFeatureName;
    const format = new WKT();
    this.feature = format.readFeature(f.ctrd_wkt);
    this.feature.set('type', 'point');
    this.feature.set('id', f.id);
    this.feature.set('layerId', f.layer_id);
    const style = new Style({
      image: new Icon({
        src: '/assets/img/marker.png',
        imgSize: [80, 150],
        offset: [5, 2],
        scale: 0.5,
        opacity: 1.0,
      }),
    });
    this.feature.setStyle(style);

    this.mapService.listResult.addFeatures([this.feature]);
    this.feature1 = format.readFeature(f.geom_wkt);
    this.feature1.set('type', 'polygon');
    this.feature1.set('id', f.id);
    this.feature1.set('layerId', f.layer_id);
    const polystyle = new Style({
      // fill: new Fill({
      //   color: 'rgb(255, 204, 51,0.3)',
      // }),
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
    });
    this.feature1.setStyle(polystyle);
    this.mapService.selectedParcels.push(this.feature1);
    this.mapService.listResult.addFeatures([this.feature1]);
    this.sideNavService.sideNavState('open');
  }

  edit() {
    const fields: any = {};
    for (let i = 0; i < this.editCaptureData.length; i++) {
      fields[`field${this.editCaptureData[i].id}`] =
        this.editCaptureData[i].data;
    }
    const body: any = {
      lid: this.captureLayerId,
      fid: this.editID.toString(),
      fields: fields,
      map_id: parseInt(this.mapId),
      share_id: this.mapService.shareId.toString() || '',
    };

    this.captureToolService
      .updateCaptureFeature(body)
      .subscribe((res: any) => {
        if (res.error) {
          this.dialog.open(ErrorCaptureToolModelComponent, {
            position: {
              top: '25%',
            },
            data: {
              title: res.error,
            },
          });
        } else {
          this.dialog.open(UpdateCaptureComponent, {
            disableClose: true,
          });
        }
      });
  }
  delete() {
    const body: any = {
      lid: this.captureLayerId,
      fid: this.editID.toString(),
      map_id: parseInt(this.mapId),
      share_id: this.mapService.shareId.toString() || '',
    };
    this.captureToolService.deleteCaptureFeature(body).subscribe((res: any) => {
      if (res) {
        this.mapService.deleteCaptueFeature(
          (this.delCaptureFeature = res.feature_deleted[0])
        );
        this.dialog.open(DeleteCaptureComponent, {
          disableClose: true,
        });
      }
    });
  }
  closeRightpanel() {
    this.mapService.mapLayer.removeInteraction(this.captureToolService.drawFeature);

    this.sideNavService.closeRightSideNav();
    if (this.mapService.result) {
      this.mapService.result.getSource().clear();
    }
    const source = this.mapService.captureVectorLayer.getSource();
    const features = source.getFeatures();
    features.forEach((feature: any) => {
      source.removeFeature(feature);
    });
    this.sideNavService.sideNavState('close');
    this.sideNavService.infoSideNav('closed');
    this.captureToolService.clickedIndex = null
    this.mapService.selectLayer = false
  }
  goBack() {
    this.sideNavService.isLoadedFeature('Featuresdasda');
    this.sideNavService.maintainHistory();
  }
  clickToZoom() {
    const features = this.mapService.listResult.getFeatures();
    for (let i = 0; i < features.length; i++) {
      if (features[i].get('type') === 'polygon') {
        const extent = features[i].getGeometry().getExtent();
        this.mapService.mapLayer
          .getView()
          .fit(extent, this.mapService.mapLayer.getSize());
      }
    }
  }
}
