import { cloneData } from './../../modules/shared/models/clone-layer/cloneLayer';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CloneLayersService {

  constructor(private http: HttpClient,) { }

  //Create Clone Layer
  public createCloneLayer(body: any): Observable<cloneData> {
    return this.http.post<cloneData>(`/glomod/clone-layer/`, body).pipe(
      shareReplay()
    );
  }
}
