import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlansService } from 'src/app/services/plan/plans.service';
import { SnackbarService } from 'src/app/services/snackbarService/snackbar.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent implements OnInit {
  public productForm!: FormGroup;
  isDataLoaded = false;
  productList: any = [];
  products: any = {};
  updatePriceObject: any = {};

  constructor(
    private fb: FormBuilder,
    private service: PlansService,
    private spinner: NgxSpinnerService,
    public _snackbar: SnackbarService
  ) {
    this.productForm = this.fb.group({
      products: this.fb.array([]),
    });
  }
  private addProductGroup(product: any): FormGroup {
    return this.fb.group({
      annualPrice: [product.annual.price],
      annualCount: [{ value: product.annual.count, disabled: true }],
      annualPlanCode: [
        { value: this.annualPlanCodes(product.annual.plans), disabled: true },
      ],
      monthlyPrice: [product.monthly.price],
      monthlyCount: [{ value: product.monthly.count, disabled: true }],
      monthlyPlanCode: [
        { value: this.monthlyPlanCodes(product.monthly.plans), disabled: true },
      ],
      dayPrice: [product.day_pass.price],
      dayCount: [{ value: product.day_pass.count, disabled: true }],
      dayPlanCode: [
        { value: this.dayPlanCodes(product.day_pass.plans), disabled: true },
      ],
    });
  }

  annualPlanCodes(plans: any) {
    const planCodeList: any[] = [];
    plans.forEach((item: any) => {
      planCodeList.push(item.plan_code);
    });
    return planCodeList;
  }
  monthlyPlanCodes(plans: any) {
    const planCodeList: any[] = [];
    plans.forEach((item: any) => {
      planCodeList.push(item.plan_code);
    });
    return planCodeList;
  }
  dayPlanCodes(plans: any) {
    const planCodeList: any[] = [];
    plans.forEach((item: any) => {
      planCodeList.push(item.plan_code);
    });
    return planCodeList;
  }

  addProduct(product: any): void {
    this.productArray.push(this.addProductGroup(product));
  }
  get productArray(): FormArray {
    return <FormArray>this.productForm.get('products');
  }

  ngOnInit(): void {
    this.spinner.show();
    this.service.getProducts().subscribe((res: any) => {
      this.products = res.products;
      this.productList = Object.keys(this.products);
      this.createProductsForm();
    });
    this.spinner.hide();
  }
  createProductsForm() {
    for (let i = 0; i < this.productList.length; i++) {
      const product = this.products[this.productList[i]];
      this.addProduct(product);
    }
    this.isDataLoaded = true;
  }
  onSubmit() {
    this.spinner.show();
    for (let i = 0; i < this.productList.length; i++) {
      const product = this.products[this.productList[i]];
      this.updatePriceObject[this.productList[i]] = {
        annual: { plans: [], old_price: 0, new_price: 0 },
        monthly: { plans: [], old_price: 0, new_price: 0 },
        day_pass: { plans: [], old_price: 0, new_price: 0 },
      };
      this.changeInPrice(product, i);
    }
    this.service.changeProductPrice(this.updatePriceObject).subscribe(() => {
      this._snackbar.openSnackBar('Price updated successfully');
      this.ngOnInit();
    });
  }
  changeInPrice(product: any, productNo: number) {
    const productDetails = this.productForm.value.products[productNo];
    if (product.annual.price !== productDetails.annualPrice) {
      this.updatePriceObject[this.productList[productNo]].annual.old_price =
        product.annual.price;
      this.updatePriceObject[this.productList[productNo]].annual.new_price =
        productDetails.annualPrice;
      this.updatePriceObject[this.productList[productNo]].annual.plans =
        this.annualPlanCodes(product.annual.plans);
    } else {
      this.updatePriceObject[this.productList[productNo]].annual = {};
    }
    if (product.monthly.price !== productDetails.monthlyPrice) {
      this.updatePriceObject[this.productList[productNo]].monthly.old_price =
        product.monthly.price;
      this.updatePriceObject[this.productList[productNo]].monthly.new_price =
        productDetails.monthlyPrice;
      this.updatePriceObject[this.productList[productNo]].monthly.plans =
        this.monthlyPlanCodes(product.monthly.plans);
    } else {
      this.updatePriceObject[this.productList[productNo]].monthly = {};
    }
    if (product.day_pass.price !== productDetails.dayPrice) {
      this.updatePriceObject[this.productList[productNo]].day_pass.old_price =
        product.day_pass.price;
      this.updatePriceObject[this.productList[productNo]].day_pass.new_price =
        productDetails.dayPrice;
      this.updatePriceObject[this.productList[productNo]].day_pass.plans =
        this.dayPlanCodes(product.day_pass.plans);
    } else {
      this.updatePriceObject[this.productList[productNo]].day_pass = {};
    }
  }
}
