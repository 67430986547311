import { SnackbarService } from './../../../../../../services/snackbarService/snackbar.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { showFields } from 'src/app/modules/shared/models/showFields/showFields';
import { TabItem } from 'src/app/modules/shared/models/tab/tab.model';
import { AuthService } from 'src/app/services/auth.service';
import { ShowFieldsService } from 'src/app/services/show-fields/show-fields.service';
import { TabService } from 'src/app/services/tab/tab.service';
import { first } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-fields',
  templateUrl: './add-fields.component.html',
  styleUrls: ['./add-fields.component.css'],
})
export class AddFieldsComponent implements OnInit {
  user: any;
  product: any;
  tabs: TabItem[] | undefined;
  layerName!: string;
  //for drag drop in table
  @ViewChild('table') table: any;
  apMapFeed: any
  //datasource for table
  dataSource: any;
  fieldsData: showFields[] = [];

  //data fetching from backend
  backendDummyData: showFields = new showFields();
  backendData: showFields = new showFields();
  //drop down for Type
  type: any = [
    { type: 'Date', value: 'Date' },
    { type: 'Date + Time', value: 'Date + Time' },
    { type: 'Decimal', value: 'Decimal' },
    { type: 'Hyperlink', value: 'Hyperlink' },
    { type: 'Integer', value: 'Integer' },
    { type: 'Text Line', value: 'Text Line' },
    { type: 'Text Block', value: 'Text Block' },
    { type: 'Formatted Text', value: 'Formatted Text' },
  ];

  //drop down for Visibility,Queryability,Editability,Auto Complete,Search,Capture
  accessLevel: any = [
    { type: 'Deleted', value: '-999' },
    { type: 'Public', value: '0' },
    { type: 'Client', value: '100' },
    { type: 'Reader', value: '200' },
    { type: 'Editor', value: '300' },
    { type: 'Admin', value: '400' },
    { type: 'Demigod', value: '500' },
    { type: 'Nobody', value: '999' },
  ];

  //mat table header
  displayedColumns: any = [
    'position',
    '+',
    'name',
    'dbColumn',
    'type',
    'unit',
    'visibility',
    'queryability',
    'editability',
    'autoComplete',
    'search',
    'toolTip',
    'capture',
    'dropdown',
  ];
  constructor(
    private authService: AuthService,
    public router: Router,
    public tabService: TabService,
    public showFieldService: ShowFieldsService,
    public _snackbar: SnackbarService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.showFieldService.layerdata = localStorage.getItem('layerData');
    if (this.showFieldService.layerdata) {
      this.product = JSON.parse(this.showFieldService.layerdata);
      this.backendDummyData.layer = this.product.id;
      this.apMapFeed = `/layers/${this.product.id}/apmapfeed`;
      this.layerName = this.product.name;
      this.showFieldService
        .getAddFieldsById(this.product.id)
        .pipe(first())
        .subscribe({
          next: (res: any) => {
            this.dataSource = res.data;
          },
          error: () => {
            this._snackbar.openSnackBar('Something went wrong');
          },
        });
    }
  }

  dropTable(event: CdkDragDrop<any[]>) {
    const prevIndex = this.dataSource.findIndex(
      (d: any) => d === event.item.data
    );
    moveItemInArray(this.dataSource, prevIndex, event.currentIndex);
    this.table.renderRows();
  }
  addItem(): void {
    const body = {
      data: this.fieldsData,
    };
    this.spinner.show();
    this.showFieldService
      .saveAddFields(this.backendDummyData.layer, body)
      .pipe(first())
      .subscribe({
        next: () => {
          this.spinner.hide();
          this._snackbar.openSnackBar('Fields Created Successfully');
          this.router.navigate(['/showFields']);
        },
        error: () => {
          this.spinner.hide();
          this._snackbar.openSnackBar('Something went wrong');
        },
      });
  }
  getField(fields: any) {
    const index = this.fieldsData.indexOf(fields);
    if (index != -1) {
      this.fieldsData.splice(index, 1);
    } else {
      this.fieldsData.push(fields);
    }
  }
  logout() {
    this.authService.logout();
    window.location.href = `${environment.operatorDashboardUrl}/login/`;
  }
}
