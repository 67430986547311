import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardServiceService } from 'src/app/modules/dashboard/services/dashboard-service.service';
import { MapServiceService } from 'src/app/modules/map/services/Map-service/map-service.service';
import { AuthService } from 'src/app/services/auth.service';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-glo-mod-login',
  templateUrl: './glo-mod-login.component.html',
  styleUrls: ['./glo-mod-login.component.css'],
})
export class GloModLoginComponent implements OnInit {
  token = '';
  slug = '';
  userInfo: any;
  security: any;
  encrypted: any;
  message = 'SuperSecret!!';
  constructor(
    private route: ActivatedRoute,
    public mapServices: MapServiceService,
    public dashboardService: DashboardServiceService,
    private auth: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) {
    //
  }

  ngOnInit(): void {
    localStorage.clear();
    this.token = this.route.snapshot.paramMap.get('token') || '';
    this.security = this.route.snapshot.paramMap.get('security') || '';
    this.mapServices.getClientInfo(this.token).subscribe((res: any) => {
      localStorage.setItem('isVerified', 'true');
      this.userInfo = res;
      this.encrypted = CryptoJS.AES.encrypt(
        this.userInfo.user.access_level.toString(),
        this.message
      );
      if (!res.message) {
        localStorage.setItem('userAccessLevel', this.encrypted);
      }

      this.auth.setToken(res);
      localStorage.setItem('redirect', 'true');
      switch (this.security) {
        case 'global':
          this.router.navigate(['/global']);
          break;
        case 'courtesy-account':
          this.router.navigate(['/courtesyAccount']);
          break;
        default:
          this.router.navigate(['/securityControl']);
          break;
      }
    });
  }
}
