import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-recurly-error',
  templateUrl: './recurly-error.component.html',
  styleUrls: ['./recurly-error.component.css']
})
export class RecurlyErrorComponent implements OnInit {
  html: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private global: GlobalService
  ) { }

  ngOnInit(): void {
    this.html = this.data.message;
  }

  dismissUsers() {
    this.global.messageModalRef.close();
  }
}
