import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-share-expired-modal-fdp',
  templateUrl: './live-share-expired-modal-fdp.component.html',
  styleUrls: ['./live-share-expired-modal-fdp.component.css']
})
export class LiveShareExpiredModalFdpComponent implements OnInit {

  constructor() {
    //
   }

  ngOnInit(): void {
    //
  }

}
