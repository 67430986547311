<div class="popup" id="export-popup" style="display: block">
  <div class="header">
    View and Export Data<a (click)="closeDialog()" class="close-popup"
      ><img id="newcros" src="/assets/img/tools/close-pv3.png"
    /></a>
  </div>
  <div class="content">
    <div id="opt-data-view">
      <ul class="nav nav-tabs">
        <li
          [tooltip]="'Data View'"
          [options]="myOptions"
          id="data-view"
          (click)="changeMenu(1)"
          [ngClass]="select === 1 ? 'active' : 'null'"
          *ngIf="dataview"
        >
          <a class="mytooltip"
            ><span
              (click)="changeMenu(1)"
              class="tooltiptext tip-bottom"
              style="margin-left: -13px; visibility: hidden; opacity: 0"
              >Data view</span
            >
            <b>Data View</b></a
          >
        </li>
        <li
          [tooltip]="mapService.templatetoolTips['labels']"
          [options]="myOptions1"
          *ngIf="mailLabels"
          id="lable-view"
          (click)="changeMenu(2)"
          [ngClass]="select === 2 ? 'active' : 'null'"
        >
          <a class="mytooltip"
            ><span
              id="lable_mod"
              class="tooltiptext tip-bottom"
              style="margin-left: -13px; visibility: hidden; opacity: 0"
              ><strong>Labels</strong> <br />Create a PDF of mailing labels in
              Avery 5160 format.<br
            /></span>
            <b>Mail Labels</b></a
          >
        </li>
        <li
          [tooltip]="mapService.templatetoolTips['pdf-table']"
          [options]="myOptions1"
          *ngIf="parcelPresent  && pdfView"
          id="table-view"
          [ngClass]="select === 3 ? 'active' : 'null'"
          (click)="changeMenu(3)"
          [ngClass]="select === 3 ? 'active' : 'null'"
        >
          <a class="mytooltip"
            ><span
              class="tooltiptext tip-bottom"
              style="margin-left: -13px; visibility: hidden; opacity: 0"
              ><strong>PDF Table</strong> <br />Create a PDF of this table to
              download.<br
            /></span>
            <b>PDF Table</b></a
          >
        </li>
        <li
          [tooltip]="mapService.templatetoolTips['csv']"
          [options]="myOptions1"
          id="CSV-view"
          class=""
          (click)="changeMenu(4)"
          [ngClass]="select === 4 ? 'active' : 'null'"
          *ngIf="csvView"
        >
          <a class="mytooltip"
            ><span
              class="tooltiptext tip-bottom"
              style="margin-left: -13px; visibility: hidden; opacity: 0"
              ><strong>CSV</strong> <br />Create a CSV spreadsheet of this table
              to download.<br
            /></span>
            <b>CSV File</b></a
          >
        </li>
        <!-- mapview now available for any selections -->
        <li
          [tooltip]="'Map View'"
          [options]="myOptions"
          id="Map-view"
          *ngIf=" loginUSer==='500'"
          (click)="changeMenu(5)"
          [ngClass]="select === 5 ? 'active' : 'null'"
        >
          <a class="mytooltip"
            ><span
              class="tooltiptext tip-bottom"
              style="margin-left: -13px; visibility: hidden; opacity: 0"
              >Map View</span
            ><b>Map View</b></a
          >
        </li>
      </ul>
    </div>
    <app-data-view *ngIf="select === 1" [data]="data"></app-data-view>
    <app-mail-labels *ngIf="select === 2"></app-mail-labels>
    <app-pdf-table *ngIf="select === 3"></app-pdf-table>
    <app-csv-file *ngIf="select === 4"></app-csv-file>
    <app-map-view *ngIf="select === 5"></app-map-view>
    <div id="tbl-data-view"></div>
  </div>
  <div class="footer"></div>
</div>
