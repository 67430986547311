<div class="main">
  <form [formGroup]="courtesyMapForm">
    <div formArrayName="mapsData">
      <div *ngFor="let employee of mapInfo().controls; let empIndex = index">
        <div [formGroupName]="empIndex" class="row">
          <div class="col-md-4">
            <select placeholder="Choose Map" name="maps" id="maps" formControlName="map_id">
              <option value="" disabled style="color: rgb(168, 162, 162);">Choose Map</option>
              <option *ngFor="let map of allMaps" value={{map.id}}>{{map.invite_display_name}}</option>
            </select>
          </div>
          <div class="col-md-2">
            <select name="clients" id="maps" formControlName="privilege">
              <option *ngFor="let level of accessList" value={{level.value}}>{{level.title}}</option>
            </select>
          </div>
          <div class="col-md-3">
            <p>Expiry
              <input type="checkbox" (click)="durationEnable(empIndex)" formControlName="expiryCheck">
              <input type="number" min="1" style="width: 50px;" formControlName="expiry_duration">
            </p>
          </div>
          <div class="col-md-2" style="text-align: center;">
            <img src="../../../../../../assets/img/trash.svg" (click)="removeMapInfo(empIndex)" alt="trash">
          </div>
          <!-- {{ empIndex }} First Name :
          <input type="text" formControlName="firstName" /> -->
          <!-- Last Name:
          <input type="text" formControlName="lastName" /> -->

        </div>
      </div>
      <img class="add-icon" src="../../../../../../assets/img/plus-border.png" alt="plus" (click)="addMaps()" />
    </div>
  </form>
</div>
<ngx-spinner [fullScreen]="true" type="" size="medium" bdColor="rgba(0, 0, 0, 0)">
  <p class="loading"> <img src="/assets/img/wheel.svg" alt="Girl in a jacket" width="100px" height="100px"> </p>
</ngx-spinner>