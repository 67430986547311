import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrePlanService {
  prePlanUpdate: Subject<any> = new Subject<any>();
  constructor() { 
    //
  }
 
  public refreshPrePlan(value: any) {
    this.prePlanUpdate.next(value);
  }
}
