import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';


import 'imask/esm/masked/number';
import { DashboardServiceService } from 'src/app/modules/dashboard/services/dashboard-service.service';
import { MapServiceService } from 'src/app/modules/map/services/Map-service/map-service.service';
import { SubscribErrorModalComponent } from 'src/app/modules/shared/components/modals/subscrib-error-modal/subscrib-error-modal.component';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-collaboorator-registertion',
  templateUrl: './collaboorator-registertion.component.html',
  styleUrls: ['./collaboorator-registertion.component.css']
})
export class CollabooratorRegistertionComponent implements OnInit {
  loginStatus: any;
  errorMsg: any
  id: any
  showForm: any
  userEmail: any
  deviceOption: any
  user: any = {
  };
  industry: any
  product = ''
  @ViewChild('collaborationForm') collaborationForm!: NgForm;
  constructor(private route: ActivatedRoute,
    private dashboardService: DashboardServiceService,
    private mapService: MapServiceService,
    public dialog: MatDialog, private router: Router,
    public auth: AuthService) {
    this.industry = this.dashboardService.getIndustryList();
  }

  ngOnInit(): void {
    this.product = this.route.snapshot.paramMap.get('product') || '';
    this.id = this.route.snapshot.paramMap.get('id') || '';
    localStorage.setItem('collId', this.id);
    this.userEmail = this.route.snapshot.paramMap.get('email') || '';
    this.user = {
      email: this.userEmail,
      device: 'Industry'
    };
    this.loginStatus = localStorage.getItem('auth_token');
  

    this.mapService.invitationVerification(this.id, this.userEmail).subscribe((res: any) => {
     
      if (res.success) {
        this.showForm = true
      } else {
        this.showForm = false
      }
    });

  }


  submit() {
   
    if (this.collaborationForm.value.device == undefined ||
      this.collaborationForm.value.device === '') {
      this.collaborationForm.value.device = 'Industry'
    }
    if (this.collaborationForm.value.phone == undefined ||
      this.collaborationForm.value.phone === '') {
      this.collaborationForm.value.phone = ''
    }
    if (this.collaborationForm.value.company == undefined ||
      this.collaborationForm.value.company === '') {
      this.collaborationForm.value.company = ''
    }
    if (
      this.collaborationForm.value.first_name == undefined ||
      this.collaborationForm.value.first_name === ''
    ) {
      this.dialog.open(SubscribErrorModalComponent, {
        panelClass: 'app-full-bleed-dialog',
        backdropClass: 'bdrop',
        position: {
          top: '10%',
        },
        data: {
          title: 'First name blank.',
          content: 'This field is required.',
        },
      });
      return;
    } else if (
      this.collaborationForm.value.last_name == undefined ||
      this.collaborationForm.value.last_name === ''
    ) {
      this.dialog.open(SubscribErrorModalComponent, {
        panelClass: 'app-full-bleed-dialog',
        backdropClass: 'bdrop',
        position: {
          top: '10%',
        },
        data: {
          title: 'Last name blank.',
          content: 'This field is required.',
        },
      });
      return;
    } else if (
      this.collaborationForm.value.new_password2 == undefined || this.collaborationForm.value.new_password2 === '' 
    ) {
      this.dialog.open(SubscribErrorModalComponent, {
        panelClass: 'app-full-bleed-dialog',
        backdropClass: 'bdrop',
        position: {
          top: '10%',
        },
        data: {
          title: 'Password blank.',
          content: 'This field is required.',
        },
      });
      return;
    } else if(this.collaborationForm.value.new_password1 == undefined || this.collaborationForm.value.new_password1 === '') {
      this.dialog.open(SubscribErrorModalComponent, {
        panelClass: 'app-full-bleed-dialog',
        backdropClass: 'bdrop',
        position: {
          top: '10%',
        },
        data: {
          title: 'Password blank.',
          content: 'This field is required.',
        },
      });
      return;
    }
     else {
      const body = {
        inviter_id: this.id,
        invitee_email: this.userEmail,
        first_name: this.collaborationForm.value.first_name,
        last_name: this.collaborationForm.value.last_name,
        password_1: this.collaborationForm.value.new_password1,
        password_2: this.collaborationForm.value.new_password2,
        phone_number: this.collaborationForm.value.phone,
        organization: this.collaborationForm.value.company,
        industry: this.collaborationForm.value.device,
      }
      this.mapService.createCollaborator(body).subscribe((res: any) => {
      
        if (res.success) {
          this.login();
        } else {
          this.dialog.open(SubscribErrorModalComponent, {
            panelClass: 'app-full-bleed-dialog',
            backdropClass: 'bdrop',
            position: {
              top: '10%',
            },
            data: {
              title: res.message,
              content: 'Try again',
            },
          });
        }
      })
    }

  }


  login() {
    const body = {
      email: this.userEmail,
      password: this.collaborationForm.value.new_password1
    }
    this.auth
      .login(body)
      .subscribe((result: any) => {
        if (!result.message) {
          this.auth.setToken(result);
          // localStorage.setItem('signUp', 'true');
          // localStorage.setItem('colSignUp', 'true');
          localStorage.setItem('firstTime', 'true');
          this.router.navigate(['/']);
        }
      });
  }
}
