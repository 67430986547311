export class createSubscribe {
    name!: string;
    slug!: string;
    logo!: string;
    header_title!: string;
    product_type!: string;
    body_title!: string;
    body_content!: string;
    map_text!: string;
    map_subtext!: string;
    monthly_text!: string;
    annual_text!: string;
    monthly_subtext!: string;
    annual_subtext!: string;
    day_text!: string;
    day_subtext!: string;
    collab_seats!: boolean;
    collab_seat_title!: string;
    collab_seat_content!: string;
    collab_monthly_text!: string;
    collab_annual_text!: string;
    outreach!: boolean;
    outreach_title!: string;
    outreach_content!: string;
    outreach_monthly_text!: string;
    outreach_annual_text!: string;
    footer!: string;
}