<div
  class="modal-content"
  style="border: solid 1px #aaaaaa; padding: 10px; height: auto; width: 650px"
>
  <div class="modal-body">
    <div class="modal-body1" [innerHtml]="html | sanitizeHtml"></div>

    <div class="div">
      <button
        id="dont-show-btn"
        type="button"
        class="btn gernal-btn"
        data-dismiss="modal"
        (click)="dismissUsers()"
      >
        OK
      </button>
    </div>
  </div>
</div>
