import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LayerService } from 'src/app/services/layer/layer.service';
import { SnackbarService } from 'src/app/services/snackbarService/snackbar.service';

@Component({
  selector: 'app-style-basemap',
  templateUrl: './style-basemap.component.html',
  styleUrls: ['./style-basemap.component.css'],
})
export class StyleBasemapComponent implements OnInit {
  @Input() editLayer = false;

  @Output() spinner = new EventEmitter<boolean>();
  @Output() passedlayerStyle = new EventEmitter<any>();
  styleLayer: FormControl = new FormControl('', Validators.required);
  styleFromLayer: any[] = [];
  workspace: any[] = [];
  existingLayer: any[] = [];
  baseMap: any[] = [];

  mapId = 0;
  layerStyle: any[] = [];
  subject: Subject<any> = new Subject();
  mainWorkSpace = '';

  getLayerData: any[] = [];

  constructor(
    private router: Router,
    private layerService: LayerService,
    public _snackbar: SnackbarService
  ) {}

  ngOnInit(): void {
    this.spinner.emit(true);
    this.layerService
      .getWorkSpace()
      .pipe()
      .subscribe({
        next: (res) => {
          this.baseMap = res.base_maps;
          this.workspace = res.workspaces_list;
          this.existingLayer = res.existing_layers_styles;
        },
        error: () => {
          this._snackbar.openSnackBar('Something went wrong');
        },
        complete: () => {
          const getEdit = localStorage.getItem('setEdit');
          if (getEdit === '1') {
            this.editLayer = true;
            const layerData = JSON.parse(
              localStorage.getItem('layerData') || ''
            );
            this.getLayerData = layerData.layer_styles;
            this.getLayerData.forEach((layer) => {
              this.baseMap.forEach((ele) => {
                if (ele.id === layer.base_map) {
                  ele.layerid = layer.id;
                  ele.workspace = layer.workspace;
                  ele.layername = layer.name;
                  ele.icon_url = layer.icon_url;
                  this.onWorkSpaceSelect(ele.workspace, layer.base_map);
                }
              });
            });
          } else {
            this.onWorkSpaceSelect('mapport', -999999);
          }
        },
      });
  }

  applyChange(array: any): [] {
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < this.getLayerData.length; j++) {
        if (array[i].id == this.getLayerData[j].base_map) {
          array[i].styleList = [];
          array[i].styleList.push(this.getLayerData[j]);
          break;
        }
      }
    }
    return array;
  }

  getParms() {
    this.spinner.emit(true);
    let tempArray = JSON.parse(JSON.stringify(this.baseMap));
    let params: any;
    if (this.getLayerData.length) {
      tempArray = this.applyChange(tempArray);
    }
    tempArray.forEach((ele: any) => {
      let name: string;
      if (
        ele.styleList &&
        ele?.styleList[0].name != '' &&
        ele?.styleList[0].name != 'No style found for this workspace.'
      ) {
        name = ele?.styleList[0].name;
      } else {
        name = 'null';
      }
      params = {
        id: ele.layerid,
        name: name,
        workspace: ele.workSpace,
        icon_url: ele.icon_url ? ele.icon_url : '',
        base_map: ele.id,
      };
      this.layerStyle.push(params);
    });
    this.spinner.emit(false);
  }

  backToMain() {
    this.router.navigate(['/global']);
  }

  getMapId() {
    return this.mapId;
  }

  async onWorkSpaceSelect(event: any, mapid: number, fromClick = false) {
    this.mapId = mapid;
    this.spinner.emit(true);
    const workSpace = event.target ? event.target.value : event;
    this.mainWorkSpace = workSpace;
    this.layerService
      .getStyleOfWorkspace(workSpace)
      .pipe()
      .subscribe({
        next: (res) => {
          this.baseMap.forEach((element) => {
            if (element.id == mapid) {
              if (fromClick) {
                for (let i = 0; i < this.getLayerData.length; i++) {
                  if (this.getLayerData[i].base_map == mapid) {
                    this.getLayerData[i].name =
                      res.styles_list.length > 0
                        ? res.styles_list[0].name
                        : 'No style found for this workspace.';

                    break;
                  }
                }
              }
              // applying No style found for this workspace. to empty layer styles

              element.styleList =
                res.styles_list.length > 0
                  ? res.styles_list
                  : [{ name: 'No style found for this workspace.' }];
              element.workSpace = workSpace;
            } else if (mapid == -999999) {
              element.styleList =
                res.styles_list.length > 0
                  ? res.styles_list
                  : [{ name: 'No style found for this workspace.' }];
              element.workSpace = workSpace;
            }
          });
          this.styleFromLayer = res.styles_list;
        },
        error: () => {
          this.spinner.emit(false);
          this._snackbar.openSnackBar('Something went wrong');
        },
        complete: () => {
          this.layerStyle = [];
          this.getParms();
        },
      });
  }

  saveStyelList() {
    this.passedlayerStyle.emit(this.layerStyle);
  }

  onLayerSelect(event: any, mapid: number) {
    const layer = event.target.value;
    this.layerStyle.forEach((ele) => {
      if (ele.base_map == mapid) {
        ele.name = layer;
      }
    });
    if (this.getLayerData.length) {
      this.getLayerData.forEach((ele) => {
        if (ele.base_map == mapid) {
          ele.name = layer;
        }
      });
    } else {
      this.getLayerData = this.layerStyle;
    }
  }

  onIconChange() {
    this.subject.next();
  }

  inputClick(iconValue: any, mapid: number) {
    this.spinner.emit(true);
    this.subject.pipe(debounceTime(800)).subscribe(() => {
      this.baseMap.forEach((element) => {
        if (element.id == mapid) {
          element.icon_url = iconValue.target.value;
        }
      });
      this.layerStyle = [];
      this.getParms();
    });
  }

  onMainLayerSelect(event: any) {
    this.spinner.emit(true);
    const layer = event.target.value;
    this.layerService
      .getStyleOfLayer(layer)
      .pipe()
      .subscribe({
        next: (res) => {
          this.editLayer = true;
          this.getLayerData = res;
          this.getLayerData.forEach((layer) => {
            this.baseMap.forEach((ele) => {
              if (ele.id === layer.base_map) {
                ele.layerid = layer.id;
                ele.workspace = layer.workspace;
                ele.layername = layer.name;
                ele.icon_url = layer.icon_url;
                this.onWorkSpaceSelect(ele.workspace, layer.base_map);
              }
            });
          });
        },
      });
  }
}
