import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CourtesyService {
  emitData: Subject<any> = new Subject<any>();
  mapsData: Subject<any> = new Subject<any>();
  addOns: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient) {}

  accessLevel = [
    { title: 'Public', value: '000' },
    { title: 'Client', value: '100' },
    { title: 'Reader', value: '200' },
    { title: 'Editor', value: '300' },
    { title: 'Admin', value: '400' },
    { title: 'Demigod', value: '500' },
    { title: 'Nobody', value: '999' },
  ];

  getAllMaps(): Observable<any> {
    return this.http.get('/map/list/');
  }

  public courtesyData(value: any) {
    this.emitData.next(value);
  }
  public getMapsData(value: any) {
    this.mapsData.next(value);
  }
  public getAddsOns(value: any) {
    this.addOns.next(value);
  }
  registerUser(body: any): Observable<any> {
    return this.http.post('/authentication/register-courtesy-user/', body);
  }
  verifyUser(id: any, email: any): Observable<any> {
    return this.http.get(
      '/authentication/courtesy-invitation-verification/?invitee_id=' +
        id +
        '&invitee_email=' +
        email
    );
  }
  updateUser(id: any, body: any) {
    return this.http.patch('/users/' + id + '/', body);
  }
}
